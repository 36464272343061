

<div class=" box-2">

    <div class="col-12 mobile-screen-show" [ngClass]="{'dark-theme':darkMode}">
      <!-- Transactions -->
      <div class="all-feature-mobile mobile-transactions " style="margin: 0;
      min-height: 100vh; padding: 10px;">
        <div class="title">All SEND Money Log</div>
        <div class="mobile-transaction-filter">
          <div class="filter">
            <form (ngSubmit)="onSubmit($event)">
              <div class="search">
                <!-- Search Input with ngModel -->
                <input type="text" placeholder="Search" *ngIf="!startDate" [(ngModel)]="searchQuery" name="search" required />
            
                <!-- Date Input with ngModel -->
                <input type="date" [(ngModel)]="startDate" name="date" [value]="startDate" required />
            <div style="margin-right: 10px;" *ngIf="startDate">TO </div>
                <input type="date"*ngIf="startDate" [(ngModel)]="endDate" name="date" value="" required />
                <!-- Submit Button with SVG Icon -->
                <button type="submit" class="apply-btn">
                  <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                    stroke-linecap="round" stroke-linejoin="round" data-lucide="search" icon-name="search" class="lucide lucide-search">
                    <circle cx="11" cy="11" r="8"></circle>
                    <path d="m21 21-4.3-4.3"></path>
                  </svg>
                </button>
              </div>
            </form>
          </div>
        </div>
        <div style="padding-bottom: 60px;">
          <div class="contents">
            <div *ngFor="let transaction of sendMOneyData?.data">
              <div class="single-transaction">
                <div class="transaction-left">
                  <div class="transaction-des">
                    <div class="transaction-title"> {{transaction?.paymentType}} with {{transaction?.type}}
                    </div>
                    <div class="transaction-id">{{transaction?.sendMoneyId||'--'}}</div>
                    <div class="transaction-date">{{transaction?.createdAt|date: 'MMM dd yyyy HH:mm'}}</div>
                  </div>
                </div>
                <div class="transaction-right">
                  <div class="transaction-amount add">
                     {{transaction?.totalAmount||0|currency:'USD'}} </div>
                  <div class="transaction-fee sub">
                 {{transaction?.convenienceFee||0}} % Fee</div>
                  <div class="transaction-gateway" *ngIf="transaction?.remark">{{transaction?.remark}}</div>
                  <div class="transaction-status success">
                    {{transaction?.status||'---'}}</div>
  
                </div>
              </div>
            </div>
          </div>
          <!-- <div class="single-transaction">
                      <div class="transaction-left">
                          <div class="transaction-des">
                              <div class="transaction-title">Deposit With Rahul kumar
                              </div>
                              <div class="transaction-id">TRXF7EEVB2CSV</div>
                              <div class="transaction-date">Feb 06 2025 12:06</div>
                          </div>
                      </div>
                      <div class="transaction-right">
                          <div class="transaction-amount add">
                              + 100 USDT</div>
                          <div class="transaction-fee sub">
                              -0 USDT Fee </div>
                          <div class="transaction-gateway">Ok</div>
  
  
                          <div class="transaction-status pending">Pending</div>
                      </div>
                  </div> -->
  
        </div>
  
      </div>
  
    </div>
  </div>
  
  <!-- <app-bottom-appbar></app-bottom-appbar> -->