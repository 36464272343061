<video autoplay loop muted class="t-v">
    <source src="/assets/Man doing Successful Transaction.mp4" type="video/mp4">
</video>
<div class="row">
    <div class="col-12">
        <div class="amount">
            <p>₹930.0</p>
        </div>
    </div>
</div>
<div class="row bg-w mt-2 rounded ">
    <div class="col-6">
        <ul class="h-detail">
            <li class="fs-5">Reference ID:</li>
            <li>Paid to:</li>
            <li>Account:</li>
            <li>IFSC:</li>
            <li>Date:</li>
        </ul>
    </div>
    <div class="col-6">
        <ul class="h-detail">
            <li>YESIG4329345546</li>
            <li>ASHISH KUMAR</li>
            <li>4904435865534</li>
            <li>UTIb56785434</li>
            <li>02 Desember 2024</li>
        </ul>
    </div>
</div>
<div class="d-btn">
    <p (click)="onDone()" >Done</p>
</div>