import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApkDataService } from 'src/app/apk-data.service';

@Component({
  selector: 'app-get-reff-code',
  templateUrl: './get-reff-code.component.html',
  styleUrls: ['./get-reff-code.component.css']
})
export class GetReffCodeComponent {
     userPassword = ''
     newPassword = '';
     confirmPassword = '';
   
      constructor( private route:Router , private http:HttpClient,
            private host :ApkDataService,    private toastr: ToastrService
            
          ){}
     ngOnInit(){}     
   
     onChangePassword() {
       if (this.newPassword !== this.confirmPassword) {
         this.toastr.error('New passwords do not match!', 'Error');
         return;
       }
      this.setUserPassCode(this.newPassword,this.userPassword)
     }
   
     setUserPassCode(passCode:any,password:any){
        this.http.post(`${this.host.localHost}${this.host.user_changePassword}`,{oldPassword:password,newPassword:passCode}).subscribe(res=>{
                   if(res){
                     this.toastr.success('Successfuly updated passCode', 'success');
                     this.route.navigateByUrl('app-profile')
                   }      
              },err=>{
               this.toastr.error(err.error.message, 'Error');
       
              })
     }
}
