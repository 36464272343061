import { Component } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-bottom-appbar',
  templateUrl: './bottom-appbar.component.html',
  styleUrls: ['./bottom-appbar.component.css']
})
export class BottomAppbarComponent {
  activeTab: string = 'app-home';
 constructor(private route: Router){}

 navigateByUrls(url: string) {
  if (url) {
    this.activeTab = url;
    this.route.navigateByUrl(url);
 }

 }
}
