<!-- <div class="container">
  <div class="row balance-display  bg-light border rounded  ">
      <div class="col-12">
        <div class="user-wallets-mobile" >
            <img src="assets/frontend/1.png" alt=""
                class="wallet-shadow">
            <div class="head">Available Balance in USDT</div>
            <div class="one">
                <div class="balance">
  
                    <span class="symbol"></span>{{userdeatails?.walletBalance/userdeatails?.USDT_Price | currency :'USD'}}
            {{- amountToAdd/userdeatails?.USDT_Price | currency :'USD'}}<span class="after-dot"></span>
                </div>
                <div class="wallet">Main Wallet</div>
            </div>
  
  
            <div class="one p-wal">
                <div class="balance">
                    <span class="symbol"></span>{{userdeatails?.walletBalance | currency :'INR'}}
                    {{- amountToAdd | currency :'INR'}}
                   
                </div>
                <div class="wallet">Indian Rupees</div>
            </div>
            <div class="info">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    data-lucide="info" icon-name="info" class="lucide lucide-info">
                    <circle cx="12" cy="12" r="10"></circle>
                    <path d="M12 16v-4"></path>
                    <path d="M12 8h.01"></path>
                </svg>1 USDT = {{ userdeatails?.USDT_Price | currency: 'INR' }}
            </div>
        </div>
    </div>
    </div>


    

    
  </div>
 
  <div class="container">
    <div  *ngIf="!hideButtonBankName">
    <button class="btn bg-orange w-100 mt-3" style="background-color: blueviolet;" (click)="GoToAddBankPage()">+Add Bank</button>
    <button class="btn bg-orange w-100 mt-3" style="background-color: blueviolet;" *ngIf="hideSelectBankOption && userBackDetails.length" (click)="UserSelectBank()">Select
      Bank</button>
    </div>
    <div class="row" *ngIf="!hideSelectBankOption">
      <div class="col-md-6" *ngFor="let bank of userBackDetails;">
        <div class="card mt-3" (click)="selectBank(bank.bankId)">
          <div class="card-body">
           
            <p>
           
              <label class="bank-details-user">
                <p> Account Number :</p>
                <p class="userDetails">{{ bank.accountNumber }}</p>
              </label>
              <label class="bank-details-user">
                <p> Account Holder Name :</p>
                <p class="userDetails">{{ bank.accountHolderName }}</p>
              </label>
              <label class="bank-details-user">
                <p>IFSC Code :</p>
                <p class="userDetails">{{ bank.ifscCode }}</p>
              </label>
            </p>
          </div>
        </div>
      </div>
    </div>


    <div class="container" *ngIf="hideSelectBankOption && showSelectedBankDetiails">
      <form class="form" *ngFor="let bank of showParticularBankDetails">
        <label>
          Amount:
          <input type="number" class="form-control mb-3" id="amount" [value]="amountToAdd" (input)="ChangeData($event)"
            placeholder="Enter amount (₹)" [max]="userdeatails?.walletBalance" />
        </label>
       
        <label>
          Account No:
          <input type="text" [value]="bank.accountNumber" disabled />
        </label>
        <label>
          Account Holder Name:
          <input type="text" [value]="bank.accountHolderName" disabled />
        </label>
        <label>
          IFSC:
          <input type="text" [value]="bank.ifscCode " disabled />
        </label>
       
        <button type="submit" (click)="openPassCodeModel()">Submit</button>
      </form>
    </div>

  </div>

  <div id="modal" *ngIf="openModel">
    <div class="modal-content">
      <button class="close-btn" (click)="closePassCodeModel()">&times;</button>
      <div>
        <label>Enter Account Password</label>
        <input type="password" placeholder="Enter Account Password" (input)="changeThePasssCode($event,'password')" />
      </div>
      <div>
        <label>Enter PassCode</label>
        <input type="password" maxlength="4" placeholder="Enter passCode"
          (input)="changeThePasssCode($event,'passCode')" />
      </div>
      <div>
        <label>Confirm</label>
        <input type="password" maxlength="4" placeholder="conform passCode" (input)="ConformPassWrdWrite($event)" />
      </div>

      <button class="submit-btn" (click)="SubmitData()">Submit</button>
    </div>
  </div>

  <div id="modal" *ngIf="openWritePassCodeModel">
    <div class="modal-content">
      <button class="close-btn" (click)="closeWritePassCodeModel()">&times;</button>
      <div>
        <label>Enter PassCode</label>
        <input type="password" maxlength="4" placeholder="Enter passCode"
          (input)="conformUserPassCode($event)" />
      </div>
      <button class="submit-btn" (click)="makePayment()">Submit</button>
    </div>
  </div> -->













<div class="" [ngClass]="{'dark-theme':darkMode}" style="height: 100vh;" *ngIf="!getQrCoderPopup">
  <div class="col-xl-12">
    <div class="site-card">
      <div class="site-card-header">
        <h3 class="title">Withdraw Money</h3>
        <div class="card-header-links">
          <a (click)="getQrCoderPopupModel()" class="card-header-link" *ngIf="payType=='Bank-account'"> Withdraw
            Account</a>
          <a (click)="getQrCoderPopupModel()" class="card-header-link" *ngIf="payType=='E-pay'"> Withdraw QR Code</a>

        </div>
      </div>
      <div class="site-card-body" >
        <div class="progress-steps-form">
          <form>

            <div class="row">
              <div class="col-xl-6 col-md-12 mb-3">
                <label for="exampleFormControlInput1" class="form-label">Withdraw Account</label>
                <div class="input-group">
                  <div class="button-container">
                    <div>
                      <button class="custom-button" [ngClass]="{'highlight-btn': payType=='E-pay'}"
                        (click)="toggleHighlight('E-pay')">
                        E-pay</button>
                    </div>
                    <div>
                      <button [ngClass]="{'highlight-btn': payType=='Bank-account'}" class="custom-button"
                        (click)="toggleHighlight('Bank-account')">
                        Bank Account
                      </button>
                    </div>
                  </div>
                </div>
                <div class="input-info-text processing-time"></div>
              </div>
              <div style="display: flex;justify-content: center;" (click)="uploadShowPopup()" *ngIf="payType=='E-pay'">
                <div *ngIf="aadQrCodeData.qrCodeImages">
                  <img [src]="aadQrCodeData.qrCodeImages" alt="" srcset="" width="300" height="300"
                    style="border: solid 1px;">
                </div>
                <div *ngIf="!aadQrCodeData.qrCodeImages">
                  <img src="/assets/frontend/qrCodeUpalod.png" alt="" srcset="" width="300" height="300"
                    style="border: solid 1px;">
                </div>
              </div>
              <div *ngIf="payType=='Bank-account'">
                <div class="form-container">
                  <h2>Add Bank Account</h2>
                  <label for="holder-name">Account Holder Name:</label>
                  <input type="text" *ngIf="!disabelAddbBankInput" placeholder="Enter account holder name"
                    (input)="addBankAccount($event,'accountHolderName')" />
                  <input type="text" *ngIf="disabelAddbBankInput" [disabled]="disabelAddbBankInput"
                    [value]="addBankData?.accountHolderName" />

                  <label for="account-number">Account Number:</label>
                  <input type="tel" *ngIf="!disabelAddbBankInput" placeholder="Enter account number"
                    (input)="addBankAccount($event,'accountNumber')" />
                  <input type="text" *ngIf="disabelAddbBankInput" [disabled]="disabelAddbBankInput"
                    [value]="addBankData?.accountNumber" />

                  <label for="ifsc-code">IFSC Code:</label>
                  <input type="text" *ngIf="!disabelAddbBankInput" placeholder="Enter IFSC code"
                    (input)="addBankAccount($event,'ifscCode')" maxlength="11" />
                  <input type="text" *ngIf="disabelAddbBankInput" [disabled]="disabelAddbBankInput"
                    [value]="addBankData?.ifscCode" />

                  <div class="form-actions">
                  </div>

                </div>

              </div>
            </div>
            <div class="transaction-list table-responsive">
              <div class="user-panel-title">
                <h3>Withdraw Details</h3>
              </div>
              <table class="table">
                <tbody class="selectDetailsTbody">
                  <tr class="detailsCol">
                    <td><strong>{{(userdeatails?.walletBalance/userdeatails?.USDT_Price).toFixed(2) }}
                        <strong *ngIf="amountToAdd">{{-(amountToAdd/userdeatails?.USDT_Price).toFixed(2)}}</strong></strong></td>
                    <td style="text-align: center;"><span class="withdrawAmount"></span> USDT</td>
                  </tr>
                <tbody class="selectDetailsTbody">
                  <tr class="detailsCol">
                    <td>
                      <strong *ngIf="payType=='Bank-account'">{{  (userdeatails?.walletBalance - (userdeatails?.walletBalance * (sattingData?.bankWithdrawalconvenienceFee || 0)) / 100) | currency:'INR' }}</strong>
                      <strong *ngIf="payType=='E-pay'">{{  (userdeatails?.walletBalance - (userdeatails?.walletBalance * (sattingData?.ePayWithdrawalconvenienceFee || 0)) / 100) | currency:'INR' }}</strong>
                    </td>
                    
                    <td style="text-align: center;"><span class="withdrawAmount"></span> INR With Fee</td>
                  </tr>
                  <tr class="detailsCol" *ngIf="payType=='Bank-account'">
                    <td><strong>{{sattingData?.bankWithdrawalconvenienceFee||0}} %</strong></td>
                    <td style="text-align: center;"><span class="withdrawAmount"></span> CONVAENIENCE FEE</td>
                  </tr>
                  <tr class="detailsCol" *ngIf="payType=='E-pay'">
                    <td><strong>{{sattingData?.ePayWithdrawalconvenienceFee||0}} %</strong></td>
                    <td style="text-align: center;"><span class="withdrawAmount"></span> CONVAENIENCE FEE</td>
                  </tr>
                </tbody>
              </table>
              <div class="col-xl-6 col-md-12">
                <label for="exampleFormControlInput1" class="form-label"></label>
                <div class="input-group mb-1">
                  <input type="number" class="form-control mb-3" id="amount" [value]="amountToAdd"
                    (input)="ChangeData($event)" placeholder="Enter amount (₹)" [max]="userdeatails?.walletBalance" />
                  <!-- <input type="tel" 
                    class="form-control withdrawAmount" placeholder="Enter Amount"> -->
                </div>
                <div class="input-info-text min-max" *ngIf="this.payType == 'Bank-account'"> <span
                  *ngIf="sattingData?.bankWithdrawalMinimumDepositAmount">Minimum {{sattingData?.bankWithdrawalMinimumDepositAmount}} INR
                </span> <span *ngIf="sattingData?.bankWithdrawalMaximumDepositAmount"> and Maximum
                  {{sattingData?.bankWithdrawalMaximumDepositAmount}} </span> </div>
              <div class="input-info-text min-max" *ngIf="this.payType == 'E-pay'"> <span
                  *ngIf="sattingData?.ePayWithdrawalMinimumDepositAmount">Minimum {{sattingData?.ePayWithdrawalMinimumDepositAmount}} INR
                </span> <span *ngIf="sattingData?.ePayWithdrawalMaximumDepositAmount"> and Maximum
                  {{sattingData?.ePayWithdrawalMaximumDepositAmount}} </span> </div>

                <div class="input-info-text withdrawAmountRange"></div>
              </div>
            </div>

            <div class="buttons">
              <button class="site-btn blue-btn" (click)="conferenceMOdel()" [disabled]="isButtonDisabled()">
                Withdraw Money<i class="anticon anticon-double-right"></i>
              </button>
            </div>
          </form>

        </div>
      </div>
    </div>
  </div>
</div>


<!-- bank account save  message   -->

<div *ngIf="banckAccouMessagentSave" class="popup-overlay">
  <div class="popup" [ngClass]="{'dark-theme':darkMode}">
    <h2>Success!</h2>
    <p>Your bank account details have been saved successfully!</p>
    <div style="display: flex;justify-content: center;">
      <button type="submit" class="submit-btn" (click)="accountSaveMessageOff()">Upload</button>
    </div>
  </div>
</div>

<!-- upload qr code file  popup -->

<div *ngIf="uploadQRPopupVisible" class="popup-overlay">
  <div class="popup" [ngClass]="{'dark-theme':darkMode}">
    <h2>Upload QR Code</h2>

    <form>
      <label for="name">Name:</label>
      <input type="text" id="name" placeholder="Enter Name" (input)="addQrCodeAccount($event,'upiIdName')" />

      <!-- <label for="upi-id">UPI ID: (optional)</label>
      <input type="text" id="upi-id" placeholder="Enter UPI ID" (input)="addQrCodeAccount($event,'upiId')" /> -->
      <label for="qr-file">Upload QR Code:</label>
      <input #fileInput type="file" name="attach" id="ticket-attach" accept="image/*" (change)="onFileSelect($event)" />


      <div class="popup-actions">
        <button type="button" class="close-btn" (click)="uploadShowPopup()">Cancel</button>
        <button type="submit" class="submit-btn" [disabled]="!aadQrCodeData.qrCodeImages"
          (click)=" saveQrCodeData()  ">Upload</button>
      </div>
    </form>
  </div>
</div>




<!-- conference   page popup  -->

<div *ngIf="conferencePopup" class="popup-overlay">
  <div class="popup" [ngClass]="{'dark-theme':darkMode}" *ngIf="payType=='E-pay'">
    <h2>Conference page</h2>
    <form>
<div *ngIf="aadQrCodeData?.upiId">

  <label for="upi-id">UPI ID:</label>
  <input type="text" id="upi-id" placeholder="Enter UPI ID" disabled [defaultValue]="aadQrCodeData?.upiId||0" />
</div>

      <label for="name">Name:</label>
      <input type="text" id="name" placeholder="Enter Name" disabled [defaultValue]="aadQrCodeData?.upiIdName||0" />

      <label for="upi-id"> Pass Code:</label>
      <div style="display: flex; align-items: end;">
        <input type="tel" id="upi-id" placeholder="Enter  Pass Code" maxlength="4"
          (input)="addQrCodeAccount($event,'passCode')" />
        <button type="button" class="close-btn" (click)="openPassCodePopup() " *ngIf="passCodeForgotButton"
          style="margin-left: 15px; background-color: #e73667;">change</button>
      </div>

      <div class="popup-actions">
        <button type="button" class="close-btn" (click)="closeConferenceMOdel()">Cancel</button>
        <button type="submit" class="submit-btn" (click)="withdrawalAmountBoth()">Upload</button>
      </div>
    </form>
  </div>
  <div class="popup" [ngClass]="{'dark-theme':darkMode}" *ngIf="payType=='Bank-account'">
    <h2>Conference page</h2>

    <form>


      <label for="account-number">Account Number:</label>
      <input type="text" id="name" placeholder="Enter Name" disabled [defaultValue]="addBankData?.accountNumber||0" />
      <label for="ifsc-code">IFSC Code:</label>
      <input type="text" id="name" placeholder="Enter Name" disabled [defaultValue]="addBankData?.ifscCode||0" />
      <label for="ifsc-code">CONVAENIENCE FEE:</label>
      <input type="text" id="name" placeholder="Enter Name" [value]="(sattingData?.bankWithdrawalconvenienceFee || 0) + ' %'" readonly /> 
      <label for="ifsc-code">Anount:</label>
      <input type="text" id="name" placeholder="Enter Name" disabled [defaultValue]="amountToAdd" />
      <label for="upi-id"> Pass Code:</label>
      <div style="display: flex; align-items: end;">
        <input type="text" id="upi-id" placeholder="Enter  Pass Code" maxlength="4"
          (input)="addBankAccount($event,'passCode')" />
        <button type="button" class="close-btn" (click)="openPassCodePopup() " *ngIf="passCodeForgotButton"
          style="margin-left: 15px; background-color: #e73667;">change</button>
      </div>


      <div class="popup-actions">
        <button type="button" class="close-btn" (click)="closeConferenceMOdel()">Cancel</button>
        <button type="submit" class="submit-btn" (click)="withdrawalAmountBoth()">Upload</button>
      </div>
    </form>
  </div>
</div>



<!-- add pascode popup -->


<div *ngIf="AddPasCodepopup" class="popup-overlay">
  <div class="popup" [ngClass]="{'dark-theme':darkMode}">
    <h2>{{changPasssCodeTitel||'Add PassCode'}}</h2>
    <form>
      <div *ngIf="changPasssCodeTitel">
        <label for="upi-id">OTP : </label>
        <input type="text" id="upi-id" placeholder="Enter OTP" (input)="changeThePasssCode($event,'otp')" />
      </div>
     
      <label for="name"> new PassCode :</label>
      <input type="tel" id="name" maxlength="4" placeholder="Enter passCode" (input)="changeThePasssCode($event,'passCode')" />

      <label for="name"> conform PassCode :</label>
      <input type="tel" maxlength="4" id="name" placeholder="Enter passCode" (input)="changeThePasssCode($event,'ConfirmPassCde')" />
      <div class="popup-actions">
        <button type="button" class="close-btn" (click)="closeWritePassCodeModel()">Cancel</button>
        <button type="submit" class="submit-btn" (click)="updatePassCodeSubmitData()">set PassCode</button>
      </div>
    </form>
  </div>
</div>



<!-- popup wihdraaw request successful  -->

<div *ngIf="WithdrawRequestSuccessful" class="popup-overlay">
  <div class="popu" style="padding: 20px 30px;">

    <div class="col-xl-12" [ngClass]="{'dark-theme':darkMode}">
      <div class="site-card">
        <div class="site-card-header">
          <h3 class="title">Withdraw Money</h3>
          <div class="card-header-links">
            <a (click)="navigateByUrl('withdawal-history')" class="card-header-link">Withdraw request</a>
          </div>
        </div>
        <div class="site-card-body">
          <div class="progress-steps-form">
            <div class="transaction-status centered">
              <div class="icon success">
                <i class="anticon anticon-check"></i>
              </div>
              <h2>{{amountToAdd|currency:'INR'}} Withdraw Request Successful</h2>
              <p>The Withdraw Request has been successfully sent</p>
              <p>Transaction ID: {{resWithdrawalId||'---'}}</p>
              <a (click)="navigateByUrl('/app-home')" class="site-btn">
                <i class="anticon anticon-plus"></i> GO TO HOME 
              </a>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>



<!-- <app-bottom-appbar></app-bottom-appbar> -->
<!-- get qr code   -->


<div class=" box-2" *ngIf="getQrCoderPopup">

  <div class="col-12 mobile-screen-show" [ngClass]="{'dark-theme':darkMode}">
    <!-- Transactions -->
    <div class="all-feature-mobile mobile-transactions " style="margin: 0;min-height: 100vh;">

      <div class="title">All Withdraw Account <span> <button (click)="getQrCoderPopupModel()"
            class="close-btn ">&times;</button></span></div>

      <div class="mobile-transaction-filter">
        <div class="filter">
          <form (ngSubmit)="onSubmit($event)">
            <div class="search">
              <!-- Search Input with ngModel -->
              <input type="text" placeholder="Search" *ngIf="!startDate" [(ngModel)]="searchQuery" name="search" required />
          
              <!-- Date Input with ngModel -->
              <input type="date" [(ngModel)]="startDate" name="date" [value]="startDate" required />
          <div style="margin-right: 10px;" *ngIf="startDate">TO </div>
              <input type="date"*ngIf="startDate" [(ngModel)]="endDate" name="date" value="" required />
              <!-- Submit Button with SVG Icon -->
              <button type="submit" class="apply-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" data-lucide="search" icon-name="search" class="lucide lucide-search">
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
                </svg>
              </button>
            </div>
          </form>
        </div>
      </div>
      <div class="contents">
   
          <div *ngIf="payType=='E-pay'" style="padding-bottom: 60px;">
            <div *ngFor="let transaction of qrCodeList.data">
              <div class="single-transaction" (click)="getAndsetQrCodeData(transaction)">
                <div class="transaction-left">
                  <div class="transaction-des">
                    <div class="transaction-title">{{transaction?.upiIdName}}
                    </div>
                    <div class="transaction-id">{{transaction?.upiId||'--'}}</div>
                    <div class="transaction-date">{{transaction?.createdOn|date}}</div>
                  </div>
                </div>
                <div class="transaction-right">
                  <div style="border-radius: 10px;">
                    <img [src]="transaction?.qrCodeImages" alt="Qr Code" srcset="" height="90px" width="90px">
                  </div>
                </div>
              </div>
            </div>
          </div>

    
          <div class="contents" *ngIf="payType=='Bank-account'"  style="padding-bottom: 60px;">
            <div *ngFor="let transaction of userBackDetails">
              <div class="single-transaction" (click)="setbankDetails(transaction)">
                <div class="transaction-left">
                  <div class="transaction-des">
                    <div class="transaction-title">{{transaction?.accountHolderName||'--'}}
                    </div>
                    <div class="transaction-id">{{transaction?.accountNumber||'--'}}</div>
                    <div class="transaction-date">{{transaction?.createdAt|date: 'MMM dd yyyy HH:mm'}}</div>
                  </div>
                </div>
                <div class="transaction-right">
                  <div class="transaction-amount add">
                    {{transaction?.ifscCode||'--'}}</div>
                  <div class="transaction-id ">
                    -0 USDT Fee </div>
                  <div class="transaction-gateway">{{transaction?.bankId||'Ok'}}</div>
                </div>
              </div>
            </div>
      


          <!-- <div class="single-transaction">
            <div class="transaction-left">
              <div class="transaction-des">
                <div class="transaction-title">Deposit With Rahul kumar
                </div>
                <div class="transaction-id">12345678900</div>
                <div class="transaction-date">Feb 06 2025 12:06</div>
              </div>
            </div>
            <div class="transaction-right">
              <div style="border-radius: 10px;">
                <div class="transaction-amount add">
                  USDT</div>
                <div class="transaction-fee sub">
                  -0 USDT Fee </div>
                <div class="transaction-gateway">ok</div>
                <div class="transaction-status"></div>

              </div>
            </div>
          </div> -->

        </div>
      </div>

    </div>
  </div>