   <!-- <div class="bank-details-container">
    <div class="form-card">
      <h2>Bank Details Form</h2>
      <form #bankDetailsForm="ngForm" (ngSubmit)="onSubmit(bankDetailsForm)">
        <div class="form-grid">
          <div class="form-group">
            <label for="Bank_name">Bank Name</label>
            <input
              type="text"
              id="Bank_name"
              name="bankName"
              class="form-control"
              [(ngModel)]="bankDetails.bankName"
              required
              placeholder="Enter Bank Name"
            />
          </div>
          <div class="form-group">
            <label for="accountNumber">Account Number</label>
            <input
              type="number"
              id="accountNumber"
              name="accountNumber"
              class="form-control"
              [(ngModel)]="bankDetails.accountNumber"
              required
              placeholder="Enter Account Number"
            />
          </div>
          <div class="form-group">
            <label for="accountHolder">Account Holder</label>
            <input
              type="text"
              id="accountHolder"
              name="accountHolderName"
              class="form-control"
              [(ngModel)]="bankDetails.accountHolderName"
              required
              placeholder="Enter Account Holder's Name"
            />
          </div>
          <div class="form-group">
            <label for="ifscCode">IFSC Code</label>
            <input
              type="text"
              id="ifscCode"
              name="ifscCode"
              class="form-control"
              [(ngModel)]="bankDetails.ifscCode"
              required
              placeholder="Enter IFSC Code"
              maxlength="11"
              minlength="11"
            />
          </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="!bankDetailsForm.valid">
          Submit
        </button>
      </form>
    </div>
  </div>

   -->


   <div class="bank-details-container">
    <div class="form-card">
      <h2>Bank Details Form</h2>
      <form #bankDetailsForm="ngForm" (ngSubmit)="onSubmit(bankDetailsForm)">
        <div class="form-grid">
          <div class="form-group">
            <!-- <label for="Bank_name">Bank Name</label>
            <input
              type="text"
              id="Bank_name"
              name="bankName"
              class="form-control"
              [(ngModel)]="bankDetails.bankName"
              required
              pattern="^[a-zA-Z\s]+$"
              placeholder="Enter Bank Name"
              #bankName="ngModel"
            /> -->
            <!-- <div *ngIf="bankName.invalid && (bankName.dirty || bankName.touched)" class="error-message">
              <div *ngIf="bankName.errors?.['required']">Bank Name is required.</div>
              <div *ngIf="bankName.errors?.['pattern']">Please enter a valid Bank Name (letters only).</div>
            </div> -->
          </div>
          <div class="form-group">
            <label for="accountNumber">Account Number</label>
            <input
              type="number"
              id="accountNumber"
              name="accountNumber"
              class="form-control"
              [(ngModel)]="bankDetails.accountNumber"
              required
              pattern="^\d{9,18}$"
              placeholder="Enter Account Number"
              #accountNumber="ngModel"
            />
            <div *ngIf="accountNumber.invalid && (accountNumber.dirty || accountNumber.touched)" class="error-message">
              <div *ngIf="accountNumber.errors?.['required']">Account Number is required.</div>
              <div *ngIf="accountNumber.errors?.['pattern']">Account Number must be between 9 and 18 digits.</div>
            </div>
          </div>
          <div class="form-group">
            <label for="accountHolder">Account Holder</label>
            <input
              type="text"
              id="accountHolder"
              name="accountHolderName"
              class="form-control"
              [(ngModel)]="bankDetails.accountHolderName"
              required
              pattern="^[a-zA-Z\s]+$"
              placeholder="Enter Account Holder's Name"
              #accountHolderName="ngModel"
            />
            <div *ngIf="accountHolderName.invalid && (accountHolderName.dirty || accountHolderName.touched)" class="error-message">
              <div *ngIf="accountHolderName.errors?.['required']">Account Holder Name is required.</div>
              <div *ngIf="accountHolderName.errors?.['pattern']">Please enter a valid Account Holder Name (letters only).</div>
            </div>
          </div>
          <div class="form-group">
            <label for="ifscCode">IFSC Code</label>
            <input
              type="text"
              id="ifscCode"
              name="ifscCode"
              class="form-control"
              [(ngModel)]="bankDetails.ifscCode"
              required
              placeholder="Enter IFSC Code"
              maxlength="11"
              minlength="11"
              #ifscCode="ngModel"
            />
            <div *ngIf="ifscCode.invalid && (ifscCode.dirty || ifscCode.touched)" class="error-message">
              <div *ngIf="ifscCode.errors?.['required']">IFSC Code is required.</div>
              <div *ngIf="ifscCode.errors?.['pattern']">Please enter a valid 11-character IFSC Code.</div>
            </div>
          </div>
        </div>
        <button type="submit" class="btn btn-primary" [disabled]="bankDetailsForm.invalid">
          Submit
        </button>
      </form>
    </div>
  </div>