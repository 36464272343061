<div class="container" style="padding: 0;" [ngClass]="{'dark-theme':darkMode}">
    <!-- <header class="header">
        <button class="back-button" (click)="GetNavigate('/app-profile')">←</button>
        <h1 style="color: rgb(208, 213, 218);">Points</h1>
        <button class="menu-button" (click)="GetNavigate('/app-profile')">X</button>
    </header> -->
<div>
  <nav class="tabs">
    <button 
      class="tab" 
      [class.active]="activeTab === 'data'" 
      (click)="setActiveTab('data')" (click)="myData()">Data</button>
  
    <button 
      class="tab" 
      [class.active]="activeTab === 'team'" 
      (click)="setActiveTab('team')" (click)="MyTeam()" >My Team</button>
  
    <button 
      class="tab" 
      [class.active]="activeTab === 'history'" 
      (click)="setActiveTab('history')" (click)="lavel()">History</button>
  </nav>
</div>

      <div *ngIf="showMyData">
    <section class="content" [ngClass]="{'dark-theme':darkMode}">
        <h2 class="section-title">My Reward</h2>

        <div class="stats">
            <div class="stat">
                <p>Today Share Link</p>
                <h3>{{getPintData?.todayShareLink}}</h3>
            </div>
            <div class="stat">
                <p>Today Invited Users</p>
                <h3>{{getPintData?.todayInvitedUsers}}</h3>
            </div>
          
            <div class="stat">
                <p>Total Members</p>
                <h3>{{getPintData?.totelInviteCount}}</h3>
            </div>
            <div class="stat">
                <p>Total Share Link</p>
                <h3>{{getPintData?.totalShareLink}}</h3>
            </div>
            <div class="stat">
                <p>Withdrawal Transaction Point</p>
                <h3>{{getPintData?.selfWithdrawalTransactionPoint |  number: '1.0-2'}}</h3>
            </div>
            <div class="stat">
                <p>Referral Withdrawal Transaction</p>
                <h3>{{getPintData?.referralWithdrawalTransactionPoint|  number: '1.0-2'}}</h3>
            </div>
            <div class="stat">
                <p>Referral Point</p>
                <h3>{{getPintData?.referralPoint||0}}</h3>
            </div>
            <div class="stat">
                <p>invite-code</p>
                <h3>{{getPintData?.code}}</h3>
            </div>
         
        </div>

        <!-- <div class="qr-section">
            <button class="copy-button" (click)="copyUrl('code')" >Copy Invitation Code</button>
            <button class="copy-button"(click)="shareOnWhatsApp()" >Share To Whatapps</button>
        </div>

        <div class="invite">
            <p>Invite Link:</p>
            <p class="invite-link">{{customUrl}}?r_code={{getPintData?.code}}</p>
            <button class="copy-button" (click)="copyUrl('link')">Copy</button>
        </div> -->

    
         
    </section>
    <div class="footer" [ngClass]="{'dark-theme':darkMode}">
        <div class="icon">{{masterData?.icon}}</div>
        <h2>{{masterData?.titel}}</h2>
        <p>{{masterData?.description}}</p>
        <p>Copy your code: <span class="code" (click)="copyUrl('code')">{{getPintData?.code}}</span></p>
        <button (click)="shareOnWhatsApp()" >Invite</button>
      </div>
     </div>
    <div class="commission-table" *ngIf="showTeam" style="padding: 8px; padding-top: 0px;">
        <h3 style="display: flex; justify-content: center;" >Invited Users list</h3>
        <table>
            <thead>
                <tr>
                    <th>#</th>
                    <th>mobile No.</th>
                    <th>code</th>
                    <th>Date</th>
                </tr>
            </thead>
            <tbody *ngFor="let totelInviteUsersData of getPintData?.totelInviteUsers let i = index">
                <tr>
                    <td>{{i+1}}</td>
                    <td>{{totelInviteUsersData.mobileNumber?.toString().slice(-4)}}</td>
                    <td>{{totelInviteUsersData?.code}}</td>
                    <td>{{totelInviteUsersData?.createdOn |date}}</td>
                </tr>
              
            </tbody>
        </table>
        <div class="w-100" *ngIf="getPintData?.totelInviteUsers?.length ==0">
            <h2 class="text-center">No data  found</h2>
        </div>
    </div>
    <div class="commission-table" *ngIf="showLavel">

        <div class="table-container" [ngClass]="{'dark-theme':darkMode}">
            <h2>Referral Points</h2>
            <table>
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Amount</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let referral of referralPoints">
                  <td>{{ referral.level }}</td>
                  <td>{{ referral.Amount }}</td>
                  <td>{{ referral.Value }}</td>
                </tr>
              </tbody>
            </table>
          
            <h2>Self Points</h2>
            <table>
              <thead>
                <tr>
                  <th>Level</th>
                  <th>Amount</th>
                  <th>Value</th>
                </tr>
              </thead>
              <tbody>
                <tr *ngFor="let self of selfPoints">
                  <td>{{ self.level }}</td>
                  <td>{{ self.Amount }}</td>
                  <td>{{ self.Value }}</td>
                </tr>
              </tbody>
            </table>
          </div>
          
    </div>
</div>

<!-- <app-bottom-appbar *ngIf="activeTab!=='data'"></app-bottom-appbar> -->