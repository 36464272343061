import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import { LoginComponent } from './login/login.component';
import { ForgotComponent } from './forgot/forgot.component';
import { HomeComponent } from './home/home.component';
import { ProfileComponent } from './profile/profile.component';
import { ExchangeHComponent } from './exchange-h/exchange-h.component';
import { TransactionSuccessComponent } from './transaction-success/transaction-success.component';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { DepositComponent } from './deposit/deposit.component';
import { WithdrawalComponent } from './withdrawal/withdrawal.component';
import { WithdrawalHistoryComponent } from './withdrawal-history/withdrawal-history.component';
import { HistoryWithdrwalsComponent } from './history-withdrwals/history-withdrwals.component';
import { AddBankDetailsComponent } from './add-bank-details/add-bank-details.component';
import { CustomerSupportComponent } from './customer-support/customer-support.component';
import { StatementComponent } from './statement/statement.component';
import { ProfileBankAccountComponent } from './profile-bank-account/profile-bank-account.component';
import { ShomodeldataComponent } from './shomodeldata/shomodeldata.component';
import { NotificationComponent } from './notification/notification.component';
import { UserSettingComponent } from './user-setting/user-setting.component';
import { PassCodeForgetComponent } from './Setting-component-data/pass-code-forget/pass-code-forget.component';
import { GetReffCodeComponent } from './Setting-component-data/get-reff-code/get-reff-code.component';
import { ReferralComponent } from './referral/referral.component';
import { BrowserModule } from '@angular/platform-browser';
import { TicketPageComponent } from './ticket-page/ticket-page.component';
import { PointComponent } from './point/point.component';
import { PointsExchangePagesComponent } from './points-exchange-pages/points-exchange-pages.component';
import { SettingComponent } from './setting/setting.component';
import { ChangePasswordComponent } from './Setting-component-data/change-password/change-password.component';
import { SendMoneyComponent } from './send-money/send-money.component';
import { CryptoMarketComponent } from './crypto-market/crypto-market.component';
import { BottomAppbarComponent } from './bottom-appbar/bottom-appbar.component';



@NgModule({
  declarations: [
    LoginComponent,
    ForgotComponent,
    HomeComponent,
    ProfileComponent,
    ExchangeHComponent,
    TransactionSuccessComponent,
    DepositComponent,
    WithdrawalComponent,
    WithdrawalHistoryComponent,
    HistoryWithdrwalsComponent,
    AddBankDetailsComponent,
    CustomerSupportComponent,
    StatementComponent,
    ProfileBankAccountComponent,
    ShomodeldataComponent,
    NotificationComponent,
    UserSettingComponent,
    PassCodeForgetComponent,
    GetReffCodeComponent,
    ReferralComponent,
    TicketPageComponent,
    PointComponent,
    PointsExchangePagesComponent,
    SettingComponent,
    ChangePasswordComponent,
    SendMoneyComponent,
    CryptoMarketComponent,
    BottomAppbarComponent,
    
  ],
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    BrowserModule,
  ],
  exports:[
    LoginComponent
  ]
})
export class UserAuthModule { }
