<div class="top-heading">
    <p class="header-name-user">Bank Account</p>
</div>
<div *ngFor="let item of userDetials">
<div class="card">
    <div class="card-actions">
        <button (click)="delete(item?.bankId)" class="button-delet">Delete</button>
      </div>
    <div class="card-content">
      <!-- <div class="field">
        <label class="label">Bank Name:</label>
        <label>{{item?.bankName}}</label>
      </div> -->
      <div class="field">
        <label class="label">Account Number:</label>
        <label>{{item?.accountNumber}}</label>
      </div>
      <div class="field">
        <label class="label">Account Holder:</label>
        <label>{{item?.accountHolderName}}</label>
      </div>
      <div class="field">
        <label class="label">IFSC Code:</label>
        <label>{{item?.ifscCode}}</label>
      </div>
    </div>
   </div>
</div>

<div class="paginationPage-data">
  <button class="{{pagination.pageNo <= 1 ? '' : 'button-pagination' }}" (click)="PaginationPage('Prevoius')" 
  [disabled]="pagination.pageNo <= 1">&larr; Previous</button>
  <p class="m-0">{{pagination.pageNo}}</p>
  <button class="{{userAllDetaisl?.totalCount <= (userAllDetaisl.pageNo*userAllDetaisl?.itemsPerPage) ? '' : 'button-pagination'}}"
  (click)="PaginationPage('Next')" [disabled]="userAllDetaisl?.totalCount <= (userAllDetaisl.pageNo*userAllDetaisl?.itemsPerPage)">Next &rarr;</button>
</div>
<div class="paginationPage" (click)="Navigate()">
    <div class="Add-bank">
      <button class="add-bank-button">+</button>
    </div>
</div>


  