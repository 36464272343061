import { HttpErrorResponse, HttpEvent, HttpHandler, HttpRequest } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { catchError, Observable, throwError } from 'rxjs';
import { ToastrService } from 'ngx-toastr';
import { Router } from '@angular/router';

@Injectable({
  providedIn: 'root'
})
export class InterceptorService {

  constructor(private router: Router,private toastr: ToastrService) { }
  intercept(req:HttpRequest<any> , next:HttpHandler): Observable<HttpEvent<any>> {

   let tokenAuto;

   let token = localStorage.getItem('auto')
   if(token){
    tokenAuto= req.clone(
      {
        setHeaders:{
          Authorization:token,
        }
      })
     return next.handle(tokenAuto).pipe(
      catchError((error: HttpErrorResponse) => {
        if (error.status === 401) {
          localStorage.removeItem('auto'); 
          this.toastr.error(error.message, 'Error');
          this.router.navigateByUrl('app-login');
        }
        return throwError(() => error);
      })
   );
   }else{
     return next.handle(req)
   }
  }
}
