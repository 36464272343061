<!-- <div class="signup-main">
  <div class="main">
    <input type="checkbox" id="chk" aria-hidden="true">

    <div class="signup">
      <form [formGroup]="loginForm">
        <label for="chk" aria-hidden="true">Sign up</label>

        <input 
        type="number" 
        placeholder="Phone Number" 
        formControlName="mobileNumber" 
        (input)="onNumberInput($event)" />
      
        <input type="password" placeholder="Password" formControlName="password" />

        <input type="password" placeholder="Confirm Password" formControlName="confirmPassword" />
        <div *ngIf="loginForm.errors?.['passwordMismatch'] && loginForm.touched" class="w-100 d-flex justify-content-center">
          <small class="text-center text-danger">Passwords do not match.</small>
        </div>
      </form>
      <div class="d-flex">
        <button class="btn btn-success" (click)="UserSignUP()">Submit</button>
      </div>
    </div>
    <div class="login">
      <form (ngSubmit)="onHomepage(UserLogin)" #UserLogin=ngForm>
        <label for="chk" aria-hidden="true">Login</label>
        <input type="number" name="number" placeholder="Phone Number" required ngModel>
        <input type="password" name="pswd" placeholder="Password" required ngModel>
        <input type="submit" value="Submit" class="sbmt" />
      </form>
      <button (click)="userForgotPassword()">Forgot Password</button>
    </div>
  </div>
</div> -->




<div class="" style="padding-top: 5vh" *ngIf="landingPageObj.getStarted">
  <div class="w-100" style="height: 33vh;">
    <img src="/assets/rafiki.svg" alt="rafiki image" class="w-100 h-100">
  </div>
  <div class=" w-100" style="height: 62vh;position: relative;">
    <img style="width:100%; height:100%; bottom:0; left:0;position: absolute;" src="/assets/landingRound.svg"
      alt="group image">
    <div style=" position: absolute;
    z-index: 10;
    bottom: 0;
    width: 100%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center">
      <p
        style="font-family: 'Square Sans Serif 7', sans-serif; font-weight: 700; font-size: 30px; line-height: 33px ;padding-bottom: 0% ;margin: 5%;">
        MANAGE YOUR EXCHANGES IN ONE PLACE</p>
      <p
        style="font-family: 'Microsoft Sans Serif', sans-serif; width: 100% ;padding: 5%; font-size: 20px; padding-bottom: 10%;">
        Our platform has everything for you to make your exchanges more convenient for you to manage it easily</p>
      <button class="d-flex justify-content-center btn btn-dark btn-lg w-50 fw-bold cta-button"
        style="width: 80% !important; margin-bottom: 20px;"
        (click)="landingPageObj.getStarted = false; landingPageObj.SignupSliding = true">
        Next 
      </button>

    </div>
  </div>
</div>




<div *ngIf="landingPageObj.SignupSliding"
  style=" height: 100vh; display: flex; flex-direction: column; justify-content: center; align-items: center;position: relative;">
  <div class="h-100 w-100">
    <img src="assets/Clouds.svg" alt="managemoney" class="img-fluid mt-5 w-100">

    <div class="mt-2 d-flex flex-column align-items-center justify-content-center">
      <img src="assets/amico.svg" alt="managemoney" class="center_img">
      <p class="fs-2 text-center w-60">
        Best way to exchange your Money!</p>
    </div>
  </div>
  <div class="button_container">
    <div class="d-flex flex-column">
      <!-- <button class="primary_btn mb-3" (click)="landingPageObj.SignupSliding = false ;landingPageObj.singUp = true">Sign
        Up</button> -->
      <button class="secondary_btn" (click)="landingPageObj.SignupSliding = false ;landingPageObj.singIN = true">Get Started</button>
    </div>
    <img src="assets/Group 238782.svg" alt="managemoney" class=""
      style=" position: absolute; left: 0;bottom: 0;right:0; z-index: -10; width: 100vw;">
  </div>
</div>

<!-- sing in -->
<div class="landing_2 vh-100 vw-100 position-relative" *ngIf="landingPageObj.singIN ">
  <img src="assets/illustration.svg" alt="" class="position-absolute bottom-0 left-0 right-0">
  <div class="container">
    <form (ngSubmit)="onHomepage(UserLogin)" #UserLogin=ngForm>
      <div class="vh-60 d-flex flex-column justify-content-center align-items-center main_container">
        <i class="fa-regular fa-user-circle fa-4x text-danger"></i>
        <p class="text-center">Sign In</p>
        <div class="input_container p-4 rounded-4 border-3 position-fixed d-flex flex-column align-items-center" style="top: 38%;">
          <div>
            <div class="d-flex gap-3 pb-2">
              <i class="fa fa-mobile fs-1 text-secondary {{active ==='mobile' ? 'primary_color':''}}"></i>
              <input type="tel" maxlength="10" placeholder="Phone Number" (click)="active = 'mobile'" name="number"
                required ngModel>
            </div>

            <!-- <div class="d-flex gap-2 pt-4 pb-2 justify-content-left">
              <i class="fa fa-shield-alt fs-3 text-secondary {{active ==='password' ? 'primary_color':''}}"></i>
              <input [type]="showPassword ? 'text' : 'password'" placeholder="Password" name="pswd" (click)="active = 'password'" class="w-40" required
                ngModel>
              <i class="fa fa-eye-slash" [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }" (click)="togglePasswordVisibility()"></i>
            </div> -->
          </div>

          <button class="secondary_btn_2 position-absolute bottom-0 mt-3" value="Submit">Sign In</button>
          <button class="outline_button position-absolute bottom-0 " *ngIf="landingPageObj.showforgotPasswordBtn"
            (click)="landingPageObj.forgot = true; landingPageObj.singIN = false">Forgot Password</button>
        </div>
      </div>
    </form>
  </div>
</div>


<!-- sing up -->
<div class="landing_2 vh-100 vw-100 position-relative" *ngIf="landingPageObj.singUp">
  <img src="assets/pana.svg" alt="" class="position-absolute bottom-0 left-0 right-0 ">
  <div class="container">
    <div class=" d-flex flex-column justify-content-center align-items-center "
      style="padding-top: 15% !important;">
      <i class="fa-regular fa-user-circle fa-4x text-danger"></i>
      <p class="text-center">User Registration</p>
      <div class="input_container p-4 rounded-4 border-3 position-fixed d-flex flex-column align-items-center " style="top: 24%; background-color: white  ;">

        <form [formGroup]="loginForm">
          <div>
            <!-- Full Name -->
            <div class="d-flex gap-3 pb-3 border-bottom border-3">
              <i class="fa fa-user fs-3 text-secondary {{active ==='UserName' ? 'primary_color':''}}"></i>
              <input type="text" placeholder="User Name" (click)="active = 'UserName'" formControlName="fullName">
            </div>
            <div *ngIf="loginForm.get('fullName')?.invalid && loginForm.get('fullName')?.touched">
              <small *ngIf="loginForm.get('fullName')?.hasError('required')">Full Name is required</small>
              <small *ngIf="loginForm.get('fullName')?.hasError('pattern')">Only letters and spaces are allowed</small>
            </div>
        
            <!-- Mobile Number -->
            <!-- <div class="d-flex gap-3 pt-4 pb-2 border-bottom border-3">
              <i class="fa fa-mobile fs-1 text-secondary {{active ==='mobile' ? 'primary_color':''}}"></i>
              <input type="tel" maxlength="10" placeholder="Phone Number" formControlName="mobileNumber" (click)="active = 'mobile'">
            </div>
            <div *ngIf="loginForm.get('mobileNumber')?.invalid && loginForm.get('mobileNumber')?.touched">
              <small *ngIf="loginForm.get('mobileNumber')?.hasError('required')">Mobile Number is required</small>
              <small *ngIf="loginForm.get('mobileNumber')?.hasError('pattern')">Enter a valid phone number</small>
              <small *ngIf="loginForm.get('mobileNumber')?.hasError('maxlength')">Phone number cannot exceed 10 digits</small>
              <small *ngIf="loginForm.get('mobileNumber')?.hasError('minlength')">Phone number must be 10 digits</small>
            </div> -->
        
            <!-- Email Address -->
            <div class="d-flex gap-3 pt-4 pb-2 border-bottom border-3">
              <i class="fa fa-envelope fs-3 text-secondary {{active === 'email' ? 'primary_color' : ''}}"></i>
              <input type="email" placeholder="Email Address" (click)="active = 'email'" formControlName="email">
            </div>
            <div *ngIf="loginForm.get('email')?.invalid && loginForm.get('email')?.touched">
              <small *ngIf="loginForm.get('email')?.hasError('required')">Email is required</small>
              <small *ngIf="loginForm.get('email')?.hasError('email')">Please enter a valid email address</small>
            </div>
        
            <!-- Password -->
            <!-- <div class="d-flex gap-2 pt-4 pb-2 justify-content-left border-bottom border-3">
              <i class="fa fa-shield-alt fs-3 text-secondary {{active ==='password' ? 'primary_color':''}}"></i>
              <input [type]="showPassword ? 'text' : 'password'" placeholder="Password" (click)="active = 'password'" formControlName="password">
              <i class="fa fa-eye-slash" [ngClass]="{ 'fa-eye': showPassword, 'fa-eye-slash': !showPassword }"  (click)="togglePasswordVisibility()"></i>
            </div>
            <div *ngIf="loginForm.get('password')?.invalid && loginForm.get('password')?.touched">
              <small *ngIf="loginForm.get('password')?.hasError('required')">Password is required</small>
              <small *ngIf="loginForm.get('password')?.hasError('minlength')">Password must be at least 8 characters long</small>
            </div> -->
        
            <!-- Confirm Password -->
            <!-- <div class="d-flex gap-2 pt-4 pb-3 justify-content-left  border-bottom border-3">
              <i class="fa fa-shield-alt fs-3 text-secondary {{active ==='Conform Password' ? 'primary_color':''}}"></i>
              <input [type]="showConfirmPassword ? 'text' : 'password'" placeholder="Confirm Password" (click)="active = 'Conform Password'" formControlName="confirmPassword">
              <i class="fa fa-eye-slash" [ngClass]="{ 'fa-eye': showConfirmPassword, 'fa-eye-slash': !showConfirmPassword }"  (click)="toggleConfirmPasswordVisibility()"></i>
            </div>
        
            <div *ngIf="loginForm.hasError('passwordMismatch') && loginForm.get('confirmPassword')?.touched">
              <small class="text-danger">Passwords do not match</small>
            </div> -->
            
            <div class="d-flex gap-3 pt-4 pb-2 ">
              <i class="fa fa-envelope fs-3 text-secondary {{active === 'referrer' ? 'primary_color' : ''}}"></i>
            <input type="text" placeholder="Referrer Code (Optional)" (click)="active = 'referrer'" formControlName="referrer">
            </div>
            <div *ngIf="invalidReferralCode" >
              <small >Invalid referrer code</small>
            </div>
          </div>
        </form>
        
        <button class="secondary_btn_2 position-absolute bottom-0 mt-3" [disabled]="loginForm.invalid" (click)="UserSignUP()">Submit</button>
        

        <!-- <button class="outline_button position-absolute bottom-0 ">Forgot Password</button> -->
      </div>
    </div>
  </div>
</div>


<!-- forgot passowrd -->
<div class="landing_2 vh-100 vw-100 position-relative" *ngIf="landingPageObj.forgot">
  <img src="assets/cuate1.svg" alt="" class="position-absolute bottom-0 left-0 right-0 w-100">
  <div class="container">
    <div class="vh-60 d-flex flex-column justify-content-center align-items-center main_container"
      style="padding-top: 80px !important;">
      <i class="fa fa-unlock fa-4x text-danger"></i>
      <p class="text-center"> Forgot password</p>

      <div class="input_container p-4 rounded-4 border-3 position-fixed d-flex flex-column align-items-center ">
        <div>

          <div class="d-flex gap-3 pb-3 border-bottom border-3">
            <i class="fa fa-mobile fs-1 text-secondary {{active ==='mobile' ? 'primary_color':''}}"></i>
            <input type="tel" placeholder="Phone Number" (click)="active = 'mobile'" (input)="forgotPasswordInputEvent($event,'mobileNumber')" class="w-40">
          </div>
          <div class="d-flex gap-2 pt-4 pb-2 justify-content-left border-bottom border-3">
            <i class="fa fa-shield-alt fs-3 text-secondary {{active ==='password' ? 'primary_color':''}}"></i>
            <input type="password" placeholder="Password" (click)="active = 'password'" class="w-40" (click)="active = 'mobile'" (input)="forgotPasswordInputEvent($event,'password')">
            <i class="fa fa-eye-slash"></i>
          </div>
          <div class="d-flex gap-2 pt-4 pb-3 justify-content-left">
            <i class="fa fa-shield-alt fs-3 text-secondary {{active ==='Conform Password' ? 'primary_color':''}}"></i>
            <input type="password" placeholder="Conform Password" (click)="active = 'Conform Password'" class="w-40" (input)="forgotPasswordInputEvent($event,'ConformPassword')">
            <i class="fa fa-eye-slash"></i>
          </div>
        </div>
        <button class="secondary_btn_2 position-absolute bottom-0 mt-3" (click)="forgotPasswordSubmit()">Get OTP</button>
        <!-- <button class="outline_button position-absolute bottom-0 ">Forgot Password</button> -->
      </div>
    </div>
  </div>
</div>

<!-- get  otp -->

<div class="landing_2 vh-100 vw-100 position-relative" *ngIf="landingPageObj.otpPage">
  <img src="assets/cuate.svg" alt="" class="position-absolute bottom-0 left-0 right-0 w-100">
  <div class="container">
    <div class="vh-60 d-flex flex-column justify-content-center align-items-center main_container"
      style="padding-top: 80px !important;">
      <i class="fa fa-lock fa-4x text-danger"></i>
      <p class="text-center"> Get OTP</p>
      <div class="   position-fixed d-flex flex-column  " style=" top: 230px; width: 90%;padding: 10%;">
        <div class="d-flex gap-3 pl-3 ">
          <h4 class="fw-bold" style="color: #979797;font-size: 17px ">Enter the OTP</h4>
        </div>
        <div class="d-flex justify-content-between">
          <input type="tel" maxlength="6" placeholder="OTP" class="otp-input" style="border: solid 1px #d1d1d1;"(input)="otpInputEvent($event)">
          <button class="Resend_btn " (click)="GenerateOtpSendNumber(landingPageObj.mobileNumber)" [disabled]="timer!=='00:00'">Resend </button>
        </div>
        <h4 class="fw-bold" style="color: #979797;font-size: 17px 10% ;margin-top: 3%;">{{timer}}</h4>
        <div class="d-flex flex-column align-items-center gap-3" style="padding-top: 10%;">
          <h6 style="color: #979797;font-size: 17px">We texted you a code to verify your phone number sp <b
              style="color: #000000;"> (+91) {{landingPageObj.mobileNumber}}</b></h6>
          <h6 style="color: #979797;font-size: 17px">This code will expire 5 minutes after this message. If you don't
            get a message.</h6>
        </div>
      </div>
    </div>
  </div>
</div>