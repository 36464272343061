import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApkDataService } from 'src/app/apk-data.service';
import { InterfaceForApi } from '../../interface-for-api';

@Component({
  selector: 'app-pass-code-forget',
  templateUrl: './pass-code-forget.component.html',
  styleUrls: ['./pass-code-forget.component.css']
})
export class PassCodeForgetComponent {
  userPassword = ''
  newPassword = '';
  confirmPassword = '';

   constructor( private route:Router , private http:HttpClient,
         private host :ApkDataService,    private toastr: ToastrService
         
       ){}
  ngOnInit(){}     

  onChangePassword() {
    if (this.newPassword !== this.confirmPassword) {
      this.toastr.error('New passwords do not match!', 'Error');
      return;
    }
   this.setUserPassCode(this.newPassword,this.userPassword)
  }

  setUserPassCode(passCode:any,password:any){
     this.http.post(`${this.host.localHost}${this.host.user_addPassCode}`,{passCode,password}).subscribe(res=>{
                if(res){
                  this.toastr.success('Successfuly updated passCode', 'success');
                  this.route.navigateByUrl('app-profile')
                }      
           },err=>{
            this.toastr.error(err.error.message, 'Error');
    
           })
  }

}

