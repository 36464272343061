import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-withdrawal-history',
  templateUrl: './withdrawal-history.component.html',
  styleUrls: ['./withdrawal-history.component.css']
})
export class WithdrawalHistoryComponent {
  userdata:any
  userpage:any = ''
  paramssaas ={
    orderId:'',
        type :''
  }
  pagination = {
   itemsPerPage : 60,
   pageNo : 1
  }



  searchQuery: string = ''; // Store search text input
  startDate: string = ''; // Store date input
  endDate: string = ''; // Store date input
  userAllData:any;
 private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  
    constructor( private http:HttpClient,
      private host :ApkDataService,
      private route :Router,    private toastr: ToastrService,private darkModeService: DarkModeService,
      
    ){}

    ngOnInit(){
      this.userData()
      this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
        (enabled) => (this.darkMode = enabled)
      );
    }
    userData(payload?:any){
      // if(this.userpage !== data){
      //   this.pagination.pageNo = 1;
      //  }
      // if
      //  this.userpage = data
      // ...this.pagination
      this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.recharge_listRecharge}`,{params:payload}).subscribe(res=>{
           if(res){
             this.userdata = res
             this.userAllData = res;
           }      
      },err=>{
        this.toastr.error(err.error.message, 'Error');

      })
    }
    PreviousData(move:string,status?:any){
     if(move === 'Previous'){
          this.pagination.pageNo -= 1;
     }else{
       this.pagination.pageNo += 1;
     }
     this.userData(status)
    }
    ShowModelData(transaction:any){
        this.paramssaas.orderId = transaction?.orderId;
        this.paramssaas.type = transaction?.type;
       this.route.navigate(['shoe-model-payment'],{queryParams:{data: JSON.stringify(this.paramssaas)}})
     }
     onSubmit(event: Event) {
      // event.preventDefault(); // Prevent the default form submission
      // Prepare the data to send to the API
      const formData = {
        search: this.searchQuery,
        startDate: this.startDate,
        endDate: this.endDate
      }; 
      if (formData.startDate && formData.endDate) {
        if (new Date(formData.startDate) > new Date(formData.endDate)) {
          this.toastr.error('Start date cannot be greater than end date.', 'Error');
          return;
        }
        formData.search='';
      }
      // Call the API
        this.userData(formData)
    }
     
}
