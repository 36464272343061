import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApkDataService } from 'src/app/apk-data.service';
import { HttpClient } from '@angular/common/http';
import { InterfaceForApi } from '../interface-for-api';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-customer-support',
  templateUrl: './customer-support.component.html',
  styleUrls: ['./customer-support.component.css']
})
export class CustomerSupportComponent {
  userDetials: any
  userSendData: any = ' '
  private intervalId: any;
  constructor(private route: Router, private location: Location,
    private http: HttpClient,
    private host: ApkDataService,
    private toastr: ToastrService

  ) {

  }
  NavigateUser() {
    this.location.back()
  }
  ngOnInit() {
    this.CustomerSuportData()
    this.intervalId = setInterval(() => {
      this.CustomerSuportData();
    }, 5000);
  }

  ngAfterViewInit() {
    this.scrollToBottom()
  }

  ngOnDestroy() {
    clearInterval(this.intervalId);
  }

  CustomerSuportData() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.home_getMessageByUser}`).subscribe(res => {
      if (res) {
        this.userDetials = res?.messages;
        this.scrollToBottom()
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  SendData() {
    this.http.post(`${this.host?.localHost}${this.host?.home_sendmessage}`, { text: this.userSendData }).subscribe(res => {
      if (res) {
        this.CustomerSuportData()
        this.userSendData = ' '
        this.scrollToBottom()
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  DataChange(event: any) {
    this.userSendData = event.target.value;
  }

  scrollToBottom() {
    const chatMessages = document.querySelector('.chat-messages');
    if (chatMessages) {
      chatMessages.scrollTop = chatMessages.scrollHeight;
    }
  }

}
