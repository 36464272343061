<!-- <div class="main">
    <div class="chat-container">
        <div class="chat-header">
            <div (click)="NavigateUser()"><i class="fa-solid fa-arrow-left"></i></div>
            <div class="title">Customer Support</div>
            <div class="close-btn" (click)="NavigateUser()"><i class="fas fa-times"></i></div>
        </div>
        <div class="chat-messages userDetailsPadding message-container">
        <div  *ngFor="let data of userDetials">
            <div class="message received"  *ngIf="data?.senderRole !== 'user'">{{data?.text}}</div>
            <div class="messagess sentss user-sendData" *ngIf="data?.senderRole === 'user'">{{data?.text}}</div>
        </div>
    </div>
        <div class="chat-messages" *ngIf="!userDetials.length">
            <div class="message received">Hello! How can I help you?</div>
        </div>
        <div class="chat-input">
            <input type="text" placeholder="Type a message..." value="{{userSendData}}" (input)="DataChange($event)">
            <button (click)="SendData()"><i class="fa-regular fa-paper-plane"></i></button>
        </div>
    </div>
</div> -->
<div class="main">
    <div class="chat-container">
        <div class="chat-header">
            <div (click)="NavigateUser()"><i class="fa-solid fa-arrow-left"></i></div>
            <div class="title">Customer Support</div>
            <div class="close-btn" (click)="NavigateUser()"><i class="fas fa-times"></i></div>
        </div>

        <!-- Chat messages -->
        <div class="chat-messages userDetailsPadding message-container">
            <div *ngFor="let data of userDetials" [ngClass]="{'received': data?.senderRole !== 'user', 'sent': data?.senderRole === 'user'}" class="message">
                {{data?.text}}
            </div>
        </div>

        <!-- Default message if no messages exist -->
        <div class="chat-messages" *ngIf="!userDetials.length">
            <div class="message received">Hello! How can I help you?</div>
        </div>

        <!-- Chat input -->
        <div class="chat-input">
            <input type="text" placeholder="Type a message..." [(ngModel)]="userSendData" />
            <button (click)="SendData()"><i class="fa-regular fa-paper-plane"></i></button>
        </div>
    </div>
</div>
