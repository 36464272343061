<!-- <div class="container mt-4">
    <div class="row justify-content-center">
    <h5 class="card-title text-center fw-bold mb-3">Statement Details</h5>
    <div class="d-flex mb-3 gap-3 row">
        <button class="btn-click btn equal-width col-sm-3 col-md-3 {{userpage ? '' : 'btn-click-user'}}" (click)="userData('')">All</button>
        <button class="btn btn-click equal-width col-sm-3 col-md-3 {{userpage === 'Pending' ? 'btn-click-user' : ''}}" (click)="userData('Pending')">Pending</button>
        <button class="btn btn-click equal-width col-sm-3 col-md-3 {{userpage === 'Approved' ? 'btn-click-user' : ''}}" (click)="userData('Approved')">Approved</button>
        <button class="btn btn-click equal-width col-sm-3 col-md-3 {{userpage === 'Rejected' ? 'btn-click-user' : ''}}" (click)="userData('Rejected')">Rejected</button>
    </div>
      <div class="col-lg-4 col-md-6 col-sm-12 mb-4" *ngFor="let transaction of userdata">
        <div class="card shadow-sm" (click)="ShowModelData(transaction)">
          <div class="card-body">
            <div class="d-flex justify-content-between">
              <span class="fw-bold">Status:</span>
              <span [ngClass]="{'text-success': transaction.status === 'Active', 'text-warning': transaction.status === 'Pending'}">
                {{ transaction.status }}
              </span>
            </div>
            <div class="d-flex justify-content-between" *ngIf="transaction.type === 'Deposit'">
              <span class="fw-bold">Date:</span>
              <span>{{ transaction.rechargeDate }}</span>
            </div>
            <div class="d-flex justify-content-between" *ngIf="transaction.type === 'Withdrawal'">
              <span class="fw-bold">Date:</span>
              <span>{{ transaction.withdrawaDate }}</span>
            </div>
            <div class="d-flex justify-content-between">
                <span class="fw-bold">Amount:</span>
                <span class="text-primary fw-bold">₹{{ transaction.amount }}</span>
            </div>
            <div class="d-flex justify-content-between">
                <span class="fw-bold">Type:</span>
                <span class="text-primary fw-bold">{{ transaction.type }}</span>
              </div>
          </div>
        </div>
      </div>
    </div>
    <div class="d-flex align-item-center">
      <button class="{{userAllData?.pageNo <=1 ? '' : 'btn btn-primary'}}" (click)="PreviousData('Previous', userAllData?.status)"
      [disabled]="userAllData?.pageNo <=1 ? true : false"
      >&larr; Previous</button>
      <p class="m-0">{{userAllData?.pageNo}}</p>
      <button class="m-0 {{userAllData?.totalCount <= (userAllData?.itemsPerPage*userAllData?.pageNo) ? '' : 'btn btn-primary'}}" 
      [disabled]="userAllData?.totalCount <= (userAllData?.itemsPerPage*userAllData?.pageNo) ? true : false"
        (click)="PreviousData('Next', userAllData?.status)">Next &rarr;</button>
  </div>
  </div>
   -->


   <div class="col-12 mobile-screen-show header-fix" [ngClass]="{'dark-theme':darkMode}">
    <!-- Transactions -->
    <div class="all-feature-mobile mobile-transactions pb-0">
     
   <div class="title">All Statement</div>
   <div class="mobile-transaction-filter">
       <div class="filter">
        <form (ngSubmit)="onSubmit($event)">
            <div class="search">
              <!-- Search Input with ngModel -->
              <input type="text" placeholder="Search" *ngIf="!startDate" [(ngModel)]="searchQuery" name="search" required />
          
              <!-- Date Input with ngModel -->
              <input type="date" [(ngModel)]="startDate" name="date" [value]="startDate" required />
          <div style="margin-right: 10px;" *ngIf="startDate">TO </div>
              <input type="date"*ngIf="startDate" [(ngModel)]="endDate" name="date" value="" required />
              <!-- Submit Button with SVG Icon -->
              <button type="submit" class="apply-btn">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                  stroke-linecap="round" stroke-linejoin="round" data-lucide="search" icon-name="search" class="lucide lucide-search">
                  <circle cx="11" cy="11" r="8"></circle>
                  <path d="m21 21-4.3-4.3"></path>
                </svg>
              </button>
            </div>
          </form>
       </div>
   </div>
   </div>
   </div>
   <div class="col-12 mobile-screen-show" [ngClass]="{'dark-theme':darkMode}">
    <!-- Transactions -->
    <div class="all-feature-mobile mobile-transactions" style="height: 100vh; border: 0px;">
       
        <div class="contents scrollable-contents" >
            <div *ngFor="let transaction of userdata">

                <div class="single-transaction">
                    <div class="transaction-left">
                        <div class="transaction-des">
                            <div class="transaction-title">{{transaction?.type}} With {{transaction?.withdrawalType||transaction?.paymentType||'USDT'}}
                            </div>
                            <div class="transaction-id">{{transaction?.WithdrawalId||transaction?.orderId}}</div>
                            <div class="transaction-date">{{ transaction?.createdAt | date: 'MMM dd yyyy HH:mm' }}</div>
                        </div>
                    </div>
                    <div class="transaction-right">
                        <div class="transaction-amount">
                            {{transaction?.actually_paid|| transaction?.totalAmount | currency: (transaction?.type === 'Withdrawal' ? 'INR' : 'USD') }}
                          </div>
                        <div class="transaction-fee sub">
                           {{transaction?.convenienceFee||0}} % Fee </div>
                        <div class="transaction-gateway">{{transaction?.withdrawalType}}</div> 
    
    
                        <div class="transaction-status"  [ngClass]="{
                          'pending': transaction?.status === 'Pending', 
                          'success': transaction?.status === 'Success'
                       }">
                            {{ transaction?.status }}
                          </div>
                    </div>
                </div>
                <!-- <div class="single-transaction">
                    <div class="transaction-left">
                        <div class="transaction-des">
                            <div class="transaction-title">Deposit With Rahul kumar
                            </div>
                            <div class="transaction-id">TRXUTBD85EGMY</div>
                            <div class="transaction-date">Feb 06 2025 12:06</div>
                        </div>
                    </div>
                    <div class="transaction-right">
                        <div class="transaction-amount ">
                            +102 USDT</div>
                        <div class="transaction-fee sub">
                            -0 USDT Fee </div>
                        <div class="transaction-gateway">Ok</div>
    
    
                        <div class="transaction-status success">Success</div>
                    </div>
                </div> -->
            </div>
        </div>

    </div>

</div>