<div class="" *ngIf="showAllGetTicketpages" [ngClass]="{'dark-theme':darkMode}">
    <div class="min-vh-100">
        <div class="site-card" [ngClass]="{'dark-theme':darkMode}">
            <div class="site-card-header">
                <h3 class="title" [ngClass]="{'dark-theme':darkMode}">Support Tickets</h3>
                <div class="card-header-links">
                    <button (click)="showAddTicket()" class="card-header-link">Create
                        Ticket</button>
                </div>
            </div>
            <div class="site-card-body">
                <div class="site-transactions">

                    <div class="site-card-body" *ngIf="!getTicketData?.length">
                        <div class="site-transactions">
                            <p class="centered d-flex justify-content-center">No Data Found</p>
                        </div>
                    </div>

                    <div class="site-transaction" *ngFor="let ticket of getTicketData"
                        [ngClass]="{'dark-theme':darkMode}">
                        <div class="single" style="overflow: auto;" (click)="showFullData(ticket)">
                            <div class="left">
                                <div class="icon">
                                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                        fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                        stroke-linejoin="round" data-lucide="flag" icon-name="flag"
                                        class="lucide lucide-flag">
                                        <path d="M4 15s1-1 4-1 5 2 8 2 4-1 4-1V3s-1 1-4 1-5-2-8-2-4 1-4 1z"></path>
                                        <line x1="4" x2="4" y1="22" y2="15"></line>
                                    </svg>
                                </div>
                                <div class="content">
                                    <div class="title">{{ticket?.title}}</div>
                                    <div class="date">Created {{ ticket?.createdAt | date: 'medium' }}
                                        <span class="ms-2 status site-badge"
                                            [ngClass]="ticket?.status === 'Open' ? 'badge-pending' : (ticket?.status === 'Close' ? 'badge-failed' : 'badge-success')">
                                            {{ ticket?.status }}
                                        </span>



                                    </div>
                                </div>
                            </div>
                        </div>

                    </div>

                </div>
            </div>
        </div>
    </div>
</div>

<div class="row " *ngIf="showAddTicketpages" [ngClass]="{'dark-theme':darkMode}">
    <div class="min-vh-100">
        <div class="site-card" [ngClass]="{'dark-theme':darkMode}">
            <div class="site-card-header">
                <h3 class="title" [ngClass]="{'dark-theme':darkMode}">Add New Support Ticket</h3>
                <div class="card-header-links">
                    <button (click)="showAddTicket()" class="card-header-link">All Tickets</button>
                </div>
            </div>
            <div class="site-card-body">
                <div class="progress-steps-form">
                    <form>

                        <div class="">
                            <div class="col-xl-12 col-md-12">
                                <label class="form-label">Ticket Title</label>
                                <div class="input-group">
                                    <input type="text" class="form-control" name="title" (input)="TicketTitle($event)"
                                        id="Title">
                                </div>
                            </div>
                            <div class="col-xl-12 col-md-12">
                                <label class="form-label">Ticket Descriptions</label>
                                <div class="input-group">
                                    <textarea class="form-control textarea" name="message"
                                        style="height: 120px; font-size: larger;" (input)="TicketDescription($event)"
                                        id="Description"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="mb-3">
                            <div class="wrap-custom-file">
                                <input #fileInput type="file" name="attach" id="ticket-attach"
                                    accept="image/*,video/*,application/pdf" (change)="onFileSelect($event)" />
                                <label for="ticket-attach">
                                    <!-- Default upload icon when no file is selected -->
                                    <img class="upload-icon" *ngIf="!awsImagesURL&&!showupdateCss"
                                        src="{{'/assets/frontend/upload.svg'}}" alt="Upload Icon" />

                                    <!-- Image preview when the file is an image -->
                                    <img *ngIf="awsImagesURL && selectedFiletype.startsWith('image/')"
                                        [src]="awsImagesURL" alt="Image Preview" height="100%" />

                                    <!-- PDF icon when the file is a PDF -->
                                    <img *ngIf="awsImagesURL && selectedFiletype === 'application/pdf'"
                                        src="/assets/frontend/pdf.png" alt="PDF Icon" />


                                    <div *ngIf="showupdateCss" class="loader"></div>

                                    <!-- Placeholder text when no file is selected -->
                                    <video *ngIf="awsImagesURL&& selectedFiletype.startsWith('video/')"
                                        [src]="awsImagesURL" controls width="100%" height="100%">
                                        does not support the video
                                    </video>
                                    <span *ngIf="!awsImagesURL&&!showupdateCss">Attach File </span>
                                </label>

                            </div>
                        </div>
                        <div class="buttons">
                            <button type="submit" class="site-btn blue-btn" [disabled]="submitDisabled"
                                (click)="addTicket()">
                                Add New Ticket<i class="anticon anticon-double-right"></i>
                            </button>
                        </div>
                    </form>

                </div>
            </div>
        </div>
    </div>
</div>




<div *ngIf="showOneTicket">
    <div class="min-vh-100" [ngClass]="{'dark-theme':darkMode}">
        <div class="col-xl-12">
            <div class="site-card overflow-hidden">
                <div class="site-card-header" [ngClass]="{'dark-theme':darkMode}">
                    <!-- <h3 class="title status site-badge " style="overflow: auto;">  <span
                            [ngClass]="FullData?.status === 'Open' ? 'badge-pending' : (FullData?.status === 'Close' ? 'badge-failed' : 'badge-success')">
                            {{ FullData?.status }} </span></h3> -->
                            <span class="ms-2 status site-badge "
                            [ngClass]="FullData?.status === 'Open' ? 'badge-pending' : (FullData?.status === 'Close' ? 'badge-failed' : 'badge-success')">
                            {{ FullData?.status }}
                        </span>
                    <div class="card-header-links">
                        <button class="card-header-link rounded-pill" (click)="markItClose()" type="button">
                            Mark it close
                        </button>
                    </div>
                </div>

                <div class="site-card-body">
                    <div class="support-ticket-single-message user" style="overflow: auto;" [ngClass]="{'dark-theme':darkMode}">
                        <hr>
                        <!-- Title -->
                        <table style="width: 100%; margin-top: 1rem;">
                            <tr>
                                <td><strong>Title:</strong></td>
                                <td>{{'' + FullData?.title }}</td>
                            </tr>
                    
                            <!-- Mobile No. -->
                            <tr>
                                <td><strong>Mobile No.:</strong></td>
                                <td><a [href]="'tel:' + FullData?.mobileNumber">{{ FullData?.mobileNumber }}</a></td>
                            </tr>
                    
                            <!-- Date -->
                            <tr>
                                <td><strong>Date:</strong></td>
                                <td>{{ FullData?.createdAt | date: 'dd MMM yyyy, hh:mm a' }}</td>
                            </tr>
                    
                            <!-- Description -->
                            <tr>
                                <td><strong>Description:</strong></td>
                                <td>{{ FullData?.description }}</td>
                            </tr>
                    
                            <!-- Status -->
                            <tr>
                                <td><strong>Status:</strong></td>
                                <td>{{ FullData?.status }}</td>
                            </tr>
                    
                            <!-- TicketId -->
                            <tr>
                                <td><strong>TicketId:</strong></td>
                                <td>{{ FullData?.ticketId }}</td>
                            </tr>
                    
                            <!-- Remark (if available) -->
                            <tr *ngIf="FullData?.remark">
                                <td><strong>Remark:</strong></td>
                                <td>{{ FullData?.remark }}</td>
                            </tr>
                        </table>
                    
                        <!-- Attachments -->
                        <div class="message-attachments" style="margin-top: 1rem;">
                            <div class="title" style="margin-top: 0.5rem;">Attachments</div>
                            <div class="single-attachment" style="margin-top: 0.5rem;">
                                <div class="attach">
                                    <a *ngIf="FullData?.fileType =='application/pdf'"
                                       (click)="downloadPdf(FullData?.imagesKey)" target="_blank"
                                       style="margin-top: 0.5rem;">Download</a>
                                    <hr>
                                    <a [href]="FullData?.imagePath">
                                        <i class="anticon anticon-picture"></i>
                                        {{ FullData?.imagePath?.split('/').pop() }}
                                    </a>
                                </div>
                            </div>
                        </div>
                    
                        <!-- Image or File Preview -->
                        <div style="margin-top: 1rem;">
                            <div class="logo">
                                <img *ngIf="FullData?.imagePath && FullData?.fileType.startsWith('image/')"
                                    [src]="FullData?.imagePath" alt="Image Preview" height="100%" width="100%" />
                                
                                <!-- PDF icon when the file is a PDF -->
                                <img *ngIf="FullData?.imagePath && FullData?.fileType === 'application/pdf'"
                                    src="/assets/frontend/pdf.png" alt="PDF Icon" height="100%" width="100%" />
                    
                                <video *ngIf="FullData?.imagePath && FullData?.fileType.startsWith('video/')"
                                    [src]="FullData?.imagePath" controls width="100%" height="100%">
                                    Your browser does not support the video tag.
                                </video>
                            </div>
                        </div>
                    </div>
                    
                </div>

            </div>

        </div>
    </div>
</div>