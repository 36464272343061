import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { NgForm } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-add-bank-details',
  templateUrl: './add-bank-details.component.html',
  styleUrls: ['./add-bank-details.component.css']
})
export class AddBankDetailsComponent {
  bankDetails = { accountHolderName: '', accountNumber: '', ifscCode: '', bankName: '' }
  dataRetrive: any

  constructor(private http: HttpClient, private route: Router,
    private activeRoute: ActivatedRoute,
    private location: Location,
    private host: ApkDataService,
    private toastr: ToastrService

  ) {

  }

  CheckFOrNavigation() {
    this.activeRoute.queryParams.subscribe(params => {
      this.dataRetrive = JSON.parse(params['key'])
    })
  }

  async userBankDetails(bankdetails: any) {
    this.http.post(`http://${this.host?.localHost}${this.host?.user_addBank}`, { ...bankdetails }).subscribe((res) => {
      this.location.back();
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  onSubmit(bankDetails: NgForm) {
    this.userBankDetails(bankDetails.value)
  }
}
