import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile',
  templateUrl: './profile.component.html',
  styleUrls: ['./profile.component.css']
})
export class ProfileComponent {
shareUrl() {
throw new Error('Method not implemented.');

}
shareText: string = 'Check out this amazing website!'; 
  userDetials:any;
  todaysDate:any;
  todayDay:any;
  day=['Sun','Mon','Tue','Wedn','Thur','Fri','Sat']
  constructor(private route:Router,
    private http:HttpClient,
    private toastr: ToastrService,
    private host :ApkDataService,
  ){}

  ngOnInit(){
    this.UserDetails()
  }
       async onLogOut(){
          await this.http.post(`${this.host.localHost}${this.host.user_logout}`,{}).subscribe(res=>{
             if(res){
               localStorage.clear()
               this.route.navigateByUrl("/app-login")
             }
          },err=>{
           localStorage.clear()
           this.route.navigateByUrl("/app-login")
          })
       }
       UserDetails(){
          this.todaysDate = new Date().getDate()
          this.todayDay  = new Date().getDay()
          this.http.get(`${this.host.localHost}${this.host.user_UserInfo}`).subscribe(res=>{
             this.userDetials = res
          },err=>{
            this.toastr.error(err.error.message, 'Error');

          })
       }
       GetNavigate(data:string){
           this.route.navigateByUrl(data)
       }
       CustomerSupport(url:string){
        this.route.navigateByUrl(url)
       }
    // navigateToTelegram(): void {
    //   window.location.href = 'https://t.me/your_telegram_channel'; // Replace with your actual Telegram link
    // }

    // shareContent() {
    //   if (navigator.share) {
    //     // Web Share API is supported
    //     navigator.share({
    //       title: 'Awesome Share!',
    //       text: this.shareText,
    //       url: "rtyjk"
    //     }).then(() => {
    //       console.log('Content successfully shared!');
    //     }).catch((error) => {
    //       console.log('Error sharing:', error);
    //     });
    //   } else {
    //     // Fallback: Copy URL to clipboard if Web Share API is not supported
    //     alert('Sharing is not supported on this device. You can copy the link and share manually.');
    //     navigator.clipboard.writeText("")
    //     alert("copy link please share new ")
    //     // this.copyToClipboard("ghjkl");
    //   }
    // }
    // message: string = `Check out this amazing app referral code:  ${this.referralcode}  `;
    // customUrl: string = 'https://www.mediafire.com/file/98o3ag1ftahgb41/UK_pay.apk/file'; 
    // shareOnWhatsApp(data?:any) {
    //   if(data){      
    //     this.userDetials.code
    //   }   
    //   const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
    //     `${`Check out this amazing app referral code:  ${this.userDetials.code}  `} ${this.customUrl}`
    //   )}`;
    //   window.open(whatsappUrl);
    // }
  }
    // copyToClipboard(text: string) {
    //   const textArea = document.createElement('textarea');
    //   textArea.value = text;
    //   document.body.appendChild(textArea);
    //   textArea.select();
    //   document.execCommand('copy');
    //   document.body.removeChild(textArea);
    //   alert('URL copied to clipboard!');
    // }

// }
