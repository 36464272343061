import { Component, NgModule } from '@angular/core';
import { RouterModule, Routes } from '@angular/router';
import { LoginComponent } from './user-auth/login/login.component';
import { ForgotComponent } from './user-auth/forgot/forgot.component';
import { HomeComponent } from './user-auth/home/home.component';
import { ProfileComponent } from './user-auth/profile/profile.component';
import { ExchangeHComponent } from './user-auth/exchange-h/exchange-h.component';
import { TransactionSuccessComponent } from './user-auth/transaction-success/transaction-success.component';
import { authGuard } from './user-auth/guard/auto.guard';
import { DepositComponent } from './user-auth/deposit/deposit.component';
import { WithdrawalComponent } from './user-auth/withdrawal/withdrawal.component';
import { WithdrawalHistoryComponent } from './user-auth/withdrawal-history/withdrawal-history.component';
import { HistoryWithdrwalsComponent } from './user-auth/history-withdrwals/history-withdrwals.component';
import { AddBankDetailsComponent } from './user-auth/add-bank-details/add-bank-details.component';
import { CustomerSupportComponent } from './user-auth/customer-support/customer-support.component';
import { StatementComponent } from './user-auth/statement/statement.component';
import { ProfileBankAccountComponent } from './user-auth/profile-bank-account/profile-bank-account.component';
import { ShomodeldataComponent } from './user-auth/shomodeldata/shomodeldata.component';
import { NotificationComponent } from './user-auth/notification/notification.component';
import { UserSettingComponent } from './user-auth/user-setting/user-setting.component';
import { PassCodeForgetComponent } from './user-auth/Setting-component-data/pass-code-forget/pass-code-forget.component';
import { GetReffCodeComponent } from './user-auth/Setting-component-data/get-reff-code/get-reff-code.component';
import { TicketPageComponent } from './user-auth/ticket-page/ticket-page.component';
import { PointComponent } from './user-auth/point/point.component';
import { PointsExchangePagesComponent } from './user-auth/points-exchange-pages/points-exchange-pages.component';
import { SettingComponent } from './user-auth/setting/setting.component';
import { ChangePasswordComponent } from './user-auth/Setting-component-data/change-password/change-password.component';
import { SendMoneyComponent } from './user-auth/send-money/send-money.component';
import { CryptoMarketComponent } from './user-auth/crypto-market/crypto-market.component';
import { BottomAppbarComponent } from './user-auth/bottom-appbar/bottom-appbar.component';

const routes: Routes = [

  {
    path: '',
    redirectTo: 'app-home',
    pathMatch: 'full'
  },
  {
    path: 'app-login',
    component: LoginComponent
  },
  {
    path: 'app-forgot',
    component: ForgotComponent
  },
  {
    path:'app-home',
    component:HomeComponent,
    canActivate:[authGuard]
  },
  {
    path:'app-profile',
    component:ProfileComponent,
    canActivate:[authGuard]
  },
  {
    path:'exchange-histroy',
    component:ExchangeHComponent,
    canActivate:[authGuard]
  },
  {
    path:'app-transaction-success',
    component:TransactionSuccessComponent,
    canActivate:[authGuard]
  },{
    path:'deposits',
    component:DepositComponent,
    canActivate:[authGuard]
  },{
    path:'withdrawal',
    component:WithdrawalComponent,
    canActivate:[authGuard]
  },{
    path:'deposite-history',
    component:WithdrawalHistoryComponent,
    canActivate:[authGuard]
  },{
    path:'withdawal-history',
    component:HistoryWithdrwalsComponent,
    canActivate:[authGuard]
  },{
    path:'AddBankDetailsComponent',
    component:AddBankDetailsComponent,
    canActivate:[authGuard]
  },{
    path:'app-customer-support',
    component:CustomerSupportComponent,
    canActivate:[authGuard]
  },{
    path:'statement',
    component:StatementComponent,
    canActivate:[authGuard]
  },{
    path:'profile-bank-account',
    component:ProfileBankAccountComponent,
    canActivate:[authGuard]
  },{
    path:'shoe-model-payment',
    component:ShomodeldataComponent,
    canActivate:[authGuard]
  },{
    path:'app-notification',
    component:NotificationComponent,
    canActivate:[authGuard]
  },
  {
    path:'user-setting',
    component:UserSettingComponent,
    canActivate:[authGuard]
  },
  {
    path:'user-forgot-passcode',
    component:PassCodeForgetComponent,
    canActivate:[authGuard]
  },{
    path:'user-ref-code',
    component:GetReffCodeComponent,
    canActivate:[authGuard]
  },
  {
    path:'ticket',
    component:TicketPageComponent,
    canActivate:[authGuard]

  },{
    path:'points',
    component:PointComponent,
    canActivate:[authGuard]
  },{
    path:'points-exchange',
    component:PointsExchangePagesComponent,
    canActivate:[authGuard]
  },{
    path:'setting',
    component:SettingComponent,
    canActivate:[authGuard]
  },{
    path:'change password',
    component:ChangePasswordComponent,
  },
  {
    path:'send-money',
    component:SendMoneyComponent,
    canActivate:[authGuard]
  },{
    path:'crypto-market',
    component: CryptoMarketComponent,
    canActivate:[authGuard]
  },{
    path:'bottom-appbar',
    component:BottomAppbarComponent,
    canActivate:[authGuard]
  }
 
];

@NgModule({
  imports: [RouterModule.forRoot(routes)],
  exports: [RouterModule]
})
export class AppRoutingModule { }
