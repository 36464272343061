
<div class="box_body" [ngClass]="{'blur-background': showPopupModel}"[ngClass]="{'dark-theme':darkMode}">
<div class="dashboard" [ngClass]="{'dark-theme':darkMode}">
  <div class="balance-card">
    <h2>{{getIndianCurrency(userInfoData?.walletBalance || 0,
      userInfoData?.USDT_Price) | currency :'USD'}}</h2>
    <div class="balance-details">
      <div>
        <p>{{getPointsData?.selfWithdrawalTransactionPoint|number :'1.0-2'}}</p>
        <p>Self Withdraw</p>
      </div>

      <div>
        <p>{{getPointsData?.referralWithdrawalTransactionPoint |number :'1.0-2'}}</p>
        <p>Referra Withdraw</p>
      </div>
      <div>
        <p>{{getPointsData?.referralPoint|number :'1.0-2'}}</p>
        <p>Reward Points</p>
      </div>
    </div>
  </div>

  <div class="actions" [ngClass]="{'dark-theme':darkMode}">
    <div class="button withdraw-button" [ngClass]="{ 'active': selectedButton === 'self' }"
      (click)="selectButton('self')" (click)="openPopup('selfWithdraw',true)">
      Self<br><small>Withdraw</small>
    </div>

    <div class="button withdraw-button" [ngClass]="{ 'active': selectedButton === 'withdraw' }"
      (click)="selectButton('withdraw')" (click)="openPopup('referralWithdraw',true)">
      Referral<br><small>Withdraw</small>
    </div>

    <div class="button recharge-button" [ngClass]="{ 'active': selectedButton === 'recharge' }"
      (click)="selectButton('recharge')" (click)="openPopup('Point',true)">
      Poins<br><small>Withdraw</small>
    </div>
  </div>

</div>


<div [ngClass]="{'dark-theme':darkMode}">
  <div class="dashboard"[ngClass]="{'dark-theme':darkMode}" style=" height: 100vh;">
    <table>
      <thead>
        <tr>
          <th>#</th>
          <!-- <th>Amount</th> -->
          <th>Transaction </th>
          <th>Points</th>
        </tr>
      </thead>
      <tbody *ngFor="let pointsHistery of getTabelData  let index = index">
        <tr>
          <td>{{index+1}}</td>
          <!-- <td>{{pointsHistery?.amount}}</td> -->
          <td *ngIf="pointsHistery?.WithdrawalId">{{pointsHistery?.type}}</td>
          <td *ngIf="pointsHistery?.pointsType">{{pointsHistery?.pointsType}}</td>
          <td *ngIf="pointsHistery?.selfWithdrawalTransactionPoint"> + {{pointsHistery?.selfWithdrawalTransactionPoint||0}}</td>
          <td *ngIf="pointsHistery?.points"> - {{pointsHistery?.points||0}}</td>
  
        </tr>
      </tbody>
    </table>
    <div *ngIf="!getTabelData?.length" style="display: flex; justify-content: center;">
      <h2>No data  found</h2>
    </div>
  </div>
</div>
</div>



<!-- popup  -->

 <div *ngIf="showPopupModel" class="modal-container"[ngClass]="{'dark-theme':darkMode}">
  <div class="">
    <div class="modal-header">
      <h2>{{popupData?.title||'Points'}}</h2>
      <button (click)="closePopup()" class="close-button">X</button>
    </div>

    <div class="points-section">
      <div class="points-box">
        <h3>Total Reward Points</h3>
        <p>{{popupData?.points|number:'1.0-2'}}</p>
      </div>
      <div class="points-box" *ngIf="popupData?.Point_WorthShow">
        <h3>Points Worth</h3>
        <p>{{popupData?.PointsWorth|currency:'INR'}}</p>
      </div>
      <div class="points-box" *ngIf="!popupData?.Point_WorthShow">
        <h3>Main balance</h3>
        <p>{{getIndianCurrency(userInfoData?.walletBalance || 0,
          userInfoData?.USDT_Price) | currency :'USD'}}</p>
      </div>
    </div>

    <div class="info-box">
      <p>⚠️ Minimum {{popupData?.MinimumRewardpoints||100}} reward points redeem at a time.</p>
    </div>

    <input type="number" placeholder="Enter Points to Redeem" [disabled]="!popupData?.Point_WorthShow"
    (input)="redeemInputValue($event,popupData?.points)" [max]="popupData?.points"  class="input-box" [value]="redeemInputValueData"/>
    <button class="redeem-button" (click)="updateExchangePoints()" [disabled]="!popupData?.Point_WorthShow" >REDEEM POINTS</button>

    <div class="points-worth" *ngIf="popupData?.Point_WorthShow">
      <span class="highlight">{{popupData?.Point_Worth|currency:'INR'}} </span> <span class="spank-p"> Points = 1</span> 
    </div>
    <div class="points-worth" *ngIf="!popupData?.Point_WorthShow">
   <span class="spank-p">Better luck </span>    <span class="highlight"> next time! 😄</span>
    </div>
  </div>
</div>
