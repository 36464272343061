import { HttpClient } from "@angular/common/http";
import { Component, HostListener } from "@angular/core";
import { ActivatedRoute, Router } from "@angular/router";
import { AbstractControl, FormBuilder, FormGroup, NgForm, NgModel, ValidationErrors, ValidatorFn, Validators } from "@angular/forms";
import { InterfaceForApi } from "../interface-for-api";
import { ApkDataService } from "src/app/apk-data.service";
import { ToastrService } from 'ngx-toastr';
import { Location } from '@angular/common';
import { CookieService } from 'ngx-cookie-service';
@Component({
  selector: "app-login",
  templateUrl: "./login.component.html",
  styleUrls: ["./login.component.css"],
})
export class LoginComponent {
  loginForm: FormGroup;
  userLoginPhoneNUmber: any
  SuccessMessage: any
  istrue: boolean = false
  showPassword: boolean = false
  showConfirmPassword: boolean = false
  active: string = '';
  passwordMismatch: boolean = false
  landingPageObj: any = {
    getStarted: true,
    SignupSliding: false,
    singUp: false,
    singIN: false,
    forgot: false,
    otpPage: false,
  }
  timer: string = '00:59';
  forgotPasswordObj: any = {}
  invalidReferralCode: Boolean = false
  isexistUser: Boolean = false
  otpVerify: any
  constructor(private route: Router, private http: HttpClient,
    private host: ApkDataService, private toastr: ToastrService,
    private fb: FormBuilder,private cookieService: CookieService

  ) {
    this.loginForm = fb.group(
      {
        email: ['', [Validators.required, Validators.email]],
        fullName: ['', [Validators.required, Validators.pattern(/^[a-zA-Z\s]+$/)]],
        referrer: [''],

      },
      { validators: this.confirmPasswordValidator() }
    );


  }

  confirmPasswordValidator() {
    return (group: FormGroup): { [key: string]: any } | null => {
      const password = group.get('password')?.value;
      const confirmPassword = group.get('confirmPassword')?.value;

      // If the passwords do not match, return the error object
      if (password && confirmPassword && password !== confirmPassword) {
        return { passwordMismatch: true };
      }
      // If passwords match, return null indicating no error
      return null;
    };
  }

  // onForgot(data?: any) {
  //   if (typeof data !== "object") return;
  //   if (data) {
  //     this.route.navigate(["/app-forgot"], {
  //       queryParams: { key: JSON.stringify(data) },
  //     });
  //   } else {
  //     this.route.navigateByUrl("/app-forgot");
  //   }
  // }

  async onHomepage(form: NgForm) {
    let loginNumber = String(form.value.number)
    
    if (loginNumber.length !== 10 ) {
      form.reset()
      this.toastr.error('Please Enter Valid Details', 'Error');
      return
    }
    let receivedData = {
      mobileNumber: loginNumber,
    }
    this.GenerateOtpSendNumber(receivedData.mobileNumber)
    this.userAlreadySignIn(receivedData)
    // await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_loginwithMobile}`, { ...receivedData })
    //   .subscribe(res => {
    //     localStorage.setItem('auto', res.accessToken)
    //     this.route.navigateByUrl("/app-home");
    //   }, err => {
    //     this.toastr.error(err.error.message, 'Error');
    //     if (err.error.message === "Invalid password") {
    //       this.loginForm.get('password')?.reset();
    //       this.landingPageObj.showforgotPasswordBtn = true
    //     }
    //   })

  }
  async UserSignUP() {
    // const signUpDetails = this.loginForm.value;
    // delete signUpDetails.confirmPassword
    // this.userAlreadySignIn(signUpDetails);
    let payload = this.loginForm.value
    this.loginForm.value.mobileNumber = this.landingPageObj.mobileNumber
    this.loginForm.value.uniqueId=this.otpVerify.uniqueId
      if (this.loginForm.value.referrer) {
        
       this.isChakeRefferCode(this.loginForm.value.referrer)
      }else{
        delete payload.referrer
      this.userRegister(payload)
      }
  }
   async userRegister(payload: any) { 
    await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_registerWithMobile}`, { ...payload })
    .subscribe(
      (res) => {
        localStorage.setItem('auto', res?.accessToken)
       this. setCookie(res?.value,res?.gameToken)
        this.route.navigateByUrl('app-home')
      },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      }
    );
   }

  userAlreadySignIn(data: any) {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_isExistingUser}`, { params: { mobileNumber: data.mobileNumber } }).subscribe((res) => {
      if (res && res.existingUser) {
  this.isexistUser=res.existingUser
      } else {
        this.isexistUser=res.existingUser
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');

      console.log(err.error.message)
    })

  }
  isChakeRefferCode(data: any) {  
    this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_isInviteCodeValid}`, { inviteCode: data}).subscribe((res) => {
      if (res && res.isExistInvaiteCode) {
        this.loginForm.value.inviteCode=data
        delete this.loginForm.value.referrer
        this.userRegister(this.loginForm.value)
        return true
      } else {
        this.invalidReferralCode = true
        return false
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');

      console.log(err.error.message)
    })
  }
  async GenerateOtpSendNumber(phoneNo?: any, data?: any) {
    await this.http.post(`${this.host.localHost}${this.host.user_generateOTP}`, {
      mobileNumber: phoneNo,
    }).subscribe((res) => {
      if (res) {
        this.SuccessMessage = res
        if (this.SuccessMessage.status) {
          // this.toastr.success(this.SuccessMessage.status, 'Success');
          this.landingPageObj.mobileNumber = phoneNo
          this.landingPageObj.otpPage = true;
          this.landingPageObj.singIN = false
          this.startTimer(1);
        }
        // this.onForgot(data);
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');

      }
    );
  }
  userForgotPassword() {
    this.route.navigateByUrl('app-forgot')
    // let data = {}
    // this.route.navigate(["/app-forgot"], {queryParams: { key: JSON.stringify(data) },
  }
  // onNumberInput(event: Event): void {
  //   const input = event.target as HTMLInputElement;
  //   const value = input.value;

  //   // Enforce maximum 10 digits
  //   if (value.length > 10) {
  //     input.value = value.slice(0, 10);
  //     this.loginForm.get('mobileNumber')?.setValue(value.slice(0, 10));
  //   }
  // }


  userInput(event: any): void {
    this.active = event;
  }


  togglePasswordVisibility() {
    this.showPassword = !this.showPassword;
  }

  toggleConfirmPasswordVisibility() {
    this.showConfirmPassword = !this.showConfirmPassword;
  }


  async otpInputEvent(event: any) {
    let eventValue = event.target.value
    if (this.isexistUser) {
      if (eventValue.length === 6) {
        let payload = {
          mobileNumber: this.landingPageObj.mobileNumber,
          otp: eventValue
        }
        await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_loginwithMobile}`, { ...payload })
          .subscribe(
            (res) => {
              localStorage.setItem('auto', res?.accessToken)
              this.setCookie(res?.value,res?.gameToken)
              this.route.navigateByUrl('app-home')
            },
            (err) => {
              this.toastr.error(err.error.message, 'Error');
            }
          );
      }
      return//user_otpVerify
    }else if(!this.isexistUser && eventValue.length === 6){
      let payload = {
        mobileNumber: this.landingPageObj.mobileNumber,
        otp: eventValue,
        type: 'register'
      }
      await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_otpVerify}`, { ...payload })
      .subscribe(
        (res) => {
          this.landingPageObj.otpPage = false
          this.landingPageObj.singUp = true
          this.otpVerify = res
        },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        }
      );
    }
      let payload = this.loginForm.value
      payload.otp = eventValue
      // delete payload.sungUp
      // if (this.loginForm.value.referrer) {
      //   payload.inviteCode = this.loginForm.value.referrer
      // }
      // delete payload.referrer
      // await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_registerWithMobile}`, { ...payload })
      //   .subscribe(
      //     (res) => {
      //       localStorage.setItem('auto', res?.accessToken)
      //       this.route.navigateByUrl('app-home')
      //     },
      //     (err) => {
      //       this.toastr.error(err.error.message, 'Error');
      //     }
      //   );
      
    
    // if (this.loginForm.value.email && this.loginForm.value.fullName && eventValue.length === 6) {
    //   let payload = this.loginForm.value
    //   payload.otp = eventValue
    //   delete payload.sungUp
    //   if (this.loginForm.value.referrer) {
    //     payload.inviteCode = this.loginForm.value.referrer
    //   }
    //   delete payload.referrer
    //   await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_registerWithMobile}`, { ...payload })
    //     .subscribe(
    //       (res) => {
    //         localStorage.setItem('auto', res?.accessToken)
    //         this.route.navigateByUrl('app-home')
    //       },
    //       (err) => {
    //         this.toastr.error(err.error.message, 'Error');
    //       }
    //     );
    // } else if (eventValue.length === 6 && this.forgotPasswordObj['mobileNumber'] && this.forgotPasswordObj['password'] && this.forgotPasswordObj['ConformPassword']) {
    //   let payload = this.forgotPasswordObj
    //   payload.otp = eventValue
    //   await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_forgotPassword}`, { ...payload }).subscribe((res) => {
    //     let token = res.accessToken;
    //     localStorage.setItem('auto', token)
    //     this.route.navigateByUrl('app-home')

    //   }, err => {
    //     this.toastr.error(err.error.message, 'Error');
    //   }
    //   )
    // }
  }
  startTimer(minutes: number) {
    let timeInSeconds = minutes * 60;
    const interval = setInterval(() => {
      const minutesLeft = Math.floor(timeInSeconds / 60);
      const secondsLeft = timeInSeconds % 60;
      this.timer = `${this.pad(minutesLeft)}:${this.pad(secondsLeft)}`;

      if (timeInSeconds > 0) {
        timeInSeconds--;
      } else {
        clearInterval(interval);

      }
    }, 1000);
  }
  pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }

  forgotPasswordInputEvent(event: any, key: any) {
    this.forgotPasswordObj[key] = event.target.value
  }
  forgotPasswordSubmit() {
    if (this.forgotPasswordObj['mobileNumber'].length !== 10) {
      this.toastr.error('Please enter a valid mobile number', 'Error');
      return false;
    } else if (this.forgotPasswordObj['password'].length < 8) {
      this.toastr.error('Password must be at least 8 characters long', 'Error');
      return false;
    } else if (this.forgotPasswordObj['password'] !== this.forgotPasswordObj['ConformPassword']) {
      this.toastr.error('Conform Password does not match', 'Error');
      return false;
    }
    this.GenerateOtpSendNumber(this.forgotPasswordObj['mobileNumber'], { forgot: true })
    return true;
  }
  @HostListener('window:popstate', ['$event'])
  onPopState(event: PopStateEvent) {
    window.location.reload();
    // Close modal when the back button is pressed
    if (this.landingPageObj.forgot) {
// window.history.pushState(null, '', window.location.href);
      this.landingPageObj.SignupSliding = true
      this.landingPageObj.getStarted = false
    }
  }
  setCookie(auth: any,token:any) {
    // this.cookieService.set('auth', auth, 100); // The cookie will expire in 100 day
    // this.cookieService.set('token', token, 100);
  }

}
