<!-- <div class="crypto-container">
    <div *ngFor="let crypto of cryptos" class="crypto-item">
      <div class="crypto-info">
        <div class="crypto-logo">{{ crypto.symbol }}</div>
        <div>
          <div class="crypto-name">{{ crypto.name }}</div>
          <div class="crypto-symbol">{{ crypto.symbol }}</div>
        </div>
      </div>
      <div class="crypto-pricing">
        <div [ngClass]="crypto.up ? 'price-up' : 'price-down'">
          {{ crypto.change }} 
          <span class="arrow">{{ crypto.up ? '▲' : '▼' }}</span>
        </div>
        <div>{{ crypto.price }}</div>
      </div>
    </div>
  </div>
   -->
 
   <!-- <div style="display: flex;" [ngClass]="{'dark-theme':darkMode}">
    <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
        <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
            <img [src]="currency?.image" class="mb-0 p-0" />
            <p class="mt-4 fs-4">{{ currency?.name }}</p>
        </div>
        <div class="p-2 ms-2 mb-1">
            <div style="display: flex; gap: 20px;" class="mb-2">
                {{ currency?.current_price | currency: 'USD' }}
                {{ currency?.symbol.toUpperCase() }}
            </div>
            <span
                [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
                {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
                <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
                <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
            </span>
        </div>
    </div>
</div>

<div style="display: flex;" [ngClass]="{'dark-theme':darkMode}">
  <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
      <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
          <img [src]="currency?.image" class="mb-0 p-0" />
          <p class="mt-4 fs-4">{{ currency?.name }}</p>
      </div>
      <div class="p-2 ms-2 mb-1">
          <div style="display: flex; gap: 20px;" class="mb-2">
              {{ currency?.current_price | currency: 'USD' }}
              {{ currency?.symbol.toUpperCase() }}
          </div>
          <span
              [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
              {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
              <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
              <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
          </span>
      </div>
  </div>

</div>

<div style="display: flex;" [ngClass]="{'dark-theme':darkMode}">
  <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
      <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
          <img [src]="currency?.image" class="mb-0 p-0" />
          <p class="mt-4 fs-4">{{ currency?.name }}</p>
      </div>
      <div class="p-2 ms-2 mb-1">
          <div style="display: flex; gap: 20px;" class="mb-2">
              {{ currency?.current_price | currency: 'USD' }}
              {{ currency?.symbol.toUpperCase() }}
          </div>
          <span
              [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
              {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
              <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
              <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
          </span>
      </div>
  </div>
</div>

<div style="display: flex;" [ngClass]="{'dark-theme':darkMode}">
  <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
      <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
          <img [src]="currency?.image" class="mb-0 p-0" />
          <p class="mt-4 fs-4">{{ currency?.name }}</p>
      </div>
      <div class="p-2 ms-2 mb-1">
          <div style="display: flex; gap: 20px;" class="mb-2">
              {{ currency?.current_price | currency: 'USD' }}
              {{ currency?.symbol.toUpperCase() }}
          </div>
          <span
              [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
              {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
              <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
              <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
          </span>
      </div>
  </div>
</div> -->

<div class="main-market-card"[ngClass]="{'dark-theme':darkMode}" >
  <div class="market-card" [ngStyle]="!otherCurrency ? { 'height': '100vh' } : {}">
      <h2>Track the market</h2>
      <p>Make better investment decisions with real-time data at your fingertips</p>
      <!-- <div class="d-flex" style="margin-bottom: 20px;">
        <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
          <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
              <img [src]="currency?.image" class="mb-0 p-0" />
              <p class="mt-4 fs-4">{{ currency?.name }}</p>
          </div>
          <div class="p-2 ms-2 mb-1">
              <div style="display: flex; gap: 20px;" class="mb-2">
                  {{ currency?.current_price | currency: 'USD' }}
                  {{ currency?.symbol.toUpperCase() }}
              </div>
              <span
                  [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
                  {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
                  <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
                  <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
              </span>
          </div>
      </div>
      </div> -->
      <div style="margin-bottom: 60px;">
        <div class="crypto-item" *ngFor="let cur of otherCurrency;">
            <div class="crypto-name">
                <img [src]="cur?.image" alt="Bitcoin">
                <div>
                    <span class="name">{{ cur?.name }}</span>
                    <span class="symbol">{{ cur?.symbol.toUpperCase() }}</span>
                </div>
            </div>
            <div class="crypto-price">
                <div class="price">{{ cur?.current_price | currency: 'USD' }}</div>
                <div class="change change-up"> <span
                        [ngClass]="{ 'positive': isPositive(cur?.market_cap_change_percentage_24h), 'negative': !isPositive(cur?.market_cap_change_percentage_24h) }">
                        {{ cur?.market_cap_change_percentage_24h | number: '1.2-2' }}%
                        <span *ngIf="isPositive(cur?.market_cap_change_percentage_24h)">⬆</span>
                        <span *ngIf="!isPositive(cur?.market_cap_change_percentage_24h)">⬇</span>
                    </span></div>
            </div>
        </div>
      </div>
     
      <div *ngIf="!otherCurrency" style="display: flex; justify-content: center; align-items: center;">
        <div class="loader"></div>
      </div>
      <!-- <a href="#" class="view-button">View market data →</a> -->
  </div>
</div> 


<app-bottom-appbar></app-bottom-appbar>