import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { InterfaceForApi } from '../interface-for-api';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
import { CookieService } from 'ngx-cookie-service';

@Component({
  selector: "app-home",
  templateUrl: "./home.component.html",
  styleUrls: ["./home.component.css"],
})
export class HomeComponent {
  userdetails: any
  cryptoDetails: any;
  topCurrency: any;
  otherCurrency: any;
  timerValue: number = 59;

  allStatistic: any;
  allStatisticData: any
  isBlock: boolean = true;
  bootemIsBlock: Boolean = true
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  RecentTransactions: any
  showModal: boolean = true;
  popupMessge: boolean = false;
  getPintData: any;
  satting: any;
  constructor(private route: Router, private http: HttpClient,
    private host: ApkDataService, private toastr: ToastrService,
    private darkModeService: DarkModeService,private cookieService: CookieService
  ) { }
  onProfile() {
    this.route.navigateByUrl("/app-profile");
  }
  ngOnInit() {
    this.callInitialApi();
    // this.cryptoCurrencyData();
    this.Transactionsrécentes();
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.getStatemantData()
    this.getPointData()
    this. getSetting()
    this.gameuserInfo() 
  }
  onNotification() {
    this.route.navigateByUrl("/app-customer-support")
  }

  callInitialApi() {
    this.http.get(`${this.host?.localHost}${this.host?.user_UserInfo}`,).subscribe((res) => {
      if (res) {
        this.userdetails = res;
        if (this.userdetails?.user?.popupMessage?.isViewed == false) {
          let popupKey = this.getSessionStorage()
          if (popupKey !== 'true') {
            this.popupMessge = true
            this.setSessionStorage()
          }
          if (this.userdetails?.user?.popupMessage?.autoClose) {
            this.startTimer();
          }
        }

      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }
  getNavigate(navi: string) {
    this.route?.navigateByUrl(navi)
  }
  onCustomer() {
    this.route.navigateByUrl("app-customer-support")
  }

  // cryptoCurrencyData() {
  //   this.http.get(`${this.host?.localHost}${this.host?.home_getCryptocurrency}`).subscribe((res) => {
  //     if (res) {
  //       this.cryptoDetails = res;
  //       this.topCurrency = this.cryptoDetails?.data.slice(0, 2);
  //       this.otherCurrency = this.cryptoDetails?.data.slice(2);
  //     }
  //   },
  //     (err) => {
  //       this.toastr.error(err.error.message, 'Error');
  //     });
  // }
  Transactionsrécentes() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.home_allStatistic}`).subscribe((res) => {
      this.allStatistic = res
    }, (err) => {
      this.toastr.error(err.error.message, 'Error');
    })
  }


  isPositive(change: number): boolean {
    return change >= 0;
  }
  async onLogOut() {
    await this.http.post(`${this.host.localHost}${this.host.user_logout}`, {}).subscribe(res => {
      if (res) {
        localStorage.clear()
        this.route.navigateByUrl("/app-login")
      }
    }, err => {
      localStorage.clear()
      this.route.navigateByUrl("/app-login")
    })
  }
  startTimer(): void {
    setTimeout(() => {
      this.popupMessge = false
    }, 1000 *this.userdetails?.user?.popupMessage?.autoCloseTime||5 );
  }



  ngOnDestroy(): void {
    // if (this.interval) {
    //   clearInterval(this.interval);
    // }
  }

  getIndianCurrency(INR: any, USD: any) {
    return (Number(INR) / Number(USD)).toFixed(2);
  }


  topToggleDisplay() {
    this.isBlock = !this.isBlock;
  }
  bootemToggleDisplay() {
    this.bootemIsBlock = !this.bootemIsBlock;
  }
  getStatemantData() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getStament}`, { params: { 'limit': 4 } }).subscribe(res => {
      if (res) {
        this.RecentTransactions = res?.data;
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');

    })
  }
  userUpdateInfo(updateKey?:any ,value?:any) { 
    let payload
    if (updateKey) {
      payload = {
        [updateKey]: value
      }
    }else{
      if (this.userdetails?.user?.popupMessage?.oneTime == false) {
        this.popupMessge = false
        return
      }
       payload = {
        popupMessage: { isViewed: true}
      }
    }
    this.http.patch<InterfaceForApi>(`${this.host.localHost}${this.host.user_updateUserInfo}`, payload).subscribe(res => {
      if (res) {
        this.popupMessge = false
        this.callInitialApi();
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  setSessionStorage(): void {
    sessionStorage.setItem('popupMessage', 'true');
  }
  // Get from sessionStorage
  getSessionStorage(): string | null {
    return sessionStorage.getItem('popupMessage');
  }

  getPointData() {
    this.http.get(`${this.host.localHost}${this.host.home_getUserPointData}`,).subscribe(res => {
      if (res) {
        this.getPintData = res
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }

  getSetting() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.home_getSatting}`).subscribe((res) => {
      if (res) {
        this.satting = res;
    }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }
  shareOnWhatsApp() {
    this.http.patch(`${this.host.localHost}${this.host.home_shareLinkCount}`, null).subscribe(res => {
      if (res) {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${`Check out this amazing app referral code:  ${this.getPintData.code}  `} ${this.satting.appLink}`
        )}`;
        window.open(whatsappUrl);
        this.getPointData()
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  gameuserInfo() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.game_GameGetUserInfo}`, )
    .subscribe(
      (res) => {
        this.callInitialApi();
      },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      }
    );
  }
  setIpAndMac(path:any) {
    let payload={
       auth :this.cookieService.get('auth'),
       token :this.cookieService.get('token')
    }
    this.http.post(`${this.host.localHost}${this.host.game_setIpAndMac}`, {...payload})
      .subscribe(
        (res) => {
          this.windowLocation(path)
        },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        }
      );
  }
  windowLocation(path:any){
    console.log(path);
    
    // window.location.href = `http://127.0.0.1:3000/${path}`;
    window.location.href = `https://xpertclub.fun/${path}`;
  }
}
