import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { InterfaceForApi } from '../interface-for-api';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-points-exchange-pages',
  templateUrl: './points-exchange-pages.component.html',
  styleUrls: ['./points-exchange-pages.component.css']
})
export class PointsExchangePagesComponent {
  getPointsData: any
  getTabelData: any
  userInfoData: any
  selectedButton: string = 'self';
  showPopupModel: Boolean = false
  popupData: any
  sattingData: any
  redeemInputValueData: number = 0
  openPopupKey: any
  pointsHistory:any
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  constructor(private route: Router,
    private http: HttpClient,
    private toastr: ToastrService,
    private host: ApkDataService,
    private darkModeService: DarkModeService,

  ) { }
  ngOnInit(): void {
    this.getPointsExchange()
    this.getPointsHistery()
    this.userInfo()
    this.getSatting()
    // this.getExchangePointsHistory()
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
  }


  getPointsExchange() {
    this.http.get(`${this.host.localHost}${this.host.home_getExchangePoints}`).subscribe(res => {
      this.getPointsData = res
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }


  getPointsHistery() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.Withdrawal_getWithdrawalHisteryPoints}`).subscribe(res => {
      this.getTabelData = res.data
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }

  userInfo() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_UserInfo}`).subscribe(res => {
      this.userInfoData = res
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  getIndianCurrency(INR: any, USD: any) {
    return (Number(INR) / Number(USD));
  }


  selectButton(buttonType: string) {
    this.selectedButton = buttonType;
  }

  openPopup(model?: any, removeInput?: any) {
    this.openPopupKey = model
    if (this.openPopupKey == 'selfWithdraw') {
      this.popupData = {
        title: 'Self Withdrawal Reward ',
        points: Math.floor(this.getPointsData?.selfWithdrawalTransactionPoint) || 0,
        PointsWorth: this.redeemInputValueData / this.sattingData?.RewardPointsSelfPrise || this.getPointsData?.selfWithdrawalTransactionPoint / this.sattingData?.RewardPointsSelfPrise || 0,
        MinimumRewardpoints: this.sattingData?.minimumRewardSelf || 0,
        Point_Worth: this.sattingData?.RewardPointsSelfPrise || 0,
        Point_WorthShow: this.getPointsData?.selfWithdrawalTransactionPoint >= this.sattingData?.minimumRewardSelf
      }
    } else if (this.openPopupKey == 'referralWithdraw') {
      this.popupData = {
        title: 'Referral Withdrawal Reward ',
        points: Math.floor(this.getPointsData?.referralWithdrawalTransactionPoint) || 0,
        PointsWorth: this.redeemInputValueData / this.sattingData?.RewardPointsReferralPrise || this.getPointsData?.referralWithdrawalTransactionPoint / this.sattingData?.RewardPointsReferralPrise || 0,
        MinimumRewardpoints: this.sattingData?.minimumRewardReferral || 0,
        Point_Worth: this.sattingData?.RewardPointsReferralPrise || 0,
        Point_WorthShow: this.getPointsData?.referralWithdrawalTransactionPoint >= this.sattingData?.minimumRewardReferral
      }
    } else if (this.openPopupKey == 'Point') {
      this.popupData = {
        title: 'Referral Reward ',
        points: Math.floor(this.getPointsData?.referralPoint) || 0,
        PointsWorth: this.redeemInputValueData / this.sattingData?.RewardPointsPrise || this.getPointsData?.referralPoint / this.sattingData?.RewardPointsPrise || 0,
        MinimumRewardpoints: this.sattingData?.minimumRewardPoints || 0,
        Point_Worth: this.sattingData?.RewardPointsPrise || 0,
        Point_WorthShow: this.getPointsData?.referralPoint >= this.sattingData?.minimumRewardPoints
      }
    }
    if (removeInput) {
      this.redeemInputValueData = NaN;
    }
    this.showPopupModel = true
  }
  closePopup() {
    this.showPopupModel = !this.showPopupModel
    this.redeemInputValueData = NaN;
  }
  redeemInputValue(event: any, point: any) {
    const inputElement = event.target as HTMLInputElement;
    const maxBalance = point || 0;
    const inputValue = parseInt(inputElement.value, 10);
    if (inputValue > maxBalance) {
      inputElement.value = maxBalance.toString();
    }
    this.redeemInputValueData = event.target.value
    this.openPopup(this.openPopupKey)
  }

  getSatting() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getSatting}`).subscribe(res => {
      this.sattingData = res
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  async updateExchangePoints() {
    let payload = {
      [this.openPopupKey]: this.redeemInputValueData
    }
    if (!this.redeemInputValueData || isNaN(this.redeemInputValueData)) {
      this.toastr.error('Please enter a valid number', 'Error');
      return
    }
    await this.updateExchangePointsToDb(payload)

  }

  updateExchangePointsToDb(payload: any) {
    this.http.patch<InterfaceForApi>(`${this.host.localHost}${this.host.home_exchangePoints}`, payload).subscribe(res => {
      this.toastr.success('Points updated successfully', 'Success');
      this.closePopup()
      this.getPointsHistery()
      this.getPointsExchange()
    }, err => {
      this.toastr.error(err.error.message, 'Error');
      return false
    })
  }


}
