import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-exchange-h',
  templateUrl: './exchange-h.component.html',
  styleUrls: ['./exchange-h.component.css']
})
export class ExchangeHComponent {
  searchQuery: string = ''; // Store search text input
  startDate: string = ''; // Store date input
  endDate: string = ''; // Store date input
  sendMOneyData:any
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  constructor(private route: Router,
    private darkModeService: DarkModeService,
    private toastr: ToastrService,  private http: HttpClient,
    private host: ApkDataService,
  ) { }
  ngOnInit() {
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.getsendMOneyApiCall()
  }
  onHistoryView() {
    this.route.navigateByUrl("/app-profile")
  }
  onTranssation() {
    this.route.navigateByUrl("/app-transaction-success")
  }


  getsendMOneyApiCall(){
  
    this.http.get(`${this.host?.localHost}${this.host?.home_sendMoney}`).subscribe((res) => {
      this.sendMOneyData = res;
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }
  onSubmit(event: Event) {
    // event.preventDefault(); // Prevent the default form submission
    // Prepare the data to send to the API
    const formData = {
      search: this.searchQuery,
      startDate: this.startDate,
      endDate: this.endDate
    };
    if (formData.startDate && formData.endDate) {
      if (new Date(formData.startDate) > new Date(formData.endDate)) {
        this.toastr.error('Start date cannot be greater than end date.', 'Error');
        return;
      }
      formData.search = '';
    }
    // Call the API

  }
}
