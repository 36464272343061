<div class="row">
    <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
        <div class="site-card">
            <div class="site-card-header">
                <h3 class="title">Change Password</h3>
                <div class="card-header-links">
                    <a href="https://xpertclub.in/user/settings" class="card-header-link">Back</a>
                </div>
            </div>
            <div class="site-card-body">
                <div class="progress-steps-form">
                    <form action="https://xpertclub.in/user/password-store" method="post">
                        <input type="hidden" name="_token" value="viNmZoq90s5AA1YQ7ZuHZqeMwx89Q0ocKrXFu3hU">
                        
                        <div class="row">
                            <div class="col-xl-12 col-md-12">
                                <label for="exampleFormControlInput1" class="form-label">Current Password</label>
                                <div class="input-group">
                                    <input type="password" name="current_password" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <label for="exampleFormControlInput1" class="form-label">New Password</label>
                                <div class="input-group">
                                    <input type="password" name="new_password" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <label for="exampleFormControlInput1" class="form-label">Confirm Password</label>
                                <div class="input-group">
                                    <input type="password" name="new_confirm_password" class="form-control">
                                </div>
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <button type="submit" class="site-btn blue-btn">Change Password</button>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</div>