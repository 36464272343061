<div class="settings-container">
    <h2>Forgot PassCode</h2>
    <form (ngSubmit)="onChangePassword()" #passwordForm="ngForm">
        <div class="form-group">
            <label for="newPassword">Enter your Password</label>
            <input
              type="passwords"
              id="newPasswords"
              name="newPasswords"
              [(ngModel)]="userPassword"
              required
            />
          </div>
      <div class="form-group">
        <label for="newPassword">New PassCode</label>
        <input
          type="password"
          id="newPassword"
          name="newPassword"
          [(ngModel)]="newPassword"
          minlength="4"
          maxlength="4"
          required
        />
      </div>
      <div class="form-group">
        <label for="confirmPassword">Confirm New PassCode</label>
        <input
          type="password"
          id="confirmPassword"
          name="confirmPassword"
          [(ngModel)]="confirmPassword"
          minlength="4"
          maxlength="4"
          required
        />
      </div>
      <div class="form-actions">
        <button type="submit" [disabled]="passwordForm.invalid">Update Password</button>
      </div>
    </form>
  </div>
  