import { Router } from '@angular/router';
import { HttpClient } from '@angular/common/http';
import { Component, EventEmitter, Input, Output } from '@angular/core';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-profile-bank-account',
  templateUrl: './profile-bank-account.component.html',
  styleUrls: ['./profile-bank-account.component.css']
})
export class ProfileBankAccountComponent {
  constructor( private http:HttpClient, private route:Router,
    private toastr: ToastrService,
    private host :ApkDataService,
  ){}
userDetials:any;
userAllDetaisl:any;
pagination = {
  itemsPerPage:10,
  pageNo:1
}
ngOnInit(){
  this.GetAllBankAllDetails()
}
GetAllBankAllDetails(){
  this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_getBankDetails}`,{params : this.pagination}).subscribe(res=>{
    if(res){
        this.userDetials = res?.bankDetails;
        this.userAllDetaisl = res;
    }
  },err=>{
    this.toastr.error(err.error.message, 'Error');

  })
}
  delete(bankId:any) {
    this.http.patch<InterfaceForApi>(`${this.host.localHost}${this.host.user_Deletebank}`,{bankId}).subscribe(res=>{
      if(res){
          this.GetAllBankAllDetails()
      }
    },err=>{
      this.toastr.error(err.error.message, 'Error');

    })
  }
  PaginationPage(status:string){
      if(status === 'Prevoius'){
         this.pagination.pageNo -= 1
      }else{
        this.pagination.pageNo += 1
      }
      this.GetAllBankAllDetails()
  }
  Navigate(){
    this.route.navigate(['AddBankDetailsComponent'],{queryParams:{key:'reverse'}})
  }
}
