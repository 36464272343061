import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-forgot',
  templateUrl: './forgot.component.html',
  styleUrls: ['./forgot.component.css']
})
export class ForgotComponent {
  isOTPsent: boolean = true;
  otp: string[] = ['', '', '', ''];
  isOtpComplete: boolean = false
  receivedData: any
  numbertoSendOtp: any
  numberForOtpVerification: any
  userForgotPassword: boolean = false
  newpasswordforuser: any
  signuppagedata: boolean = false;
  inviteCodeshowAndHide: boolean = false;
  invitedCode: any;


  constructor(private route: Router,
    private routedata: ActivatedRoute,
    private http: HttpClient,
    private host: ApkDataService,
    private toastr: ToastrService

  ) { }
  ngOnInit(): void {
    this.startTimer();
    this.queryParamsData()
  }
  queryParamsData() {
    this.routedata.queryParams.subscribe(params => {
      this.receivedData = JSON.parse(params['key']);
      let verifyingDataSource = Object.values(this.receivedData)
      if (verifyingDataSource.length == 2) {
        this.signuppagedata = true;
      }
    });
  }
  onLogin() {
    this.route.navigateByUrl("/app-login")
  }
  async CallGeneratOtp(key: string) {
    this.receivedData.otp = key
    if (this.invitedCode !== "") {
      this.receivedData.inviteCode = this.invitedCode
    } else {
      delete this.receivedData.inviteCode;
    }
    await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_registerWithMobile}`, { ...this.receivedData })
      .subscribe(
        (res) => {
          localStorage.setItem('auto', res?.accessToken)
          this.route.navigateByUrl('app-home')
        },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        }
      );
  }

  handleInput(event: Event, index: number): void {
    const input = event.target as HTMLInputElement;
    const value = input.value;
    if (!/^\d$/.test(value)) {
      input.value = '';
      return;
    }
    this.otp[index] = value;
    this.isOtpComplete = this.otp.every((val) => val !== '');
    if (this.isOtpComplete) {
      let userdata = this.otp.join('')
      if (!this.userForgotPassword) {
        this.CallGeneratOtp(userdata)
      } else {
        this.callForgotApi(userdata)
      }
    }
    if (value && index < this.otp.length - 1) {
      const nextInput = input.nextElementSibling as HTMLInputElement;
      if (nextInput) {
        nextInput.value = '';
        nextInput.focus();
      }
    }
  }

  handleBackspace(event: KeyboardEvent, index: number): void {
    const input = event.target as HTMLInputElement;
    if (event.key === 'Backspace') {
      this.otp[index] = '';
      this.isOtpComplete = false;
      input.value = '';
      if (index > 0) {
        const previousInput = input.previousElementSibling as HTMLInputElement;
        if (previousInput) {
          previousInput.focus();
          previousInput.value = '';
          this.otp[index - 1] = '';
        }
      }
    }
  }
  verifyOtp(): void {
    this.isOTPsent = !this.isOTPsent;
    const otpValue = Number(this.otp.join(''));
    console.log('OTP Verified:', otpValue);
  }

  timeLeft: number = 120;
  timer: any;

  startTimer() {
    this.timer = setInterval(() => {
      if (this.timeLeft > 0) {
        this.timeLeft--;
      } else {
        clearInterval(this.timer);
      }
    }, 1000);
  }
  resendOtp() {
    this.timeLeft = 60;
    this.startTimer();
    this.SendOtpToNUmber()
  }

  userEnterPhoneNUmber(event: any, data: string) {
    if (data === 'num') {
      this.numbertoSendOtp = event.target.value
    } else {
      this.newpasswordforuser = event.target.value
    }
  }
  async SendOtpToNUmber() {
    let phoneNumber = String(this.numbertoSendOtp)
    if (phoneNumber.length !== 10) {
      return
    }

    await this.http.post(`${this.host.localHost}${this.host.user_generateOTP}`, { mobileNumber: phoneNumber }).subscribe((res) => {
      if (res) {
        this.userForgotPassword = true;
        this.numberForOtpVerification = phoneNumber;
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      }
    );
  }
  async callForgotApi(otp: string) {
    if (this.newpasswordforuser.lenght < 8) {
      this.toastr.error("password must be 8 currector", 'Error');
      return
    }
    await this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_forgotPassword}`, { mobileNumber: this.numberForOtpVerification, otp, password: this.newpasswordforuser }).subscribe((res) => {
      let token = res.accessToken;
      localStorage.setItem('auto', token)
      this.route.navigateByUrl('app-home')

    }, err => {
      this.toastr.error(err.error.message, 'Error');
    }
    )

  }

  userInviteCode(event: any) {
    this.invitedCode = event.target.value;
  }

}

