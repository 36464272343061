import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApkDataService } from 'src/app/apk-data.service';
import { InterfaceForApi } from '../interface-for-api';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode/dark-mode.service';
@Component({
  selector: 'app-ticket-page',
  templateUrl: './ticket-page.component.html',
  styleUrls: ['./ticket-page.component.css']
})
export class TicketPageComponent {

  showAddTicketpages: Boolean = false
  showAllGetTicketpages: Boolean = true
  showOneTicket: Boolean = false
  submitDisabled: boolean = true;
  getTicketData: any
  TicketDescriptionInputData: any
  TicketTitleinputData: any
  selectedFiletype: any
  file: File | null = null;
  awsImagesURL: string | undefined
  imagesKey: any
  FullData: any
  showupdateCss:Boolean=false
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  constructor(private route: Router, private http: HttpClient,
    private host: ApkDataService, private toastr: ToastrService,
     private darkModeService: DarkModeService,

  ) { }
  ngOnInit() {
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.getTicket()

  }
  showAddTicket() {
    this.showAddTicketpages = !this.showAddTicketpages
    this.showAllGetTicketpages = !this.showAllGetTicketpages
  }
  getTicket() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getTicketByUser}`,).subscribe(res => {
      if (res) {
        this.getTicketData = res.tickets
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  TicketTitle(event: any) {
    this.TicketTitleinputData = event.target.value
  }
  TicketDescription(event: any): void {
    this.TicketDescriptionInputData = event.target.value
  }

  onFileSelect(event: any): void {
    const input = event.target as HTMLInputElement;
    const selectedFile = input.files?.[0];

    if (selectedFile) {
      const allowedTypes = ['image/jpeg',
        'image/png',
        'image/gif',
        'application/pdf',
        'video/mp4',
        'video/webm',
        'video/ogg',
        'video/x-matroska']

      if (allowedTypes.includes(selectedFile.type)) {
        this.selectedFiletype = selectedFile.type
        if (selectedFile.size <= 25 * 1024 * 1024) { // Limit size to 5MB
          this.file = selectedFile;
          this.uploadFile(); // Call the upload method
        } else {
          this.toastr.error('File size exceeds 25 MB. Please select a smaller file.', 'Error');
          input.value = ''; // Reset input value
        }
      } else {
        this.toastr.error('Invalid file type. Only images (jpeg, png, gif) and PDFs are allowed.', 'Error');
        input.value = ''; // Reset input value
      }
    }
  }


  uploadFile(): void {
    if (this.file) {
      this.showupdateCss=true
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('uploadUse', 'Ticket');
      this.http.post(`${this.host.localHost}${this.host.document_update}`, formData)
        .subscribe({
          next: (response: any) => {
            this.awsImagesURL = response.URL;
            this.imagesKey = response.key
            this.toastr.success('File uploaded successfully!', 'success');
            this.submitDisabled = !this.submitDisabled
            this.showupdateCss=false
          },
          error: (error: HttpErrorResponse) => {
            this.showupdateCss=false
            this.toastr.error('Error uploading file. Please try again', 'Error');
          }
        });
    } else {
      this.toastr.error('No file selected.', 'Error');
    }
  }


  addTicket() {
    const ticket = {
      title: this.TicketTitleinputData,
      description: this.TicketDescriptionInputData,
      imagePath: this.awsImagesURL,
      fileType: this.selectedFiletype,
      imagesKey: this.imagesKey
    };
    this.http.post(`${this.host.localHost}${this.host.home_addTicket}`, ticket)
      .subscribe({
        next: (response: any) => {
          this.getTicket()
          this.toastr.success('Ticket added successfully!', 'success');
          this.TicketTitleinputData = '';
          this.TicketDescriptionInputData = '';
          this.file = null;
          this.awsImagesURL = '';
          this.showAllGetTicketpages = !this.showAllGetTicketpages
          this.showAddTicketpages = !this.showAddTicketpages
        },
        error: (error: HttpErrorResponse) => {
          // console.error('Error adding ticket:', error);
          // alert('Error adding ticket. Please try again.');
          this.toastr.error(error.error.message, 'Error');

        }
      });
  }


  showFullData(data: any) {// show full data of ticket
    this.FullData = data
    this.showOneTicket = !this.showOneTicket
    this.showAllGetTicketpages = !this.showAllGetTicketpages
  }
  markItClose() {
    this.showOneTicket = !this.showOneTicket
    this.showAllGetTicketpages = !this.showAllGetTicketpages
  }
  downloadPdf(imagesKey: string): void {
    this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.document_convartBlob}`, { imagesKey })
      .subscribe(
        (response: any) => {
          const url = response.url;
          const downloadLink = document.createElement('a');
          downloadLink.href = url;
          downloadLink.target = '_blank';
          downloadLink.download = 'downloaded-Ticket.pdf';
          downloadLink.click();
          // this.toastr.success('PDF file downloaded successfully!', 'Success');
        },
        (error: HttpErrorResponse) => {
          console.error('Error downloading PDF:', error);
          this.toastr.error('Error downloading PDF. Please try again.', 'Error');
        }
      );
  }
}



