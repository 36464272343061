import { Component, OnDestroy, OnInit } from '@angular/core';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { ToastrService } from 'ngx-toastr';
import { ApkDataService } from 'src/app/apk-data.service';
import { Router } from '@angular/router';
@Component({
  selector: 'app-setting',
  templateUrl: './setting.component.html',
  styleUrls: ['./setting.component.css']
})
export class SettingComponent implements OnInit, OnDestroy {
  darkMode: boolean = false;
  private darkModeSubscription!: Subscription;
  selectedFiletype:any
  file:any
  userdetails:any
  constructor(private darkModeService: DarkModeService,private http: HttpClient
    ,  private toastr: ToastrService,private host: ApkDataService,private route:Router
  ) {
   
  }

  ngOnInit(): void {
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.callInitialApi()
  }

  enableDarkMode(event: Event): void {
    const isChecked = (event.target as HTMLInputElement).checked;
    this.darkModeService.setDarkMode(isChecked);
  }

  ngOnDestroy(): void {
    if (this.darkModeSubscription) {
      this.darkModeSubscription.unsubscribe();
    }
  }

    onFileSelect(event: any): void {
      const input = event.target as HTMLInputElement;
      const selectedFile = input.files?.[0];
      if (selectedFile) {
        const allowedTypes = ['image/jpeg', 'image/png']
  
        if (allowedTypes.includes(selectedFile.type)) {
          this.selectedFiletype = selectedFile.type
          if (selectedFile.size <= 25 * 1024 * 1024) { // Limit size to 5MB
            this.file = selectedFile;
            this.uploadFile(); // Call the upload method
          } else {
            this.toastr.error('File size exceeds 25 MB. Please select a smaller file.', 'Error');
            input.value = ''; // Reset input value
          }
        } else {
          this.toastr.error('Invalid file type. Only images (jpeg, png, gif) and PDFs are allowed.', 'Error');
          input.value = ''; // Reset input value
        }
      }
    }
    uploadFile(): void {
      if (this.file) {
        const formData = new FormData();
        formData.append('file', this.file);
        formData.append('uploadUse', 'WithdrawalQrCode');
        this.http.post(`${this.host.localHost}${this.host.document_update}`, formData)
          .subscribe({
            next: (response: any) => {
              // this.aadQrCodeData.qrCodeImages = response.URL;
              // this.aadQrCodeData.imagesKey = response.key
              this.toastr.success('File uploaded successfully!', 'success');
            },
            error: (error: HttpErrorResponse) => {
              this.toastr.error('Error uploading file. Please try again', 'Error');
            }
          });
      } else {
        this.toastr.error('No file selected.', 'Error');
      }
    }

    callInitialApi() {
      this.http.get(`${this.host?.localHost}${this.host?.user_UserInfo}`,).subscribe((res) => {
        this.userdetails = res
      },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        });
    }
    async onLogOut(){
      await this.http.post(`${this.host.localHost}${this.host.user_logout}`,{}).subscribe(res=>{
         if(res){
           localStorage.clear()
           this.route.navigateByUrl("/app-login")
         }
      },err=>{
       localStorage.clear()
       this.route.navigateByUrl("/app-login")
      })
   }

}
