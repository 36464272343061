import { Component } from '@angular/core';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { InterfaceForApi } from '../interface-for-api';
import { HttpClient } from '@angular/common/http';
@Component({
  selector: 'app-send-money',
  templateUrl: './send-money.component.html',
  styleUrls: ['./send-money.component.css']
})
export class SendMoneyComponent {
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  userdetails:any
  resObj:any={}
  resData:any
  satting:any
   constructor(private route: Router,
      private http: HttpClient,
      private toastr: ToastrService,
      private host: ApkDataService,
      private darkModeService: DarkModeService,
  
    ) { }
    ngOnInit(): void {
      this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
        (enabled) => (this.darkMode = enabled)
      );
      this.callInitialApi()
      this.getSetting()
    }
    getIndianCurrency(INR: any, USD: any) {
      return (Number(INR) / Number(USD));
    }
    callInitialApi() {
      this.http.get(`${this.host?.localHost}${this.host?.user_UserInfo}`,).subscribe((res) => {
        this.userdetails = res;
      },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        });
    }

    mobileNumberAndNotsInputValue(event:any,key:any){   
      this.resObj[key]=event.target.value
    }
    redeemInputValue(event: any,) {
      const inputElement = event.target as HTMLInputElement;
      const maxBalance = Math.floor (this.userdetails?.walletBalance / this.userdetails?.USDT_Price);
      const inputValue = parseInt(inputElement.value, 10);
      if (inputValue > maxBalance) {
        inputElement.value = maxBalance.toString();
      }
      this.resObj.amount=event.target.value
    }
    sendMOneyApiCall(){
      let  payload=this.resObj
      if (!payload.amount||!payload.mobileNumber) {
        this.toastr.error('One or more fields are empty.', 'Error');
        return
      }
      this.http.post(`${this.host?.localHost}${this.host?.home_sendMoney}`,{...payload}).subscribe((res) => {
        this.resData = res;
      },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        });
    }
    closePopup(){
      window.location.reload();
    }
    navigateByUrl(url: string) {
      this.route.navigateByUrl(url);
    }
    getSetting(){
      this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.home_getSatting}`).subscribe((res) => {
        if (res) {
          this.satting = res;
        }
      },
        (err) => {
          this.toastr.error(err.error.message, 'Error');
        });
    }
}
