import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { InterfaceForApi } from '../interface-for-api';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { Clipboard } from '@angular/cdk/clipboard';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';

@Component({
  selector: 'app-deposit',
  templateUrl: './deposit.component.html',
  styleUrls: ['./deposit.component.css']
})
export class DepositComponent {
  userdata: any;
  firstClick = false;
  userDetailsPost: any = {}
  userdetails: any
  timer: string = '20:00';
  princeInIndianCurrency: any
  options: string[] = ['Option 1', 'Option 2', 'Option 3'];
  selectedOption: string = '';
  // gatewayOptions = [
  //   { value: 'manual', name: 'Manual' },
  //   { value: 'auto', name: 'Paypal' },
  //   { value: 'invoice', name: 'Cryptomus' }
  // ];
  gatewayOptions: any=[]
  autoPayQrCodePopup: Boolean = false
  nowPayGetQrCode: any
  showPopup = false;
  DepositPadingPopup: Boolean = false
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  private interval: any;
  paymanFinishedStatus: any
  paymantSuccessfull: Boolean = false
  satting: any
  payNowClick: Boolean = true
  constructor(private http: HttpClient, private route: Router, private host: ApkDataService,
    private toastr: ToastrService, private clipboard: Clipboard, private darkModeService: DarkModeService,


  ) {

  }
  ngOnInit() {
    this.getApiData()
    this.callInitialApi()

    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.getSetting()
  }
  ngOnDestroy(): void {
    // if (this.interval) {
    //   clearInterval(this.interval);
    // }
  }//todo 
  getApiData() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.user_getQRcodeByuser}`, { params: { status: 'Pending' } }).subscribe(res => {
      this.userdata = res
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  PostData(data: any) {
    this.http.post(`${this.host?.localHost}${this.host?.recharge_addManualPaymentRequest}`, { ...data }).subscribe(res => {
      this.DepositPadingPopup = true
      // this.route.navigateByUrl('deposite-history')
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  ShowNextData() {
    if (this.userDetailsPost['amount'] && this.userDetailsPost['HashId']) {
      this.PostData(this.userDetailsPost)
      this.firstClick = false;
      this.showPopup = !this.showPopup;
    }
  }
  UserchangeData(event: any, data: string) {
    if (data === 'amount') {
      this.userDetailsPost['amount'] = event.target.value
      this.princeInIndianCurrency = (event.target.value * this.userdetails)
    } else {
      this.userDetailsPost['HashId'] = event.target.value.trim();
    }
  }
  callInitialApi() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.user_UserInfo}`).subscribe((res) => {
      if (res) {
        this.userdetails = res?.USDT_Price;
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }
  onProfile() {
    if (this.firstClick) {
      this.firstClick = false;
      return;
    }
    this.route.navigateByUrl("/app-home");
  }
  CopyUrl(copyUrl?: any) {
    let copydata
    if (copyUrl) {
      copydata = copyUrl
    } else {
      copydata = this.userdata?.USDT_Address;
    }
    if (copydata) {
      const success = this.clipboard.copy(copydata);
      if (success) {
        console.log('Copied:', copydata);
        this.toastr.success('Text Copied Successfully!', 'Success'); // Optional
      } else {
        this.toastr.error('Failed to copy the text.', 'Error'); // Optional
      }
    } else {
      this.toastr.error('No data to copy.', 'Error'); // Optional
    }
  }

  startTimer(minutes: number) {
    let timeInSeconds = minutes * 60;
    const interval = setInterval(() => {
      const minutesLeft = Math.floor(timeInSeconds / 60);
      const secondsLeft = timeInSeconds % 60;
      this.timer = `${this.pad(minutesLeft)}:${this.pad(secondsLeft)}`;

      if (timeInSeconds > 0) {
        timeInSeconds--;
      } else {
        clearInterval(interval);

      }
    }, 1000);
  }

  pad(value: number): string {
    return value < 10 ? '0' + value : value.toString();
  }
  navigateByUrl(url: string) {
    this.route.navigateByUrl(url);
  }
  togglePopup() {
    if (this.selectedOption == 'manual') {
      this.showPopup = !this.showPopup;
    }
    else if (this.selectedOption == 'auto') {
      if (this.userDetailsPost.amount) {
        let payload = {
          amount: this.userDetailsPost.amount
        }
        this.nowPayQRcodeCreatePayment(payload)
      
      } else {
        this.toastr.error('Please enter the amount to proceed.', 'Error');
      }
    }
    else if (this.selectedOption == 'invoice') {
      if (this.userDetailsPost.amount) {
        let payload = {
          amount: this.userDetailsPost.amount
        }
        this.createPaymentInvoice(payload)
      } else {
        this.toastr.error('Please enter the amount', 'Error');
      }

    }
    else {
      this.toastr.error('One or more fields are empty.', 'Error');
    }


  }
  closePopup() {
    this.showPopup = false;
    this.DepositPadingPopup = false
  }
  nowPayQRcodeCreatePayment(payload: any) {
    this.http.post(`${this.host?.localHost}${this.host?.recharge_createPayment}`, { ...payload }).subscribe(res => {
      if (res) {
        this.nowPayGetQrCode = res
        this.autoPayQrCodePopup = true
        this.startTimer(20);
        let query = {
          id: this.nowPayGetQrCode.paymentDetails.payment_id
        }
        this.paymantStatus(query)
      }
      this.payNowClick =true
      // this.payNowClick=true
      // this.route.navigateByUrl('deposite-history')
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }

  createPaymentInvoice(payload: any) {
    this.http.post(`${this.host?.localHost}${this.host?.recharge_createPaymentInvoice}`, { ...payload }).subscribe(res => {
      if (res) {
        this.nowPayGetQrCode = res
        const invoiceUrl = this.nowPayGetQrCode?.paymentDetails?.invoice_url;
        let id = this.nowPayGetQrCode?.paymentDetails.id
        let query = {
          id
        }
        if (invoiceUrl) {
          window.open(invoiceUrl);
          this.paymantStatus(query)
        } else {
          this.toastr.error('Failed to generate invoice', 'Error');
        }
      }
      // this.route.navigateByUrl('deposite-history')
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  paymantStatus(query: any) {
    this.interval = setInterval(() => {
      this.http.post<InterfaceForApi>(`${this.host?.localHost}${this.host?.recharge_getPaymantStatus}`, { ...query, }).subscribe(
        (res) => {
          if (res && res.paymentDetails.payment_status === 'finished') {//  finished
            // Payment was successful
            this.paymanFinishedStatus = res.paymentDetails
            this.toastr.success('Payment Successful', 'Success');
            clearInterval(this.interval); // Stop polling after success
            this.paymantSuccessfull = true
          }
        },
        (err) => {
          // Handle error (optional)
          // this.toastr.error(err.error.message, 'Error');
          // clearInterval(this.interval); // Optionally stop on error
        }
      );
    }, 9000); // Poll every 5 seconds
  }
  getSetting() {
    this.http.get<InterfaceForApi>(`${this.host?.localHost}${this.host?.home_getSatting}`).subscribe((res) => {
      if (res) {
        this.satting = res;
        // console.log(this.satting.gatewayOptions[i]);
        if (this.satting.gatewayOptionsAuto) {
          this.gatewayOptions.push({"name":this.satting.gatewayOptions[0].auto.name,"value":this.satting.gatewayOptions[0].auto.value})
        }
        if(this.satting.gatewayOptionsManual){
          this.gatewayOptions.push({"name":this.satting.gatewayOptions[0].manual.name,"value":this.satting.gatewayOptions[0].manual.value})
        }
        console.log(this.gatewayOptions,"mkomoimoi");
      
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }

  isButtonDisabled(): boolean {

    if (this.selectedOption === 'manual') {
      return !this.userDetailsPost?.amount||this.userDetailsPost?.amount < this.satting?.manualMinimumDepositAmount ||
             this.userDetailsPost?.amount > this.satting?.manualMaximumDepositAmount;
    }

    if (this.selectedOption === 'auto') {
      return !this.userDetailsPost?.amount|| this.userDetailsPost?.amount < this.satting?.autoPayMinimumDepositAmount ||
             this.userDetailsPost?.amount > this.satting?.autoPayMaximumDepositAmount;
    }
    return false; 
  }
   insertThreeSpaces(inputString:any) {
    return inputString.slice(0, 12) + '   ' + inputString.slice(12, 24) + '   ' + inputString.slice(24);
}
paymanFinishedStatustoFixed(actually_paid:any,outcome_amount:any){
return (actually_paid-outcome_amount).toFixed(2)||0
}
  
}