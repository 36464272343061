import { Injectable } from '@angular/core';
import { BehaviorSubject, Observable } from 'rxjs';

@Injectable({
  providedIn: 'root'
})
export class DarkModeService {
  private darkModeEnabled = new BehaviorSubject<boolean>(true); // Default value is set to true (dark mode)
  darkMode$: Observable<boolean> = this.darkModeEnabled.asObservable();

  constructor() {
    // Get the dark mode value from localStorage or default to true (dark mode)
    const darkMode = localStorage.getItem('dark-mode') === 'false' ? false : true;
    this.darkModeEnabled.next(darkMode);

    // Set dark mode class based on the stored or default value
    if (darkMode) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  setDarkMode(enabled: boolean): void {
    this.darkModeEnabled.next(enabled);
    localStorage.setItem('dark-mode', String(enabled));

    if (enabled) {
      document.body.classList.add('dark-mode');
    } else {
      document.body.classList.remove('dark-mode');
    }
  }

  isDarkMode(): boolean {
    return this.darkModeEnabled.value;
  }
}
