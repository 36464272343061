<div class="container-fluid bg-new">
    <div class="profile-box ">
        <div class="row">
            <div class="profile-contaner">
                <div class="profile-detail">
                    <img
                       [src]="userDetials?.profileImage ||'assets/frontend/materials/user.png'"/>
                        <p>+91 {{userDetials?.mobileNumber}}</p>
                </div>
            </div>
        </div>
        <div class="row">
            <div class="col-4">
                <div class="t-a">
                    <p class="m-0">Total</p> 
                    <p class="">Amount</p>  
                    <h3>{{userDetials?.walletBalance/userDetials?.USDT_Price | currency : 'USD'}}</h3>
                </div>
            </div>
            <div class="col-4">
                <div class="t-a">
                    <p>Panding recharge</p>
                    <h3>${{(userDetials?.totalrecharge/userDetials?.USDT_Price).toString().length < 7 ? (userDetials?.totalrecharge/userDetials?.USDT_Price) : (userDetials?.totalrecharge/userDetials?.USDT_Price).toString().slice(0,6) + "..." }}</h3>
                </div>
            </div>
            <div class="col-4">
                <div class="t-a">
                    <p>Panding withdrawal</p>
                    <h3>{{userDetials?.totalWithdrawals/userDetials?.USDT_Price | currency : 'USD'}}</h3>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="container-fluid">
    
</div>
<div class="container-fluid">
    <div class="more-detail">
           
          
        <div class="row box-2 " (click)="GetNavigate('deposite-history')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-solid fa-wallet"></i></span>Deposite history</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
        <div class="row box-2 " (click)="GetNavigate('withdawal-history')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-solid fa-money-bill-transfer"></i></span>Withdrawal history</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>

        <div class="row box-3 " (click)="GetNavigate('statement')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-solid fa-file-invoice-dollar"></i></span>Statment</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
        <div class="row box-4" (click)="GetNavigate('profile-bank-account')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-solid fa-building-columns"></i></span>Bank account</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
        
        <div class="row box-5 "  (click)="GetNavigate('points')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-regular fa-share-from-square"></i></span>Refer & Earn</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
        <div class="row box-5" (click)="GetNavigate('points-exchange')">
            <div class="col-10">
              <div class="referrls">
                <p><span><i class="fa-solid fa-gift"></i></span> Points Exchange</p>
              </div>
            </div>
            <div class="col-2">
              <p><i  class="fa-solid fa-chevron-right"></i></p>
            </div>
          </div>
          
        <div class="row box-5 "(click)="CustomerSupport('/app-customer-support')">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-regular fa-hand"></i></span>Customer Support</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
        <div class="row box-1" (click)="GetNavigate('ticket')">
            <div class="col-10">
              <div class="referrls">
                <p>
                  <span><i class="fa-solid fa-ticket"></i></span> Support Tickets
                </p>
              </div>
            </div>
            <div class="col-2">
              <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
          </div>       
        <div class="row box-5" (click)="CustomerSupport('/user-setting')">
            <div class="col-10">
              <div class="referrls">
                <p><span><i class="fa-solid fa-gear"></i></span> Setting</p>
              </div>
            </div>
            <div class="col-2">
              <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
          </div>
          
        <!-- <div class="row box-5" (click)="navigateToCustomerSupport()">
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-regular fa-hand"></i></span>Customer Support</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div> -->
        
        <div class="log-out">
            <p (click)=" onLogOut()">Logout</p>
        </div>
    </div>
</div>