import { query } from '@angular/animations';
import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { Clipboard } from '@angular/cdk/clipboard';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-shomodeldata',
  templateUrl: './shomodeldata.component.html',
  styleUrls: ['./shomodeldata.component.css']
})
export class ShomodeldataComponent {
  cartDetails: any;
  userDetails: any;
  constructor(private activateRoute: ActivatedRoute,
    private host: ApkDataService,
    private http: HttpClient,
    private clipboard: Clipboard,
    private toastr: ToastrService
  ) { }
  ngOnInit() {
    this.UserDetailsShow()
  }
  UserDetailsShow() {
    this.activateRoute.queryParams.subscribe(params => {
      const transactionData = params['data'];
      if (transactionData) {
        this.cartDetails = (JSON.parse(transactionData))
      }
    })
    this.userDetailsShow()
  }

  userDetailsShow() {
    this.http.get(`${this.host?.localHost}${this.host?.getStamentOneDetails}`, { params: { ...this.cartDetails } }).subscribe((res) => {
      this.userDetails = res;
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
  
      });
  }
  formatHashId(hashId: string): string {
    if (!hashId) return '';
    return hashId.match(/.{1,15}/g)?.join(' ') || hashId;
  }

  copyReferenceID(content: any) {
    this.clipboard.copy(content);
    this.toastr.success('Text Copied Successfully!', 'Success');
  }
}
