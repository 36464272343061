<div class="box-body" [ngClass]="{'dark-theme':darkMode}">
    <div class="col-xl-12">
        <div class="site-card">
            <div class="site-card-header">
                <h3 class="title">Send Money</h3>
                <div class="card-header-links">
                    <a (click)="navigateByUrl('exchange-histroy')" class="card-header-link">SEND MONEYLOG</a>
                </div>
            </div>
            <div class="site-card-body">
                <div class="progress-steps-form">
                    <form>
                        <div class="row">
                            <div class="col-xl-6 col-md-12">
                                <label for="exampleFormControlInput1" class="form-label">User Mobile Number </label>
                                <div class="input-group">
                                    <input type="tel" name="email" maxlength="10" required="" class="form-control userCheck"
                                    (input)="mobileNumberAndNotsInputValue($event,'mobileNumber')"  placeholder=" Enter Mobile Number">
                                </div>
                                <div class="input-info-text notifyUser"></div>
                            </div>
                            <div class="col-xl-6 col-md-12">
                                <label for="exampleFormControlInput1" class="form-label">Enter Amount </label>
                                <div class="input-group">
                                    <input type="tel" class="form-control sendAmount" name="amount" required=""
                                    (input)="redeemInputValue($event)"  placeholder="Enter Amount" aria-label="Amount">
                                    <span class="input-group-text"
                                        style="padding: 11px;">{{getIndianCurrency(userdetails?.walletBalance || 0,
                                        userdetails?.USDT_Price) | currency :'USD'}}</span>
                                </div>
                                <div class="input-info-text"><span *ngIf="satting?.minimumSendMoneyAmount">Minimum {{satting?.minimumSendMoneyAmount}} USDT</span><span *ngIf="satting?.maximumSendMoneyAmount">and Maximum {{satting?.maximumSendMoneyAmount}} USDT</span> </div>
                            </div>
                            <div class="col-xl-12 col-md-12 mt-3">
                                <label for="exampleFormControlInput1" class="form-label">Send Money Note
                                    (Optional)</label>
                                <div class="input-group">
                                    <textarea class="form-control-textarea" placeholder="Send Money Note" name="note"
                                    (input)="mobileNumberAndNotsInputValue($event,'nots')"   spellcheck="false"></textarea>
                                </div>
                            </div>
                        </div>
                        <div class="transaction-list table-responsive">
                            <div class="user-panel-title">
                                <h3>Send Money Details</h3>
                            </div>
                            <table class="table">
                                <tbody>
                                    <tr>
                                        <td><strong>Payment Amount</strong></td>
                                        <td><span class="previewAmount"></span> {{resObj?.amount||0|currency:'USD'}}</td>
                                    </tr>
                                    <tr>
                                        <td><strong>Charge</strong></td>
                                        <td><span class="previewCharge"></span> {{satting?.SendMoneyConvenienceFee||0}} %</td>
                                    </tr>
                                    <tr>
                                        <td><strong>User Email</strong></td>
                                        <td class="userEmail"></td>
                                    </tr>
                                </tbody>
                            </table>
                        </div>

                        <div class="buttons">
                            <button type="submit" class="site-btn blue-btn" (click)="sendMOneyApiCall()">
                                Send Money<i class="anticon anticon-double-right"></i>
                            </button>
                        </div>
                    </form>

                </div>

            </div>
        </div>
    </div>
</div>


<!-- popup -->


<div class="app-container" [ngClass]="{'dark-theme':darkMode}" *ngIf="resData?.sendMoneyId">
    <div class="popup-overlay">
      <div style="width: 90%;">
  
        <div class="col-xl-12">
          <div class="site-card">
            <div class="site-card-header">
              <h3 class="title">Sand  Money</h3>
              <div class="card-header-links">
                <a (click)="navigateByUrl('exchange-histroy')" class="card-header-link">Send Money History</a>
              </div>
            </div>
            <div class="site-card-body">
          
              <div class="progress-steps-form">
                <div class="transaction-status centered">
                  <div style="margin-bottom: 10px;">
                    <img src="/assets/frontend/pending.png" alt="" srcset="" height="65px">
                  </div>
                  <h2>$ {{resData?.usdtAmount}} Send Money</h2>
                  <!-- <p>Your transaction has been successfully processed and the amount has been sent.</p> -->
                  <p>You have successfully sent the amount to the recipient with mobile number: {{ resData?.recieverMobileNumber || '---' }}</p>
                  <p>Transaction ID: {{resData?.sendMoneyId||'---'}}</p>
                  <a (click)="closePopup()" class="site-btn">
                    <i class="anticon anticon-plus"></i>Send again
                  </a>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  
  