import { Component } from '@angular/core';
import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Clipboard } from '@angular/cdk/clipboard';

import { Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { ApkDataService } from 'src/app/apk-data.service';
import { InterfaceForApi } from '../interface-for-api';
import { Subscription } from 'rxjs';
import { DarkModeService } from '../dark-mode/dark-mode.service';
@Component({
  selector: 'app-point',
  templateUrl: './point.component.html',
  styleUrls: ['./point.component.css']
})
export class PointComponent {


  showTeam: Boolean = false
  showMyData: Boolean = true
  showLavel: Boolean = false
  PointsData: any
  getPintData: any
  referralPoints: any
  selfPoints: any
  activeTab: string = 'data';
  masterData:any
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  constructor(private route: Router, private http: HttpClient,
    private host: ApkDataService, private toastr: ToastrService, private clipboard: Clipboard,
      private darkModeService: DarkModeService,
  ) { }
  ngOnInit() {
    this.getPointData()
    this.getMasterData()
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
  }

  GetNavigate(data:string){
    this.route.navigateByUrl(data)
}
  getPointData() {
    this.http.get(`${this.host.localHost}${this.host.home_getUserPointData}`,).subscribe(res => {
      if (res) {
        this.getPintData = res
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  myData() {
    this.showMyData = true
    this.showTeam = false
    this.showLavel = false

  }
  MyTeam() {
    this.showTeam = true
    this.showMyData = false
    this.showLavel = false

  }
  lavel() {
    this.showLavel = true
    this.showTeam = false
    this.showMyData = false
    this.getLavel()
  }

  getLavel() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_referralAndSelfPoints}`).subscribe(res => {
      if (res) {
        this.referralPoints = res.data.referralPoints
        this.selfPoints = res.data.selfPoints
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })

  }

  customUrl: string = 'https://www.mediafire.com/file/98o3ag1ftahgb41/UK_pay.apk/file';
  shareOnWhatsApp() {
    this.http.patch(`${this.host.localHost}${this.host.home_shareLinkCount}`, null).subscribe(res => {
      if (res) {
        const whatsappUrl = `https://api.whatsapp.com/send?text=${encodeURIComponent(
          `${`Check out this amazing app referral code:  ${this.getPintData.code}  `} ${this.customUrl}`
        )}`;
        window.open(whatsappUrl);
        this.getPointData()
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })


  }
  copyUrl(data: any) {
    if (data == 'code') {
      const copydata = this.getPintData.code;
      if (copydata) {
        const success = this.clipboard.copy(copydata);
        if (success) {
          this.toastr.success(`Code Copied Successfully!  ${copydata}`, 'Success'); // Optional
        } else {
          this.toastr.error('Failed to copy the text.', 'Error'); // Optional
        }
      } else {
        this.toastr.error('No data to copy.', 'Error'); // Optional
      }
    } else {
      const copydata = this.customUrl
      if (copydata) {
        const success = this.clipboard.copy(copydata);
        if (success) {
          this.toastr.success(`Link Copied Successfully! `, 'Success'); // Optional
        } else {
          this.toastr.error('Failed to copy the text.', 'Error'); // Optional
        }
      } else {
        this.toastr.error('No data to copy.', 'Error'); // Optional
      }
    }

  }


  setActiveTab(tab: string) {
    this.activeTab = tab;
  }
  getMasterData() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getmasterData}`).subscribe(res => {
      if (res) { 
        this.masterData = res.inviteFriendsPriceAndPoster
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })

  }
}
