import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { InterfaceForApi } from '../interface-for-api';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
import { HttpClient } from '@angular/common/http';

@Component({
  selector: 'app-crypto-market',
  templateUrl: './crypto-market.component.html',
  styleUrls: ['./crypto-market.component.css']
})
export class CryptoMarketComponent {
  // cryptos = [
  //   { name: 'Ethereum', symbol: 'ETH', price: '$1,803.65', change: '+5.12%', up: true },
  //   { name: 'Bash Protocol', symbol: 'BNB', price: '$100.23', change: '-2.89%', up: false },
  //   { name: 'Cardano', symbol: 'ADA', price: '$0.45', change: '+1.34%', up: true },
  //   { name: 'TRON', symbol: 'TRX', price: '$0.07', change: '+0.87%', up: true }
  // ];
  demoData = []
  cryptos:any
  cryptoDetails:any
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  topCurrency:any
  otherCurrency:any
  constructor(private route: Router, private http: HttpClient,
    private host: ApkDataService, private toastr: ToastrService,
    private darkModeService: DarkModeService
  ) { }

  ngOnInit() {
    this.cryptoCurrencyData();
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
  }

  isPositive(change: number): boolean {
    return change >= 0;
  }
  cryptoCurrencyData() {
    this.http.get(`${this.host?.localHost}${this.host?.home_getCryptocurrency}`).subscribe((res) => {
      if (res) {
        this.cryptoDetails = res;
        this.topCurrency = this.cryptoDetails?.data.slice(0, 2);
        this.otherCurrency = this.cryptoDetails?.data.slice(2);
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }

}
