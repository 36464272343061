import { Location } from '@angular/common';
import { Component, HostListener } from '@angular/core';
import { Router } from '@angular/router';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.css']
})
export class AppComponent {
  title = 'frontend-clint';
  isLoading = true;


  constructor(private route:Router,public _location:Location){

  }
  ngOnInit(){
    this.UserOthentigate()
     setTimeout(() => {
      this.isLoading = false;
    }, 1500); 
  }
  UserOthentigate(){
    this.isLoading = false;
    if(localStorage.getItem('auto')){
      const currentPath = decodeURI(this._location.path());
      if (currentPath && currentPath !== '/login') {
        this.route.navigateByUrl(currentPath, { skipLocationChange: true });
      } else {
        this.route.navigateByUrl('app-home');
      }
    }else{
       this.route.navigateByUrl('app-login')
    }
  }

}
