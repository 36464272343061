<!-- <div >
   <div class="container-fluid">
    <div class="row bg-b ">
        <div class="col-3">
            <div class="user-img">
                <img
                    src="{{userdetails?.profileImage||'assets/frontend/materials/user.png'}}">
            </div>
        </div>
        <div class="col-7">
            <div class="user-detail">
                <P>+91 {{userdetails?.mobileNumber}}</P>
            </div>
        </div>
        <div class="col-2">
            <div (click)="onNotification()" class="cus-sup">
                <p><i class="fa-solid fa-bell"></i></p>
            </div>
        </div>

        <div class="col-12">
            <div class="user-wallets-mobile">
                <img src="assets/frontend/materials/wallet-shadow.png" alt=""
                    class="wallet-shadow">
                <div class="head">All Wallets in USDT</div>
                <div class="one">
                    <div class="balance">

                        <span class="symbol"></span>{{getIndianCurrency(userdetails?.walletBalance || 0,
                        userdetails?.USDT_Price) | currency :'USD'}}<span class="after-dot"></span>
                    </div>
                    <div class="wallet">Main Wallet</div>
                </div>


                <div class="one p-wal">
                    <div class="balance">
                        <span class="symbol"></span>{{userdetails?.walletBalance ||0 | currency :'INR'}}
                    
                    </div>
                    <div class="wallet">Indian Rupees</div>
                </div>
                <div class="info">
                    <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                        stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                        data-lucide="info" icon-name="info" class="lucide lucide-info">
                        <circle cx="12" cy="12" r="10"></circle>
                        <path d="M12 16v-4"></path>
                        <path d="M12 8h.01"></path>
                    </svg>1 USDT = {{userdetails?.USDT_Price||0 | currency :'INR'}}
                </div>
            </div>
   
        </div>
    </div>
   </div>
    <div class="credit-card">
        <p class="timing">Automatic refresh after <span class="s-c">{{timerValue}}</span></p>
    </div>
    <div style="display: flex;">
        <div class="banner m-2 w-50" *ngFor="let currency of topCurrency;">
            <div class="d-flex justify-content-between align-items-center m-1 me-3 ms-3">
                <img [src]="currency?.image" class="mb-0 p-0" />
                <p class="mt-4 fs-4">{{ currency?.name }}</p>
            </div>
            <div class="p-2 ms-2 mb-1">
                <div style="display: flex; gap: 20px;" class="mb-2">
                    {{ currency?.current_price | currency: 'USD' }}
                    {{ currency?.symbol.toUpperCase() }}
                </div>
                <span
                    [ngClass]="{ 'positive': isPositive(currency?.market_cap_change_percentage_24h), 'negative': !isPositive(currency?.market_cap_change_percentage_24h) }">
                    {{ currency?.market_cap_change_percentage_24h | number: '1.2-2' }}%
                    <span *ngIf="isPositive(currency?.market_cap_change_percentage_24h)">⬆</span>
                    <span *ngIf="!isPositive(currency?.market_cap_change_percentage_24h)">⬇</span>
                </span>
            </div>
        </div>
    </div>



    <div class="container-fluid fixed-bottom-menu">
        <div class="row">
            <div class="col-4">
                <div class="icon1" (click)="getNavigate('deposits')">
                    <p><i class="fa-solid fa-money-bill-transfer"></i></p>
                    <p>Deposit</p>
                </div>
            </div>
            <div class="col-4">
                <div class="icon2" (click)="getNavigate('withdrawal')">
                    <p><i class="fa-duotone fa-solid fa-indian-rupee-sign"></i></p>
                    <p>Withdraw</p>
                </div>
            </div>
            <div class="col-4">
                <div class="icon3" (click)="onProfile()">
                    <p><i class="fa-solid fa-user"></i></p>
                    <p>Profile</p>
                </div>
            </div>
        </div>
    </div>

    <div class="main-market-card">
        <div class="market-card">
            <h2>Track the market</h2>
            <p>Make better investment decisions with real-time data at your fingertips</p>
            <div class="crypto-item" *ngFor="let cur of otherCurrency;">
                <div class="crypto-name">
                    <img [src]="cur?.image" alt="Bitcoin">
                    <div>
                        <span class="name">{{ cur?.name }}</span>
                        <span class="symbol">{{ cur?.symbol.toUpperCase() }}</span>
                    </div>
                </div>
                <div class="crypto-price">
                    <div class="price">{{ cur?.current_price | currency: 'USD' }}</div>
                    <div class="change change-up"> <span
                            [ngClass]="{ 'positive': isPositive(cur?.market_cap_change_percentage_24h), 'negative': !isPositive(cur?.market_cap_change_percentage_24h) }">
                            {{ cur?.market_cap_change_percentage_24h | number: '1.2-2' }}%
                            <span *ngIf="isPositive(cur?.market_cap_change_percentage_24h)">⬆</span>
                            <span *ngIf="!isPositive(cur?.market_cap_change_percentage_24h)">⬇</span>
                        </span></div>
                </div>
            </div>
            <a href="#" class="view-button">View market data →</a>
        </div>
    </div> -->









<!-- <div class="col-12">
        <div class="user-ranking-mobile">
            <div class="icon"><img src="/assets/frontend/images/user.png" alt=""></div>
            <div class="name">
                <h4>Hi, Rahul Kumar</h4>
                <p>Hyip Leader - <span>Level 2</span></p>
            </div>
            <div class="rank-badge"><img src="https://lpq.211.mytemp.website/assets/global/images/TQDUvbD48kmhmV9qifzh.svg" alt=""></div>
        </div>
        <div class="user-wallets-mobile">
            <img src="https://lpq.211.mytemp.website/assets/frontend/materials/wallet-shadow.png" alt="" class="wallet-shadow">
            <div class="head">All Wallets in USD</div>
            <div class="one">
                <div class="balance">

                    <span class="symbol">$</span>0<span class="after-dot">.00 </span>
                </div>
                <div class="wallet">Main Wallet</div>
            </div>


            <div class="one p-wal">
                <div class="balance">
                    <span class="symbol">$</span>18<span class="after-dot">.00 </span>
                </div>
                <div class="wallet">Profit Wallet</div>
            </div>
            <div class="info">
                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round" data-lucide="info" icon-name="info" class="lucide lucide-info"><circle cx="12" cy="12" r="10"></circle><path d="M12 16v-4"></path><path d="M12 8h.01"></path></svg>You Earned 18 USD This Week
            </div>
        </div>
    </div> -->











<div class="main-content" [ngClass]="{'dark-theme':darkMode}">
    <div class="section-gap">
        <div class="container-fluid">

            <div class="mobile-screen-show ">
                <div class="row">
                    <div class="col-12">
                        <div class="user-ranking-mobile">
                            <div class="icon"><img [src]="userdetails?.user?.profileImage" alt="">
                            </div>
                            <div class="name">
                                <h4>Hi, {{userdetails?.fullName}}</h4>
                                <p>Kwik Member  <span></span></p>
                            </div>
                            <div class="rank-badge" (click)="getNavigate('setting')"><img
                                    src="/assets/Windows_Settings_app_icon.png" alt="">
                            </div>
                        </div>
                        <div class="user-wallets-mobile ">
                            <!-- <img src="assets/frontend/materials/wallet-shadow.png" alt="" class="wallet-shadow"> -->
                            <div class="head">Main Wallet in USDT (TRC20)</div>
                            <div class="one">
                                <div class="balance">

                                    <span class="wallet "></span> <span style="font-size: 20px;">Total Value (USDT)</span><span class="after-dot"></span>
                                </div>
                                <div class="balance">{{getIndianCurrency(userdetails?.walletBalance || 0,
                                    userdetails?.USDT_Price) }}</div>
                            </div>


                            <div class="one p-wal">
                                <div class="balance">
                                    <span class="symbol"></span><span>Indian Rupees</span>
                                </div>
                                <div class="wallet">{{userdetails?.walletBalance ||0 | currency :'INR'}}</div>
                            </div>
                            <div class="info" style="font-size: 17px;">
                                <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24"
                                    fill="none" stroke="currentColor" stroke-width="2" stroke-linecap="round"
                                    stroke-linejoin="round" data-lucide="info" icon-name="info"
                                    class="lucide lucide-info">
                                    <circle cx="12" cy="12" r="10"></circle>
                                    <path d="M12 16v-4"></path>
                                    <path d="M12 8h.01"></path>
                                </svg>1 USDT = {{userdetails?.USDT_Price||0 | currency :'INR'}}
                            </div>
                        </div>
                    </div>

                    <div class="col-12">
                        <div class="mob-shortcut-btn">
                            <a (click)="getNavigate('deposits')"> <svg xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" data-lucide="download"
                                    icon-name="download" class="lucide lucide-download">
                                    <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                    <polyline points="7 10 12 15 17 10"></polyline>
                                    <line x1="12" x2="12" y1="15" y2="3"></line>
                                </svg> Deposit</a>
                            <a (click)="getNavigate('crypto-market')"  ><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" data-lucide="box" icon-name="box"
                                    class="lucide lucide-box">
                                    <path
                                        d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                                    </path>
                                    <polyline points="3.29 7 12 12 20.71 7"></polyline>
                                    <line x1="12" x2="12" y1="22" y2="12"></line>
                                </svg>Crypto market</a>
                            <a (click)="getNavigate('withdrawal')"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                    height="24" viewBox="0 0 24 24" fill="none" stroke="currentColor" stroke-width="2"
                                    stroke-linecap="round" stroke-linejoin="round" data-lucide="send" icon-name="send"
                                    class="lucide lucide-send">
                                    <line x1="22" x2="11" y1="2" y2="13"></line>
                                    <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                </svg> Withdraw</a>
                        </div>
                    </div>


                    <div class="col-12">
                        <!-- all navigation -->
                        <div class="all-feature-mobile mb-3 mobile-screen-show">
                            <div class="title">All Navigations And games</div>
                            <div class="contents row">
                                <div class="col-4" (click)="setIpAndMac('win')">
                                    <div class="single">
                                        <a>
                                            <div class="icon"><img src="/assets/games/win.png" alt="">
                                            </div>
                                            <div class="name">Win Go</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4" (click)="setIpAndMac('k3')">
                                    <div class="single">
                                        <a>
                                            <div class="icon"><img src="/assets/games/k3.png" alt="">
                                            </div>
                                            <div class="name">K3 Game</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4" (click)="setIpAndMac('5d')">
                                    <div class="single">
                                        <a>
                                            <div class="icon"><img src="/assets/games/d5.png"
                                                    alt="">
                                            </div>
                                            <div class="name">5d Game</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="single">
                                        <a (click)="getNavigate('statement')">
                                            <div class="icon"><img src="/assets/frontend/materials/transactions.png"
                                                    alt="">
                                            </div>
                                            <div class="name">Transactions</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="single">
                                        <a (click)="getNavigate('deposits')">
                                            <div class="icon"><img src="/assets/frontend/materials/deposit.png" alt="">
                                            </div>
                                            <div class="name">Deposit</div>
                                        </a>
                                    </div>
                                </div>
                                <div class="col-4">
                                    <div class="single">
                                        <a (click)="getNavigate('deposite-history')">
                                            <div class="icon"><img src="/assets/frontend/materials/deposit-log.png"
                                                    alt="">
                                            </div>
                                            <div class="name">Deposit Log</div>
                                        </a>
                                    </div>
                                </div>
                              
                            </div>
                            <div class="moretext" [ngStyle]="{ 'display': isBlock ? '' : 'flex' }">
                                <div class="row contents">
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('points-exchange')">
                                                <div class="icon"><img src="/assets/frontend/materials/wallet-exchange.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Wallet Exch.</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('send-money')">
                                                <div class="icon"><img src="/assets/frontend/materials/transfer.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Transfer</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('exchange-histroy')">
                                                <div class="icon"><img src="/assets/frontend/materials/transfer-log.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Transfer Log</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('withdrawal')">
                                                <div class="icon"><img src="/assets/frontend/materials/withdraw.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Withdraw</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('withdawal-history')">
                                                <div class="icon"><img src="/assets/frontend/materials/withdraw-log.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Withdraw Log</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a>
                                                <div class="icon"><img src="/assets/frontend/materials/ranking.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Ranking Badge</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('points')">
                                                <div class="icon"><img src="/assets/frontend/materials/referral.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Referral</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('setting')">
                                                <div class="icon"><img src="/assets/frontend/materials/settings.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Settings</div>
                                            </a>
                                        </div>
                                    </div>
                                    <div class="col-4">
                                        <div class="single">
                                            <a (click)="getNavigate('ticket')">
                                                <div class="icon"><img
                                                        src="/assets/frontend/materials/support-ticket.png" alt="">
                                                </div>
                                                <div class="name">Support Ticket</div>
                                            </a>
                                        </div>
                                    </div>
                                    <!-- <div class="col-4">
                                        <div class="single">
                                            <a>
                                                <div class="icon"><img src="/assets/frontend/materials/profile.png"
                                                        alt="">
                                                </div>
                                                <div class="name">Notifications</div>
                                            </a>
                                        </div>
                                    </div> -->
                                </div>
                            </div>
                            <div class="centered">
                                <button (click)="topToggleDisplay()" class="moreless-button site-btn-sm grad-btn">Load
                                    more</button>
                            </div>
                        </div>

                        <!-- all Statistic -->
                        <div class="all-feature-mobile mb-3 mobile-screen-show">
                            <div class="title">All Statistic</div>
                            <div class="row">
                                <div class="col-12">
                                    <div class="all-cards-mobile">
                                        <div class="contents row">
                                            <div class="col-12">
                                                <div class="single-card">
                                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            data-lucide="arrow-left-right" icon-name="arrow-left-right"
                                                            class="lucide lucide-arrow-left-right">
                                                            <path d="M8 3 4 7l4 4"></path>
                                                            <path d="M4 7h16"></path>
                                                            <path d="m16 21 4-4-4-4"></path>
                                                            <path d="M20 17H4"></path>
                                                        </svg></div>
                                                    <div class="content">
                                                        <div class="amount count">{{allStatistic?.totalTransactions}}
                                                        </div>
                                                        <div class="name">All Transactions</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="single-card">
                                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            data-lucide="download" icon-name="download"
                                                            class="lucide lucide-download">
                                                            <path d="M21 15v4a2 2 0 0 1-2 2H5a2 2 0 0 1-2-2v-4"></path>
                                                            <polyline points="7 10 12 15 17 10"></polyline>
                                                            <line x1="12" x2="12" y1="15" y2="3"></line>
                                                        </svg></div>
                                                    <div class="content">
                                                        <div class="amount">$<span class="count">
                                                                {{allStatistic?.totalDepositApproved}}</span>
                                                        </div>
                                                        <div class="name">Total Deposit</div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-12">
                                                <div class="single-card">
                                                    <div class="icon"><svg xmlns="http://www.w3.org/2000/svg" width="24"
                                                            height="24" viewBox="0 0 24 24" fill="none"
                                                            stroke="currentColor" stroke-width="2"
                                                            stroke-linecap="round" stroke-linejoin="round"
                                                            data-lucide="box" icon-name="box" class="lucide lucide-box">
                                                            <path
                                                                d="M21 16V8a2 2 0 0 0-1-1.73l-7-4a2 2 0 0 0-2 0l-7 4A2 2 0 0 0 3 8v8a2 2 0 0 0 1 1.73l7 4a2 2 0 0 0 2 0l7-4A2 2 0 0 0 21 16z">
                                                            </path>
                                                            <polyline points="3.29 7 12 12 20.71 7"></polyline>
                                                            <line x1="12" x2="12" y1="22" y2="12"></line>
                                                        </svg></div>
                                                    <div class="content">
                                                        <div class="amount"><span
                                                                class="count">{{allStatistic?.totalDepositCount}}</span>
                                                        </div>
                                                        <div class="name">Total Deposit count</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="moretext-2 " [ngStyle]="{ 'display': bootemIsBlock ? '' : 'flex' }">
                                            <div class="contents row">
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="credit-card" icon-name="credit-card"
                                                                class="lucide lucide-credit-card">
                                                                <rect width="20" height="14" x="2" y="5" rx="2"></rect>
                                                                <line x1="2" x2="22" y1="10" y2="10"></line>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount"> <span
                                                                    class="count">{{allStatistic?.totalWithdrawCount||0}}</span>
                                                            </div>
                                                            <div class="name">Total Withdraw count</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="log-in" icon-name="log-in"
                                                                class="lucide lucide-log-in">
                                                                <path d="M15 3h4a2 2 0 0 1 2 2v14a2 2 0 0 1-2 2h-4">
                                                                </path>
                                                                <polyline points="10 17 15 12 10 7"></polyline>
                                                                <line x1="15" x2="3" y1="12" y2="12"></line>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount">₹<span class="count">
                                                                    {{allStatistic?.Total_Withdraw ||0}}</span>
                                                            </div>
                                                            <div class="name">Total Transfer</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="send" icon-name="send"
                                                                class="lucide lucide-send">
                                                                <line x1="22" x2="11" y1="2" y2="13"></line>
                                                                <polygon points="22 2 15 22 11 13 2 9 22 2"></polygon>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount">₹<span class="count">
                                                                    {{allStatistic?.WithdrawalApproved||0}}</span>
                                                            </div>
                                                            <div class="name">Total Withdraw</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="users-2" icon-name="users-2"
                                                                class="lucide lucide-users-2">
                                                                <path d="M14 19a6 6 0 0 0-12 0"></path>
                                                                <circle cx="8" cy="9" r="4"></circle>
                                                                <path d="M22 19a6 6 0 0 0-6-6 4 4 0 1 0 0-8"></path>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount"> <span class="count">
                                                                    {{allStatistic?.Referral_Bonus}}</span>
                                                            </div>
                                                            <div class="name">Referral Bonus</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="anchor" icon-name="anchor"
                                                                class="lucide lucide-anchor">
                                                                <circle cx="12" cy="5" r="3"></circle>
                                                                <line x1="12" x2="12" y1="22" y2="8"></line>
                                                                <path d="M5 12H2a10 10 0 0 0 20 0h-3"></path>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount"><span
                                                                    class="count">{{allStatistic?.totalSelfWithdrawalTransactionPoint||0}}</span>
                                                            </div>
                                                            <div class="name">Deposit Bonus</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="archive" icon-name="archive"
                                                                class="lucide lucide-archive">
                                                                <rect width="20" height="5" x="2" y="4" rx="2"></rect>
                                                                <path d="M4 9v9a2 2 0 0 0 2 2h12a2 2 0 0 0 2-2V9">
                                                                </path>
                                                                <path d="M10 13h4"></path>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount"><span
                                                                    class="count">{{allStatistic?.investmentBonus}}</span>
                                                            </div>
                                                            <div class="name"> Investment Bonus</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="gift" icon-name="gift"
                                                                class="lucide lucide-gift">
                                                                <polyline points="20 12 20 22 4 22 4 12"></polyline>
                                                                <rect width="20" height="5" x="2" y="7"></rect>
                                                                <line x1="12" x2="12" y1="22" y2="7"></line>
                                                                <path d="M12 7H7.5a2.5 2.5 0 0 1 0-5C11 2 12 7 12 7z">
                                                                </path>
                                                                <path d="M12 7h4.5a2.5 2.5 0 0 0 0-5C13 2 12 7 12 7z">
                                                                </path>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount count">
                                                                {{allStatistic?.totelInviteCount||0}}</div>
                                                            <div class="name"> Total Referral</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="award" icon-name="award"
                                                                class="lucide lucide-award">
                                                                <circle cx="12" cy="8" r="6"></circle>
                                                                <path d="M15.477 12.89 17 22l-5-3-5 3 1.523-9.11">
                                                                </path>
                                                            </svg></div>
                                                        <div class="content">
                                                            <div class="amount count">1</div>
                                                            <div class="name">Rank Achieved</div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div class="col-12">
                                                    <div class="single-card">
                                                        <div class="icon"><svg xmlns="http://www.w3.org/2000/svg"
                                                                width="24" height="24" viewBox="0 0 24 24" fill="none"
                                                                stroke="currentColor" stroke-width="2"
                                                                stroke-linecap="round" stroke-linejoin="round"
                                                                data-lucide="alert-triangle" icon-name="alert-triangle"
                                                                class="lucide lucide-alert-triangle">
                                                                <path
                                                                    d="m21.73 18-8-14a2 2 0 0 0-3.48 0l-8 14A2 2 0 0 0 4 21h16a2 2 0 0 0 1.73-3Z">
                                                                </path>
                                                                <path d="M12 9v4"></path>
                                                                <path d="M12 17h.01"></path>
                                                            </svg>
                                                        </div>
                                                        <div class="content">
                                                            <div class="amount count">{{allStatistic?.totalTicket}}
                                                            </div>
                                                            <div class="name"> Total Ticket</div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div class="centered">
                                            <button (click)="bootemToggleDisplay()"
                                                class="moreless-button-2 site-btn-sm grad-btn">Load more</button>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <!-- Recent Transactions -->
                        <div class="all-feature-mobile mobile-transactions mb-3 mobile-screen-show" *ngIf="RecentTransactions?.length">
                            <div class="title">Recent Transactions</div>
                            <div class="contents">

                                <div>
                                    <div class="single-transaction" *ngFor="let transaction of RecentTransactions">
                                        <div class="transaction-left">
                                            <div class="transaction-des">
                                                <div class="transaction-title">{{transaction?.type}} With
                                                    {{transaction?.withdrawalType||transaction?.paymentType||'USDT'}}
                                                </div>
                                                <div class="transaction-id">
                                                    {{transaction?.WithdrawalId||transaction?.orderId}}</div>
                                                <div class="transaction-date">{{ transaction?.createdAt | date: 'MMM dd yyyy HH:mm' }}</div>
                                            </div>
                                        </div>
                                        <div class="transaction-right">
                                            <div class="transaction-amount ">
                                                {{ transaction?.actually_paid || transaction?.totalAmount }}</div>
                                            <div class="transaction-fee sub">{{transaction?.convenienceFee||0}} % Fee
                                            </div>
                                            <div class="transaction-gateway">{{transaction?.withdrawalType}}</div>

                                            <div class="transaction-status" [ngClass]="{'pending': transaction?.status === 'Pending', 'success': transaction?.status === 'Success' }">
                                                {{ transaction?.status }}
                                            </div>

                                        </div>
                                    </div>

                                </div>

                                <!-- <div class="single-transaction">
                                    <div class="transaction-left">
                                        <div class="transaction-des">
                                            <div class="transaction-title">Withdraw With Rahul kumar-USDT
                                            </div>
                                            <div class="transaction-id">TRXT9MLJQYTRF</div>
                                            <div class="transaction-date">Feb 06 2025 12:25</div>
                                        </div>
                                    </div>
                                    <div class="transaction-right">
                                        <div class="transaction-amount ">
                                            -56 USDT</div>
                                        <div class="transaction-fee sub">-0 USDT Fee </div>
                                        <div class="transaction-gateway">Rahul Kumar</div>


                                        <div class="transaction-status success">Success</div>
                                    </div>
                                </div> -->

                            </div>
                        </div>

                        <div class="col-12">
                            <div class="mobile-ref-url mb-4">
                                <div class="all-feature-mobile">
                                    <div class="title">Referral URL</div>
                                    <div class="mobile-referral-link-form">
                                        <input type="text" 
                                        [value]="satting?.appLink + '?invite=' + userdetails?.user?.code" 
                                        disabled 
                                        id="refLink">
                                 
                                 
                                        <button type="submit" (click)="shareOnWhatsApp()">
                                            <span id="copy">invite</span>
                                        </button>
                                    </div>
                                    <p class="referral-joined">{{getPintData?.totelInviteCount||0}} peoples are joined
                                        by using this URL</p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>

                <!--Page Content-->
            </div>
        </div>
    </div>


    <div id="auto-popup" class="auto-popup-section" *ngIf="userdetails?.user?.isFirstLogin">
        <div class="auto-popup-dialog animated fadeInUp">
            <button class="auto-popup-close auto-popup-close-now"
                (click)="userUpdateInfo('isFirstLogin',!userdetails?.user?.isFirstLogin)"><svg
                    xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24" fill="none"
                    stroke="currentColor" stroke-width="2" stroke-linecap="round" stroke-linejoin="round"
                    data-lucide="x" icon-name="x" class="lucide lucide-x">
                    <path d="M18 6 6 18"></path>
                    <path d="m6 6 12 12"></path>
                </svg></button>
            <div class="auto-popup-dialog-inner"
                style="background: url(assets/frontend/images/auto-pop.jpg) no-repeat;">
                <div class="row">
                    <div class="col-md-12 col-12">
                        <div class="auto-pop-content">
                            <h2>Congratulation!</h2>
                            <h3>You got a Signup Bonus
                                <span>8 USD</span>
                            </h3>
                            <button class="site-btn grad-btn auto-popup-close-now"
                                (click)="userUpdateInfo('isFirstLogin',!userdetails?.user?.isFirstLogin)"><i
                                    class="anticon anticon-check"></i> Got it</button>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>

    <!-- popup message -->


    <div class="app-container" [ngClass]="{'dark-theme':darkMode}" *ngIf="popupMessge">
        <div class="popup-overlay">
            <div style="width: 90%;">
                <div class="col-xl-12">
                    <div class="site-card">
                        <div class="site-card-header">
                            <h3 class="title">{{userdetails?.user?.popupMessage?.alertTitel||'Notification'}}</h3>
                            <div class="card-header-links">
                                <a class="card-header-link" (click)="userUpdateInfo()">X</a>
                            </div>
                        </div>
                        <div class="site-card-body">

                            <div class="progress-steps-form">
                                <div class="transaction-status" style="padding: 13px 18px; ">
                                    <div style="margin-bottom: 10px;" *ngIf="userdetails?.user?.popupMessage?.mediaUrl">
                                        <img [src]="userdetails?.user?.popupMessage?.mediaUrl" alt="" srcset=""
                                            style="border-radius: 10px;">
                                    </div>
                                    <h2 *ngIf="userdetails?.user?.popupMessage?.titel">
                                        {{userdetails?.user?.popupMessage?.titel}}</h2>
                                    <!-- <p>Your transaction has been successfully processed and the amount has been sent.</p> -->
                                    <p *ngIf="userdetails?.user?.popupMessage?.message">
                                        {{userdetails?.user?.popupMessage?.message}} </p>
                                    <!-- <p>Transaction ID:</p>
                      <a  class="site-btn">
                        <i class="anticon anticon-plus"></i>Send again
                      </a> -->
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>



    <app-bottom-appbar></app-bottom-appbar>