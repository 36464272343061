<div class="signup-main">
    <div class="main">
        <div class="back-c">
            <p class="arrow"(click)="onLogin()"><i class="fa-solid fa-arrow-left"></i></p>
        </div>
        <div class="signup" *ngIf="!signuppagedata">
            <form>
                <label for="fss" aria-hidden="true">Forgot Password</label>
                <input id="fss" type="number" name="numb" placeholder="Enter Registration Number" (input)="userEnterPhoneNUmber($event , 'num')">
                <label for="chk" aria-hidden="true">Password</label>
                <input id="chk" type="password" name="password" placeholder="Enter New password" (input)="userEnterPhoneNUmber($event , 'pass')">
            </form>
            <p class="otp" (click)="SendOtpToNUmber()">Send OTP</p>
        </div>
        <div *ngIf="signuppagedata">
            <label>Write Invite Code</label>
            <input type="text" placeholder="Write Invite Code" (input)="userInviteCode($event)">
        </div>
        <div class="otp-content">
            <div class="otp-section">
                <div class="otp-container mt-4">
                    <input type="number" maxlength="1" class="otp-input" (input)="handleInput($event, 0)" 
                        (keydown)="handleBackspace($event, 0)" />
                    <input type="number" maxlength="1" class="otp-input" (input)="handleInput($event, 1)"
                        (keydown)="handleBackspace($event, 1)" />
                    <input type="number" maxlength="1" class="otp-input" (input)="handleInput($event, 2)"
                        (keydown)="handleBackspace($event, 2)" />
                    <input type="number" maxlength="1" class="otp-input" (input)="handleInput($event, 3)"
                        (keydown)="handleBackspace($event, 3)" />
                </div>
            </div>
        </div>
        <div class="otp-time-content">
            <p class="otp-time">Time remaining: {{ timeLeft }} seconds</p>
            <button class="resend-otp" *ngIf="timeLeft === 0" (click)="resendOtp()">Resend OTP</button>
            <button class="resend-otp" *ngIf="timeLeft > 0" disabled>Resend OTP</button>
        </div>
    </div>
</div>