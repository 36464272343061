<!-- <ul class="fw-bold">
          <li>Amount :</li>
          <li>Account Name :</li>
          <li>Bank Name :</li>
          <li>Account Number :</li>
          <li>IfCS Code :</li>
          <li>WithDrawal ID :</li>
          <li>Phone No. :</li>
          <li>Withdrawal Date :</li>
          <li>Transaction Type :</li>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <li>{{userDetails?.data?.amount}}</li>
          <li>{{userDetails?.data?.accountHolderName}}</li>
          <li>{{userDetails?.data?.bankName}}</li>
          <li>{{userDetails?.data?.accountNumber}}</li>
          <li>{{userDetails?.data?.ifscCode}}</li>
          <li>{{userDetails?.data?.WithdrawalId}}</li>
          <li>{{userDetails?.data?.mobileNumber}}</li>
          <li>{{userDetails?.data?.withdrawaDate}}</li>
          <li>{{userDetails?.data?.type}}</li> -->
<!-- </ul> -->
<!--   

      <div class="col-6">
        <ul class="fw-bold">
          <li>Amount :</li>
          <li>USTD Amount($):</li>
          <li>USTD Price :</li>
          <li>Order Id :</li>
          <li>Date:</li>
          <li>Hash ID :</li>
        </ul>
      </div>
      <div class="col-6">
        <ul>
          <li>{{userDetails?.data?.amount}}</li>
          <li>{{userDetails?.data?.usdtAmount}}</li>
          <li>{{userDetails?.data?.usdtPrice}}</li>
          <li>{{userDetails?.data?.orderId}}</li>
          <li>{{userDetails?.data?.rechargeDate}}</li>
          <li>{{userDetails?.data?.HashId}}</li>
        </ul> -->


<div class="receipt-bodyall">
  <div class="receipt-container" *ngIf="userDetails?.data?.type === 'Deposit'">
    <div class="receipt-header">
      <div class="status-icon outer-circle" [ngClass]="{
                  'pending': userDetails?.data?.status === 'Pending',
                  'rejected': userDetails?.data?.status === 'Rejected',
                  'approved': userDetails?.data?.status === 'Approved'
                }">
        <div class="inner-circle" [ngClass]="{
                    'pending': userDetails?.data?.status === 'Pending',
                    'rejected': userDetails?.data?.status === 'Rejected',
                    'approved': userDetails?.data?.status === 'Approved'
                  }">
          <!-- Dynamically display icons based on status -->
          <ng-container [ngSwitch]="userDetails?.data?.status">
            <span *ngSwitchCase="'Pending'" class="pending-icon">⏳</span>
            <span *ngSwitchCase="'Rejected'" class="rejected-icon">❌</span>
            <span *ngSwitchCase="'Approved'" class="approved-icon">✔️</span>
          </ng-container>
        </div>
      </div>
      <h2>Payment {{ userDetails?.data?.status }}</h2>
      <div class="separator-first"></div>
    </div>
    <div class="receipt-body">
      <div class="receipt-row no-line" (click)="copyReferenceID(userDetails?.data?.orderId)">
        <span>Reference ID</span>
        <span  >{{ userDetails?.data?.orderId }}</span>
      </div>
      <div class="receipt-row no-line">
        <span>Date & Time</span>
        <span>{{ userDetails?.data?.rechargeDate }}</span>
      </div>
      <div class="receipt-row no-line">
        <span>Payment Method</span>
        <span>{{ userDetails?.data?.type }}</span>
      </div>
      <!-- Conditionally display HashId -->
      <div class="receipt-row no-line" >
        <span>HashId</span>
        <span>{{ formatHashId(userDetails?.data?.HashId) }}</span>
      </div>
      <div *ngIf="userDetails?.data?.remark?.trim()">
        <div class="separator"></div>
        <div class="receipt-row no-line">
          <span>Remark</span>
          <span>{{ formatHashId(userDetails?.data?.remark) }}</span>
        </div>
      </div>
      <div class="separator"></div>
      <div class="receipt-row no-line">
        <span>Amount</span>
        <span>{{ userDetails?.data?.amount | currency:'INR' }}</span>
      </div>
      <div class="separator"></div>
      <div class="receipt-row total">
        <span>Total</span>
        <span>{{ userDetails?.data?.usdtAmount | currency:'USD' }}</span>
      </div>
    </div>
    <div class="separator-bottom">
      <!-- Half-Semicircles -->
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
    </div>
  </div>
</div>















<div class="receipt-bodyall">
  <div class="receipt-container" *ngIf="userDetails?.data?.type === 'Withdrawal'">
    <div class="receipt-header">
      <div class="status-icon outer-circle" [ngClass]="{
                  'pending': userDetails?.data?.status === 'Pending',
                  'rejected': userDetails?.data?.status === 'Rejected',
                  'approved': userDetails?.data?.status === 'Approved'
                }">
        <div class="inner-circle" [ngClass]="{
                    'pending': userDetails?.data?.status === 'Pending',
                    'rejected': userDetails?.data?.status === 'Rejected',
                    'approved': userDetails?.data?.status === 'Approved'
                  }">
          <!-- Dynamically display icons based on status -->
          <ng-container [ngSwitch]="userDetails?.data?.status">
            <span *ngSwitchCase="'Pending'" class="pending-icon">⏳</span>
            <span *ngSwitchCase="'Rejected'" class="rejected-icon">❌</span>
            <span *ngSwitchCase="'Approved'" class="approved-icon">✔️</span>
            <span *ngSwitchCase="'Refund'" class="Refund-icon">♻️</span>

          </ng-container>
        </div>
      </div>
      <h2>Payment {{ userDetails?.data?.status }}</h2>
      <div class="separator-first"></div>
    </div>
    <div class="receipt-body">
      <div class="receipt-row no-line" (click)="copyReferenceID(userDetails?.data?.WithdrawalId)">
        <span>Reference ID :</span>
        <span>{{ userDetails?.data?.WithdrawalId }}</span>
      </div>
      <div class="receipt-row no-line">
        <span>Date & Time :</span>
        <span>{{ userDetails?.data?.withdrawaDate}}</span>
      </div>
      <div class="receipt-row no-line">
        <span>Payment Method :</span>
        <span>{{ "IMPS" }}</span>
      </div>
      <!-- Conditionally display HashId -->
      <div class="receipt-row no-line" >
        <span>Account:</span>
        <span>{{ userDetails?.data?.accountNumber}}</span>
      </div> 
       <div class="receipt-row no-line" >
        <span>Account name :</span>
        <span>{{formatHashId(userDetails?.data?.accountHolderName)}}</span>
      </div>
      
      <div class="receipt-row no-line" >
        <span>IFSC:</span>
        <span>{{ userDetails?.data?.ifscCode}}</span>
      </div>
     

      <div class="separator"></div>
      <div class="receipt-row total">
        <span>Amount</span>
        <span>{{ userDetails?.data?.amount | currency:'INR' }}</span>
      </div>
      <div class="separator"></div>
      <div class="receipt-row no-line"*ngIf="userDetails?.data?.remark?.trim()">
        <span>Remark</span>
        <span>{{ formatHashId(userDetails?.data?.remark) }}</span>
      </div>
    
    </div>
    <div class="separator-bottom">
      <!-- Half-Semicircles -->
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
      <div class="half-circle"></div>
    </div>
  </div>
</div>
