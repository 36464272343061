import { HttpClient, HttpErrorResponse } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-withdrawal',
  templateUrl: './withdrawal.component.html',
  styleUrls: ['./withdrawal.component.css']
})
export class WithdrawalComponent {

  userdeatails: any
  amountToAdd: number = NaN
  selectedBank: any = null;
  userBackDetails: any
  amount = 10
  openSelectBankModel = false;
  hideSelectBankOption = true;
  showSelectedBankDetiails = false
  showParticularBankDetails: any
  openModel = false;
  passCodeIsGenerated = false
  openWritePassCodeModel = false
  conformPassWord: any
  conformUserPassCodes: any
  userGeneratePassCode: any = {}
  hideButtonBankName: boolean = false
  private darkModeSubscription!: Subscription;
  darkMode: boolean = false;
  uploadQRPopupVisible: boolean = false;
  getQrCoderPopup: boolean = false;
  payType: any = 'Bank-account'
  conferencePopup: Boolean = false
  addBankData: any = {}
  disabelAddbBankInput: Boolean = false
  saveBankAccount: Boolean = false
  banckAccouMessagentSave: Boolean = false
  isoffaccountSaveMessage: Boolean = false
  AddPasCodepopup: Boolean = false
  attemptPassCode = 0
  passCodeForgotButton: Boolean = false
  changPasssCodeTitel: any
  WithdrawRequestSuccessful: Boolean = false
  resWithdrawalId: any
  // add qr code  data
  aadQrCodeData: any = {}
  selectedFiletype: any
  file: any
  qrCodeList: any
  disabledUpiInput: boolean = true
  sattingData: any
  searchQuery: string = ''; // Store search text input
  startDate: string = ''; // Store date input
  endDate: string = ''; // Store date input

  constructor(private http: HttpClient, private route: Router,
    private toastr: ToastrService,
    private host: ApkDataService,
    private darkModeService: DarkModeService

  ) {

  }
  ngOnInit() {
    this.CallUserINfo()
    this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
      (enabled) => (this.darkMode = enabled)
    );
    this.getSatting()
  }
  CallUserINfo() {
    this.http.get(`${this.host.localHost}${this.host.user_UserInfo}`).subscribe(res => {
      this.userdeatails = res;
    }, err => {
      this.toastr.error(err.error.message, 'Error');

    })
  }
  UserAddedBank(query?: any) {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_getBankDetails}`,{ params: query }).subscribe((res) => {
      this.userBackDetails = res.bankDetails || []

    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');

      });
  }
  withdrawalAmount(bankId: any, amount: any, passCode: any) {
    if (!bankId && !amount) {
      this.toastr.error('please fill the field', 'Error');
      return
    }
    this.http.post(`${this.host.localHost}${this.host.Withdrawal_addWithdrawal}`, { bankId, amount, passCode }).subscribe((res) => {
      this.route.navigateByUrl('withdawal-history')
    }, err => {
      this.toastr.error(err.error.message, 'Error');

    })
  }

  GetParticularBank(bankId: any) {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_getBankDetails}`, { params: { bankId } }).subscribe((res) => {
      this.showParticularBankDetails = res.bankDetails || []

    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');

      });
  }

  selectBank(bank: any) {
    this.selectedBank = bank;
    this.hideSelectBankOption = true;
    this.GetParticularBank(this.selectedBank)
    this.showSelectedBankDetiails = true;
    this.hideButtonBankName = true
  }
  makePayment() {
    if (this.selectedBank) {
      this.withdrawalAmount(this.selectedBank, this.amountToAdd, this.conformUserPassCodes)
    }
  }
  GoToAddBankPage() {
    this.route.navigateByUrl('AddBankDetailsComponent')
  }
  delete(bankId: any) {
    this.http.patch<InterfaceForApi>(`${this.host.localHost}${this.host.user_Deletebank}`, { bankId }).subscribe(res => {
      if (res) {
        this.UserAddedBank()
      }
    }, err => {
      this.toastr.error(err.error.message, 'Error');

    })
  }
  ChangeData(event: any) {
    const inputElement = event.target as HTMLInputElement;
    let maxBalance =0

    if (this.payType == 'Bank-account') {
      maxBalance = this.userdeatails?.walletBalance - (this.userdeatails?.walletBalance * (this.sattingData?.bankWithdrawalconvenienceFee || 0)) / 100;
    }else if (this.payType == 'E-pay') {
      maxBalance = this.userdeatails?.walletBalance - (this.userdeatails?.walletBalance * (this.sattingData?.ePayWithdrawalconvenienceFee || 0)) / 100;
    }

    const inputValue = parseInt(inputElement.value, 10);

    if (inputValue > maxBalance) {
      inputElement.value = maxBalance.toString();
      this.amountToAdd = maxBalance;
    } else {
      this.amountToAdd = inputValue;
    }
  }
  UserSelectBank() {
    this.hideSelectBankOption = false;
  }
  openPassCodeModel() {
    if (this.passCodeIsGenerated) {
      this.openWritePassCodeModel = true;
      return;
    }
    this.openModel = true;
  }

  closePassCodeModel() {
    this.openModel = false;
  }












  ConformPassWrdWrite(event: any) {
    this.conformPassWord = event.target.value;
  }


  conformUserPassCode(event: any) {
    this.conformUserPassCodes = event.target.value;
  }
  uploadShowPopup(): void {
    this.uploadQRPopupVisible = !this.uploadQRPopupVisible
    if (!this.uploadQRPopupVisible) {
      this.pagesRefresh()
      
    }
  }

  getQrCoderPopupModel() {
    if (this.payType == 'Bank-account') {
      this.UserAddedBank()
    }
    if (this.payType == 'E-pay') {
      this.getQrCodeList()
    }
    this.searchQuery = ''; 
    this.startDate= ''
    this.endDate = ''
    this.getQrCoderPopup = !this.getQrCoderPopup
  }
  toggleHighlight(payType: any) {
    this.payType = payType;
  }

  conferenceMOdel() {
    this.userHavePassCode()
    if (this.payType == 'Bank-account') {
      if (!this.saveBankAccount && !this.isoffaccountSaveMessage) {
        if (
          this.addBankData.accountHolderName &&
          this.addBankData.accountNumber &&
          this.addBankData.ifscCode
        ) {
          this.http.post(`${this.host?.localHost}${this.host?.user_addBank}`, { ...this.addBankData }).subscribe((res) => {
            if (res) {
              this.addBankData = res
              this.banckAccouMessagentSave = true
              this.disabelAddbBankInput = true
            }
          }, err => {
            this.toastr.error(err.error.message, 'Error');
          })
        } else {
          this.toastr.error('One or more fields are empty.', 'Error');
        }
      } else if (this.addBankData.accountHolderName &&
        this.addBankData.accountNumber &&
        this.addBankData.ifscCode &&
        this.amountToAdd) {
        this.conferencePopup = !this.conferencePopup
      } else {
        this.toastr.error('One or more fields are empty.', 'Error');
      }
    }
    if (this.payType == 'E-pay') {
      if (!this.AddPasCodepopup && this.aadQrCodeData.upiIdName && this.aadQrCodeData.qrCodeImages && this.amountToAdd) {


        this.conferencePopup = true
      } else {
        this.toastr.error('One or more fields are empty.', 'Error');
      }
    }

  }

  addBankAccount(event: any, addBankData: any) {
    this.addBankData[addBankData] = event.target.value
  }
  setbankDetails(bankDetails: any) {
    this.addBankData = bankDetails
    this.disabelAddbBankInput = true
    this.getQrCoderPopup = false
    this.saveBankAccount = true

  }
  withdrawalAmountBoth() {
    if (this.payType == 'Bank-account') {
      if (
        this.addBankData.accountHolderName &&
        this.addBankData.accountNumber &&
        this.addBankData.ifscCode &&
        this.amountToAdd && this.addBankData.passCode
      ) {
           this.addBankData['withdrawalType']='bankAccount'
        let body = this.addBankData
        this.addBankData['amount'] = this.amountToAdd
        this.withdrawalByBankAccount(body)
      } else {
        this.toastr.error('One or more fields are empty.', 'Error');
        console.log('One or more fields are empty.');
      }
    }
    if (this.payType == 'E-pay') {   
      this.aadQrCodeData['amount'] = this.amountToAdd
      
      if (this.aadQrCodeData.passCode && this.aadQrCodeData.qrCodeImages && this.aadQrCodeData.upiIdName &&  this.amountToAdd&&this.aadQrCodeData.qrCodeId) {
        this.aadQrCodeData['withdrawalType']='e-pay'
        this.withdrawalByQrCode(this.aadQrCodeData)
      } else {
        console.log("error");
        
        this.toastr.error('One or more fields are empty.', 'Error');

      }
    }
  }
  withdrawalByBankAccount(body: any) {
    this.http.post(`${this.host.localHost}${this.host.Withdrawal_addWithdrawal}`, body).subscribe((res) => {
      if (res) {
        this.conferencePopup = false
        this.addBankData = {}
        this.disabelAddbBankInput = false
        this.saveBankAccount = false
        this.isoffaccountSaveMessage = false
        this.passCodeForgotButton = false
        this.WithdrawRequestSuccessful = true
        this.resWithdrawalId = res
      }
    }, err => {

      this.toastr.error(err.error.message, 'Error');
      this.resInvalidPassCode()
    })
  }
  resInvalidPassCode() {
    this.attemptPassCode++

    if (this.attemptPassCode >= 3) {
      this.passCodeForgotButton = true

    }
    if (this.attemptPassCode >= 8) {
      this.refreshPage('app-home')
      // this.http.post(`${this.host?.localHost}${this.host?.user_passCodeRemove}`, null).subscribe((res) => {
      //   if (res) {
      //   }
      // }, err => {
      //   this.toastr.error(err.error.message, 'Error');
      // })
    }
  }
  accountSaveMessageOff() {
    this.banckAccouMessagentSave = false
    this.isoffaccountSaveMessage = true
  }
  userHavePassCode() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.user_isPassCode}`).subscribe((res) => {
      if (res) {
        this.AddPasCodepopup = !res?.passCodeIsVerified
        console.log(this.AddPasCodepopup);
        
      }
    },
      (err) => {
        this.toastr.success('Pass Code has been set successfully.', 'Success')
      });
  }
  closeWritePassCodeModel() {
    this.AddPasCodepopup = !this.AddPasCodepopup
  }
  changeThePasssCode(event: any, data: string) {
    this.userGeneratePassCode[data] = event.target.value
  }
  updatePassCodeSubmitData() {
    if (this.userGeneratePassCode.passCode !== this.userGeneratePassCode.ConfirmPassCde) {
      // this.userGeneratePassCode.passCode = '';
      // this.userGeneratePassCode.ConfirmPassCde = '';
      this.toastr.error('Pass Code and Confirm Pass Code should not be same.', 'Error');
      return
    }else{
      this.generatePassCode(this.userGeneratePassCode)
    }
  }
  generatePassCode(body: any) {
    this.http.post<InterfaceForApi>(`${this.host.localHost}${this.host.user_addPassCode}`, { ...body }).subscribe((res) => {
      if (res) {
        this.AddPasCodepopup = false;
        this.conferenceMOdel()
        this.toastr.success(' Pass Code set sessefully', 'success');
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');
      });
  }
  openPassCodePopup() {
    this.AddPasCodepopup = true
    this.changPasssCodeTitel = 'Change Pass Code'
    this.conferencePopup = false
    this.GenerateOtpSendNumber(this.userdeatails?.mobileNumber)
  }

  refreshPage(url: any) {
    this.route.navigateByUrl(url, { skipLocationChange: true }).then(() => {
      this.route.navigate([this.route.url]);
    });
  }
closeConferenceMOdel(){
  this.conferencePopup = false
}

  // qurcode account
  addQrCodeAccount(event: any, QrCodeKey: any) {
    this.aadQrCodeData[QrCodeKey] = event.target.value
  }


  onFileSelect(event: any): void {
    const input = event.target as HTMLInputElement;
    const selectedFile = input.files?.[0];
    if (selectedFile) {
      const allowedTypes = ['image/jpeg', 'image/png']

      if (allowedTypes.includes(selectedFile.type)) {
        this.selectedFiletype = selectedFile.type
        if (selectedFile.size <= 25 * 1024 * 1024) { // Limit size to 5MB
          this.file = selectedFile;
          this.uploadFile(); // Call the upload method
        } else {
          this.toastr.error('File size exceeds 25 MB. Please select a smaller file.', 'Error');
          input.value = ''; // Reset input value
        }
      } else {
        this.toastr.error('Invalid file type. Only images (jpeg, png, gif) and PDFs are allowed.', 'Error');
        input.value = ''; // Reset input value
      }
    }
  }
  uploadFile(): void {
    if (this.file) {
      const formData = new FormData();
      formData.append('file', this.file);
      formData.append('uploadUse', 'WithdrawalQrCode');
      this.http.post(`${this.host.localHost}${this.host.document_update}`, formData)
        .subscribe({
          next: (response: any) => {
            this.aadQrCodeData.qrCodeImages = response.URL;
            this.aadQrCodeData.imagesKey = response.key
            this.toastr.success('File uploaded successfully!', 'success');
          },
          error: (error: HttpErrorResponse) => {
            this.toastr.error('Error uploading file. Please try again', 'Error');
          }
        });
    } else {
      this.toastr.error('No file selected.', 'Error');
    }
  }

  saveQrCodeData() {
    if (this.payType == 'E-pay') {
      if (this.aadQrCodeData.upiIdName &&  this.aadQrCodeData.qrCodeImages) {
        this.http.post(`${this.host.localHost}${this.host.user_addQrCodeAccount}`, this.aadQrCodeData).subscribe({
          next: (response: any) => {
            this.aadQrCodeData = response
            this.toastr.success('QrCode  saved successfully!', 'success');
            this.uploadQRPopupVisible = false

          },
          error: (error: HttpErrorResponse) => {
            this.toastr.error('Error saving QrCode Data. Please try again', 'Error');
          }
        });
      } else {
        this.toastr.error('Please fill all the fields', 'Error');
      }
    }
  }

  getQrCodeList(query?: any) {
    this.http.get(`${this.host.localHost}${this.host.user_getQrCodeList}`,{params:query}).
      subscribe({
        next: (response: any) => {
          this.qrCodeList = response
        },
        error: (error: HttpErrorResponse) => {
          this.toastr.error('Error fetching QrCode list. Please try again', 'Error');
        }
      });
  }

  withdrawalByQrCode(payload: any) {
    this.http.post(`${this.host.localHost}${this.host.Withdrawal_addWithdrawal}`, payload).subscribe((res) => {
      if (res) {
        this.conferencePopup = false
        this.addBankData = {}
        this.disabelAddbBankInput = false
        this.saveBankAccount = false
        this.isoffaccountSaveMessage = false
        this.passCodeForgotButton = false
        this.WithdrawRequestSuccessful = true
        this.resWithdrawalId = res
      }
    }, err => {

      this.toastr.error(err.error.message, 'Error');
      this.resInvalidPassCode()
    })
  }
  getAndsetQrCodeData(setQrCodeListData: any) {
    this.aadQrCodeData = setQrCodeListData
    this.getQrCoderPopup = false

  }

  navigateByUrl(url: any) {
    this.route.navigateByUrl(url)
  }
  pagesRefresh() {
    window.location.reload();
  }

  getSatting() {
    this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getSatting}`).subscribe(res => {
      this.sattingData = res
    }, err => {
      this.toastr.error(err.error.message, 'Error');
    })
  }
  onSubmit(event: Event) {
    // event.preventDefault(); // Prevent the default form submission
    // Prepare the data to send to the API
    const searchFilter = {
      search: this.searchQuery.endsWith(' ') ? this.searchQuery.trim() : this.searchQuery,
      startDate: this.startDate.endsWith(' ') ? this.startDate.trim() : this.startDate,
      endDate: this.endDate.endsWith(' ') ? this.endDate.trim() : this.endDate
    }; 
    if (searchFilter.startDate && searchFilter.endDate) {
      if (new Date(searchFilter.startDate) > new Date(searchFilter.endDate)) {
        this.toastr.error('Start date cannot be greater than end date.', 'Error');
        return;
      }
      searchFilter.search='';
    }
    // Call the API
    if (this.payType == 'Bank-account') {
      this.UserAddedBank(searchFilter)
    }else if(this.payType == 'E-pay'){
      this.getQrCodeList(searchFilter)
    }else{
      this.toastr.error('error', 'Error');
    }
  }
  isButtonDisabled(): boolean {

    if (this.payType == 'Bank-account') {
      return !this.amountToAdd||this.amountToAdd < this.sattingData?.bankWithdrawalMinimumDepositAmount ||
      this.amountToAdd > this.sattingData?.bankWithdrawalMaximumDepositAmount;
    }
    if (this.payType == 'E-pay') { 
      return !this.amountToAdd||this.amountToAdd < this.sattingData?.ePayWithdrawalMinimumDepositAmount ||
      this.amountToAdd > this.sattingData?.ePayWithdrawalMaximumDepositAmount;
    }
    return false; 
  }

  async GenerateOtpSendNumber(phoneNo?: any) {
    await this.http.post(`${this.host.localHost}${this.host.user_generateOTP}`, {
      mobileNumber: phoneNo,
    }).subscribe((res) => {
      if (res) {
    
        // this.onForgot(data);
      }
    },
      (err) => {
        this.toastr.error(err.error.message, 'Error');

      }
    );
  }
}
