import { HttpClient } from '@angular/common/http';
import { Component } from '@angular/core';
import { Router } from '@angular/router';
import { InterfaceForApi } from '../interface-for-api';
import { ApkDataService } from 'src/app/apk-data.service';
import { ToastrService } from 'ngx-toastr';
import { DarkModeService } from '../dark-mode/dark-mode.service';
import { Subscription } from 'rxjs';
@Component({
  selector: 'app-statement',
  templateUrl: './statement.component.html',
  styleUrls: ['./statement.component.css']
})
export class StatementComponent {
   userdata:any
   userpage:any = ''
   paramssaas = {
    WithdrawalId :'',
    type : '',
    orderId:''
   }
   pagination = {
    itemsPerPage : 10,
    pageNo : 1
   }

   searchQuery: string = ''; // Store search text input
   startDate: string = ''; // Store date input
   endDate: string = ''; // Store date input
  private darkModeSubscription!: Subscription;
   darkMode: boolean = false;
   
     constructor( private route:Router , private http:HttpClient,
       private host :ApkDataService,    private toastr: ToastrService,private darkModeService: DarkModeService
       
     ){}
     ngOnInit(){
       this.userData()
       this.darkModeSubscription = this.darkModeService.darkMode$.subscribe(
        (enabled) => (this.darkMode = enabled)
      );
     }
     userData(query?:any){
      //  if(this.userpage !== data){
      //    this.pagination.pageNo = 1;
      //   }
      //   this.userpage = data
       this.http.get<InterfaceForApi>(`${this.host.localHost}${this.host.home_getStament}`,{params:query}).subscribe(res=>{
            if(res){
              this.userdata = res?.data;
            }      
       },err=>{
        this.toastr.error(err.error.message, 'Error');

       })
     }
     PreviousData(move:string,status?:any){
      if(move === 'Previous'){
           this.pagination.pageNo -= 1;
      }else{
        this.pagination.pageNo += 1;
      }
      this.userData(status)
     }
    
     ShowModelData(transaction:any){
      if(transaction.WithdrawalId){
        this.paramssaas.WithdrawalId = transaction?.WithdrawalId;
        this.paramssaas.type = transaction?.type;
      }else{
        this.paramssaas.orderId = transaction?.orderId;
        this.paramssaas.type = transaction?.type;
      }
       this.route.navigate(['shoe-model-payment'],{queryParams:{data: JSON.stringify(this.paramssaas)}})
     }

     onSubmit(event: Event) {
      // event.preventDefault(); // Prevent the default form submission
      // Prepare the data to send to the API
      const formData = {
        search: this.searchQuery,
        startDate: this.startDate,
        endDate: this.endDate
      }; 
      if (formData.startDate && formData.endDate) {
        if (new Date(formData.startDate) > new Date(formData.endDate)) {
          this.toastr.error('Start date cannot be greater than end date.', 'Error');
          return;
        }
        formData.search='';
      }
      // Call the API
      this.userData(formData)
    }
}
