<div class="main-content" [ngClass]="{'dark-theme':darkMode}">
    <div class="section-gap">
        <div class="">


            <!--Page Content-->


            <div class="">
                <div class="col-xl-12 col-lg-12 col-md-12 col-sm-12 col-12">
                    <div class="site-card">
                        <div class="site-card-header">
                            <h3 class="title">Profile Settings</h3>
                        </div>
                        <div class="site-card-body">
                            <form>
                                <div class="row">
                                    <div class="col-xl-3">
                                        <div class="mb-3">
                                            <div class="body-title">Profile:</div>
                                            <div class="wrap-custom-file" (click)="fileInput.click()">
                                                <!-- Trigger fileInput click when div is clicked -->
                                                <input #fileInput type="file" name="attach" id="ticket-attach"
                                                    accept="image/*" (change)="onFileSelect($event)"
                                                    style="display: none;" />

                                                <label for="avatar">
                                                    <img class="upload-icon" src="assets/global/materials/upload.svg"
                                                        alt="">
                                                    <span>Update profile</span>
                                                </label>
                                            </div>

                                        </div>
                                    </div>
                                </div>
                                <div class="progress-steps-form">
                                    <div class="row">
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Full Name</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="first_name" [value]="userdetails?.fullName"
                                                    placeholder="First Name">
                                            </div>
                                        </div>
                                        <!-- <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Last Name</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="last_name" value="kumar"
                                                    placeholder="Last Name">
                                            </div>
                                        </div> -->
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Username</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="username"
                                                    [value]="userdetails?.user?.userName" placeholder="Username">
                                            </div>
                                        </div>
                                        <!-- <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Gender</label>
                                            <div class="input-group">
                                                <select name="gender" id="kycTypeSelect"
                                                    class="nice-select site-nice-select" required=""
                                                    style="display: none;">
                                                    <option value="male">male</option>
                                                    <option value="female">female</option>
                                                    <option value="other">other</option>
                                                </select>
                                                <div class="nice-select site-nice-select" tabindex="0"><span
                                                        class="current">male</span>
                                                    <ul class="list">
                                                        <li data-value="male" class="option selected">male</li>
                                                        <li data-value="female" class="option">female</li>
                                                        <li data-value="other" class="option">other</li>
                                                    </ul>
                                                </div>
                                            </div>
                                        </div> -->

                                        <!-- <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Date of
                                                Birth</label>
                                            <div class="input-group">
                                                <input type="date" name="date_of_birth" class="form-control" value=""
                                                    placeholder="Date of Birth">
                                            </div>
                                        </div> -->

                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Email
                                                Address</label>
                                            <div class="input-group">
                                                <input type="email" disabled="" class="form-control disabled"
                                                    [value]=" userdetails?.user?.email||'--'"
                                                    placeholder="Email Address">
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Phone</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="phone"
                                                    [value]="'+91 ' + userdetails?.mobileNumber" placeholder="Phone"
                                                    disabled>
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Country</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control disabled" value="India"
                                                    placeholder="Country" disabled="">
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">City</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="city" value=""
                                                    placeholder="City">
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Zip</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="zip_code" value=""
                                                    placeholder="Zip">
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Address</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control" name="address" value=""
                                                    placeholder="Address">
                                            </div>
                                        </div>
                                        <div class="col-xl-6 col-md-12">
                                            <label for="exampleFormControlInput1" class="form-label">Joining
                                                Date</label>
                                            <div class="input-group">
                                                <input type="text" class="form-control disabled"
                                                    [value]="userdetails?.user?.createdOn|date:'dd:MM:yyyy  ss:mm:hh'"
                                                    placeholder="Joining Date" disabled="">
                                            </div>
                                        </div>

                                        <div class="col-xl-6 col-md-12">
                                            <button type="submit" class="site-btn blue-btn">Save Changes</button>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>


            <div class="">
                <!-- <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">
                    <div class="site-card">
                        <div class="site-card-header">
                            <h3 class="title">2FA Security</h3>
                        </div>
                        <div class="site-card-body">
                            <a href="https://xpertclub.in/user/settings/2fa" class="site-btn blue-btn">Obtaining a
                                Secret Key for Two-Factor Authentication</a>
                        </div>
                    </div>
                </div> -->

                <div class="col-xl-6 col-lg-6 col-md-6 col-sm-12 col-12">


                    <!-- <div class="site-card">
                        <div class="site-card-header">
                            <h3 class="title">Change Password</h3>
                        </div>
                        <div class="site-card-body">
                            <a  class="site-btn blue-btn">Change
                                Password</a>
                        </div>
                    </div> -->
                    <div class="site-card">
                        <div class="site-card-header">
                            <h3 class="title">logout</h3>
                        </div>
                        <div class="site-card-body">

                            <a  (click)="onLogOut()" class="site-btn blue-btn">Logout</a>
                            <p class="mt-3"></p>
                        </div>
                    </div>

                    <div class="site-card d-flex justify-content-between pe-3" style="margin-bottom: 0px;">
                        <div class="site-card-header">
                            <h3 class="title">Dark Mode</h3>
                        </div>
                        <input type="checkbox" class="" (change)="enableDarkMode($event)" [checked]="darkMode">
                    </div>

                </div>
            </div>

            <!--Page Content-->
        </div>
    </div>
</div>

<!-- <app-bottom-appbar></app-bottom-appbar> -->