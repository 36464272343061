import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class ApkDataService {

  constructor() { }
  // localHost = 'http://127.0.0.1:3001';
  localHost = 'http://145.223.18.72:3001';  //live 



  home_getCryptocurrency = '/home/getCryptocurrency';
  home_getStament = '/home/getStament';
  home_sendmessage = '/home/sendMessage';
  getStamentOneDetails = '/home/getStamentOneDetails';
  home_getMessageByUser = '/home/getMessageByUser';
  home_allStatistic = '/home/allStatistic';
  home_getTicketByUser = '/home/getTicketByUser'
  home_addTicket = '/home/addTicket'
  home_getUserPointData = '/home/getUserPointData'
  home_referralAndSelfPoints = '/home/referralAndSelfPoints'
  home_shareLinkCount = '/home/shareLinkCount'
  home_getExchangePoints = '/home/getExchangePoints'
  home_getmasterData = '/home/getmasterData'
  home_getSatting = '/home/getSatting'
  home_exchangePoints = '/home/exchangePoints'
  home_getExchangePointsHistory = '/home/getExchangePointsHistory'
  home_sendMoney = '/home/sendMoney'


  document_update = '/document/update'
  document_convartBlob = '/document/convartBlob'

  recharge_addManualPaymentRequest = '/recharge/addManualPaymentRequest';
  recharge_listRecharge = '/recharge/listRecharge';
  recharge_createPayment = '/recharge/createPayment'
  recharge_createPaymentInvoice = '/recharge/createPaymentInvoice'
  recharge_getPaymantStatus = '/recharge/getPaymantStatus'


  Withdrawal_addWithdrawal = '/Withdrawal/addWithdrawal';
  Withdrawal_getWithdrawalByUser = '/Withdrawal/getWithdrawalByUser';
  Withdrawal_getWithdrawalHisteryPoints = '/Withdrawal/getWithdrawalHisteryPoints';

  user_addBank = "/user/addBank";
  user_UserInfo = '/user/userInfo';
  user_registerWithMobile = '/user/registerWithMobile';
  user_getQRcodeByuser = '/user/getQRcodeByuser';
  user_generateOTP = '/user/generateOTP';
  user_forgotPassword = '/user/forgotPassword';
  user_loginwithMobile = '/user/loginwithMobile';
  user_logout = '/user/logout';
  user_getBankDetails = '/user/getBankDetails';
  user_Deletebank = '/user/Deletebank';
  user_isPassCode = '/user/isPassCode';
  user_addPassCode = '/user/addPassCode';
  user_isExistingUser = '/user/isExistingUser';
  user_changePassword = '/user/changePassword';
  user_passCodeRemove = '/user/passCodeRemove'
  user_addQrCodeAccount = '/user/addQrCodeAccount'
  user_getQrCodeList = '/user/getQrCodeList'
  user_updateUserInfo = '/user/updateUserInfo'
  user_isInviteCodeValid = '/user/isInviteCodeValid'
  user_otpVerify = '/user/otpVerify'

  game_GameGetUserInfo='/game/GameGetUserInfo'
  game_setIpAndMac='/game/setIpAndMac'
}
