<div class="container-fluid">
    <div class="more-detail d-flex gap-1 flex-column">
        <div class="row box-1 border-data" (click)="NavigetUser('user-forgot-passcode')">
            <div class="col-10">
              <div class="referrls">
                <p>
                  <span><i class="fa-brands fa-telegram"></i></span> Forgot PassCode
                </p>
              </div>
            </div>
            <div class="col-2">
              <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
          
        <div class="row box-2 border-data" (click)="NavigetUser('user-ref-code')" >
            <div class="col-10">
                <div class="referrls">
                    <p><span><i class="fa-brands fa-medium"></i></span>Change PassWord</p>
                </div>
            </div>
            <div class="col-2">
                <p><i class="fa-solid fa-chevron-right"></i></p>
            </div>
        </div>
    </div>
</div>
