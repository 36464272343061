<!-- 
<div class="bodysize">

  <div class="payment-container">
    <div class="headertop">
      <header>
        <div (click)="onProfile()" class="back-arrow">&larr;</div>
        <h1>USDT Payment</h1>
      </header>
    </div>


    <div class="content">
      <h2>USDT TRC-20 Wallet address</h2>
      <div class="timer">
        <h2>Remaining Time: {{ timer }}</h2>
      </div>

      <div class="qr-code">
        <img class="qr-code" [src]="userdata?.QRcode_URL" alt="QR Code" (click)="CopyUrl()" height="250" width="250"/>
      </div>
      <p class="wallet-address">{{userdata?.USDT_Address}}</p>
      <button class="copy-btn" (click)="CopyUrl()">Copy Address</button>

       <div class="form-section">
        <div class="form-group margin-top">
          <label for="amount" class="font-bold-text">Amount :- </label>
          <div class="input-container ">
            <input 
              id="amount"
              placeholder="Enter Amount in USDT" 
              type="number" 
              (input)="UserchangeData($event,'amount')" 
              class="input-field" />
            <span class="currency-indicator">
              {{ princeInIndianCurrency || 0 | currency :'INR'}}
            </span>
          </div>
        </div>
        <div class="form-group">
          <label for="hash-id" class="font-bold-text">Hash Id :-</label>
          <input 
            id="hash-id" 
            placeholder="Enter Hash ID here" 
            type="text" 
            (input)="UserchangeData($event,'utr')" 
            class="input-field" />
        </div>
      </div>
      
      <button class="completed-btn margin-top" (click)="ShowNextData()">Submit</button>
    </div>
    <footer class=" margin-top">
      <div class="notice-container">
        <h3 class="notice-title">Notice:</h3>
        <ul class="notice-list">
          <li>> Do not save the address for repeated deposits. The deposit address is updated in real time.</li>
          <li>> Please complete each deposit within 30 minutes.</li>
          <li>
            > If the deposit is not completed within 30 minutes, please go back to the previous page and click Deposit again.
          </li>
          <li>> If you have any questions, please contact customer service.</li>
        </ul>
      </div>      
    </footer>

  </div>
</div> -->









<div [ngClass]="{'dark-theme':darkMode}">
  <div class="site-card" style="height: 100vh;">
    <div class="site-card-header">
      <h3 class="title">Add Money</h3>
      <div class="card-header-links">
        <a (click)="navigateByUrl('deposite-history')" class="card-header-link">Deposit History</a>
      </div>
    </div>
    <div class="site-card-body" style="border-top: groove;" [ngClass]="{'dark-theme':darkMode}">

      <div class="progress-steps-form" >
        <form>
          <div class="row">
            <div class="col-xl-6 col-md-12 mb-3">
              <label for="gatewaySelect" class="form-label">Payment Method:</label>
              <div class="input-group">
                <select name="gateway_code" id="gatewaySelect" class="site-nice-select" [(ngModel)]="selectedOption">
                  <option value="" disabled selected>Select network</option>
                  <option *ngFor="let gateway of  gatewayOptions" [value]="gateway.value">
                    {{ gateway.name }}
                  </option>
                </select>

              </div>
              <div class="input-info-text charge"></div>
            </div>
            <div *ngIf="selectedOption=='manual'">
              <div style="display: flex; justify-content: center; ">
                <img [src]="userdata?.QRcode_URL" alt="" srcset="" height="250px" width="250px"
                  style="border: solid 1px forestgreen;">
              </div>
              <div style="display: flex; align-items:  center; margin-top: 10px; overflow-y: auto;">
                <h4 class="wallet-address">{{userdata?.USDT_Address}}</h4>
              </div>
              <div style="display: flex; align-items: center; justify-content: center; margin-top: 10px;">
                <button class="copy-address-btn" (click)="CopyUrl()">Copy Address</button>
              </div>
            </div>

            <div class="col-xl-6 col-md-12">
              <label for="amount" class="form-label">Enter Amount:</label>
              <div>
              </div>
              <div class="input-group">
                <input id="amount" placeholder="Enter Amount in USDT" type="number"
                  (input)="UserchangeData($event,'amount')" class="form-control"
                  [disabled]="this.selectedOption == ''" />
                <span class="input-group-text" id="basic-addon1">USDT</span>
              </div>
              <div class="input-info-text min-max" *ngIf="this.selectedOption == 'manual'"> <span
                  *ngIf="satting?.manualMinimumDepositAmount">Minimum {{satting?.manualMinimumDepositAmount}} USDT
                </span> <span *ngIf="satting?.manualMaximumDepositAmount"> and Maximum
                  {{satting?.manualMaximumDepositAmount}} USDT</span> </div>
              <div class="input-info-text min-max" *ngIf="this.selectedOption == 'auto'"> <span
                  *ngIf="satting?.autoPayMinimumDepositAmount">Minimum {{satting?.autoPayMinimumDepositAmount}} USDT
                </span> <span *ngIf="satting?.autoPayMaximumDepositAmount"> and Maximum
                  {{satting?.autoPayMaximumDepositAmount}} USDT</span> </div>

            </div>

          </div>

          <div class="row manual-row">

          </div>

          <div class="transaction-list table-responsive">
            <div class="user-panel-title">
              <h3>Review Details:</h3>
            </div>
            <table class="table table-re-td">
              <tbody>
                <tr>
                  <td><strong>Amount</strong></td>
                  <td><span class="amount"></span> <strong>{{ princeInIndianCurrency || 0 | currency
                      :'INR'}}</strong><span class="currency"></span></td>
                </tr>
                <!-- <tr>
                  <td><strong>convenience fee</strong></td>
                  <td class="charge2">
                    <strong>
                      {{ selectedOption == 'manual' ? satting?.manualPayConvenienceFee : selectedOption == 'auto' ?
                      satting?.autoPayConvenienceFee : '0' }} <span  >{{selectedOption == 'manual' ? ' %' :  selectedOption == 'auto' ?'USDT':''}}</span>
                    </strong>
                  </td>

                </tr> -->
                <!-- <tr *ngIf="this.selectedOption !== ''">
                  <td><strong>Payment Method</strong></td>
                  <td><strong>{{selectedOption}}</strong> </td>
                </tr> -->

                <tr class="conversion">
                  <td><strong>Pay Amount</strong></td>
                  <td class="pay-amount"><strong>{{userDetailsPost.amount||0|currency:'USD'}}</strong></td>
                </tr>


              </tbody>
            </table>
          </div>
          <div class="buttons">
            <button type="submit" class="site-btn blue-btn" 
            [ngClass]="{
              'site-btn': true,
              'blue-btn': userDetailsPost?.amount
            }"
            [disabled]="isButtonDisabled()||!payNowClick"  (click)="togglePopup();payNowClick=false">
            Pay now<i class="anticon anticon-double-right">{{ userDetailsPost?.amount | currency: 'USD' }}</i>
          </button>
          


          </div>
        </form>
      </div>
    </div>
  </div>
</div>


<div class="app-container" [ngClass]="{ 'blur-background': showPopup }" [ngClass]="{'dark-theme':darkMode}">
  <div class="popup-overlay" *ngIf="showPopup">
    <div class="popup-content" [ngClass]="{'dark-theme':darkMode}">
      <button (click)="togglePopup()" class="close-btn  ">&times;</button>

      <div class="transaction-list table-responsive">
        <div class="user-panel-title">
          <h3>Review Details:</h3>
        </div>
        <table class="table">
          <tbody>
            <tr>
              <td><strong>Amount</strong></td>
              <td><span class="amount"></span> {{ princeInIndianCurrency || 0 | currency: 'INR' }}<span
                  class="currency"></span></td>
            </tr>
            <tr>
              <td><strong>convenience fee</strong></td>
              <td class="charge2">
                {{ selectedOption == 'manual' ? satting?.manualPayConvenienceFee : selectedOption == 'auto' ?
                satting?.autoPayConvenienceFee : '0' }} %
              </td>
            </tr>
            <tr>
              <td><strong>Payment Method</strong></td>
              <td id="logo"><img src="" class="payment-method" alt=""> {{selectedOption}}</td>
            </tr>

            <tr class="conversion">
              <td><strong>Pay Amount</strong></td>
              <td class="pay-amount">{{ userDetailsPost?.amount || 0 | currency: 'USD' }}</td>
            </tr>
          </tbody>
        </table>
        <div style="margin-top: 10px;">
          <input id="amount" placeholder="Enter Hash No . . . ." type="number" (input)="UserchangeData($event,'utr')"
            class="form-control" />
        </div>
      </div>
      <button type="submit" class="site-btn blue-btn" [ngClass]="{
          'site-btn': true,
          'blue-btn': userDetailsPost?.HashId?.length === 64
        }" [disabled]="!(userDetailsPost?.HashId?.length === 64)" (click)="ShowNextData()">
        Proceed to Payment<i></i>
      </button>
    </div>
  </div>
</div>






<!-- popup -->


<div class="app-container" [ngClass]="{ 'blur-background': DepositPadingPopup }" [ngClass]="{'dark-theme':darkMode}">
  <div class="popup-overlay" *ngIf="DepositPadingPopup">
    <div style="width: 90%;">

      <div class="col-xl-12">
        <div class="site-card">
          <div class="site-card-header">
            <h3 class="title">Add Money</h3>
            <div class="card-header-links">
              <a (click)="navigateByUrl('deposite-history')" class="card-header-link">Deposit History</a>
            </div>
          </div>
          <div class="site-card-body">

            <div class="progress-steps-form">
              <div class="transaction-status centered">
                <div style="margin-bottom: 10px;">
                  <img src="/assets/frontend/pending.png" alt="" srcset="" height="65px">
                </div>
                <h2>$ 100 Deposit Pending</h2>
                <p>The amount has been Pending added into your account</p>
                <p>Transaction ID: TRXDAPLUPLBHM</p>
                <a (click)="closePopup()" class="site-btn">
                  <i class="anticon anticon-plus"></i>Deposit again
                </a>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>




<!-- auto qurcode -->

<div class="app-container" [ngClass]="{ 'blur-background': DepositPadingPopup }" [ngClass]="{'dark-theme':darkMode}">
  <div class="popup-overlay" *ngIf="autoPayQrCodePopup">
    <div style="width: 90%;">

      <div class="col-xl-12">
        <div class="site-card">
          <div class="site-card-header">
            <h3 class="title">Add Money</h3>

          </div>
          <div class="site-card-body">

            <div class="progress-steps-form">
              <div class="transaction-status" style="padding: 15px;margin: 0px; " *ngIf="!paymantSuccessfull">
                <h2>Remaining Time: {{ timer }}</h2>
                <div style="margin-bottom: 10px;   text-align: center;
                padding: 10px;
                border-radius: 15px;
                box-shadow: 0 4px 10px rgba(0, 0, 0, 0.1); display: flex;
                margin-top: 20px; margin-bottom: 20px;"> 

                  <img [src]="nowPayGetQrCode?.qrCodeUrl" style="  border-radius: 30px;border: 3px solid #000;">
                  <div (click)="CopyUrl(nowPayGetQrCode?.paymentDetails?.pay_address)">
                    <p style="margin: 0px; overflow: auto; font-size: 18px;">
                      {{insertThreeSpaces(nowPayGetQrCode?.paymentDetails?.pay_address)}} </p> 
                      <div >
                        <p style="margin: 1%; font-size: 20px;">Copy <i class="fa fa-copy " style="color: #5e3fc9;"></i> </p>
                        <p style="margin: 0px;">Payment ID : {{nowPayGetQrCode?.paymentDetails?.payment_id}}</p>
                      </div>
                    </div>
                </div>
                <!-- <h2>$ {{nowPayGetQrCode?.paymentDetails?.price_amount||100}} Deposit
                  {{nowPayGetQrCode?.paymentDetails?.payment_status}}</h2> -->
                <!-- <p style="margin: 0px;">The amount has been Pending added into your account</p> -->
               
             <div style="margin-top: 18px;">
              <p style="margin: 0px; text-align: left; margin-left: 20px; font-size: 20px; font-weight: 700;">Note</p>
              <p style="margin: 5px; font-size: 18px;"><i>* Dont close this window and dont refresh the page and dont leave the page and dont close this app</i></p>
              <p  style=" font-size: 18px;"><i>* After the recharge is completed, please wait 3-5 minutes for the deposit to arrive</i></p>
             </div>
              </div>

              <div class="transaction-status" style="padding: 15px;margin: 0px;" *ngIf="paymantSuccessfull">
                <div style="margin-bottom: 20px;">
                  <img src="/assets/frontend/approval-8089_512.gif" alt="" srcset="" height="100">
                </div>

                <h2 style="font-size: 40px;">+ {{paymanFinishedStatus?.outcome_amount||0}} <span style="font-size: 30px;"> USDT {{paymanFinishedStatus?.payment_status||'payment_status'}}</span> 
                  <h3 style="padding-top: 10px; font-size: 20px;">network fee :  {{paymanFinishedStatustoFixed(paymanFinishedStatus?.actually_paid, paymanFinishedStatus?.outcome_amount)}}</h3>
                  <h3 style="font-size: 20px;" >Amount : {{paymanFinishedStatus?.actually_paid||0 }} USDT</h3>
                </h2>
                <!-- <p>Your payment was successfull</p> -->
                <p>Payment ID : {{paymanFinishedStatus?.payment_id}}</p>
                <div class="buttons" style="justify-content: center;">
                  <button class="site-btn blue-btn">
                    Withdraw Money<i class="anticon anticon-double-right"></i>
                  </button>
                </div>
              </div>

            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</div>

<!-- <app-bottom-appbar></app-bottom-appbar> -->